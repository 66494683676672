/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import {
  Alert, Button, Card,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAccount } from '../../providers/AccountProvider';
import { Module, Role } from '../../types/AccessTypes';
import { ConnectedServices } from '../../components/ConnectedServices';
import { RiskSummaryMatrix } from '../riskregistry/RiskMatrix';
import { TopVulnerabilitiesTable } from '../vulnerabilities/TopVulnerabilitiesTable';
import { useDashboardStore } from './DashboardStore';
import { useModuleConsent } from '../customersettings/ConsentUpdateButton';
import { Welcome } from './Welcome';
import { useAvailableCustomerData } from '../../providers/AvailableCustomerDataProvider';
import ROUTES, { Routes } from '../../routing/Routes';
import { VulnerabilitySummaryTile } from './VulnerabilitySummaryTile';
import { VulnerabilityScoreTile } from './VulnerabilityScoreTile';
import { VulnerabilityMatrix } from '../vulnerabilities/VulnerabilityMatrix';
import { VulnerabilityStatus } from '../vulnerabilities/Types';
import { VulnerabilityTrendTile } from './VulnerabilityTrendTile';
import { useShowTerms } from '../../components/useShowTerms';
import { MyVulnerabilitiesTable } from '../vulnerabilities/MyVulnerabilitiesTable';

enum DashboardTileId {
  RiskOverviewTileId = 2,
  RiskMatrixTileId = 3,
  ConnectedServicesTileId = 12,
  TopVulnerabilitiesTileId = 13,
  WelcomeTileId = 14,
  VulnerabilitySummaryTileId = 15,
  VulnerabilityScoreTileId = 16,
  VulnerabilityMatrixTileId = 17,
  VulnerabilityTrendTileId = 18,
  MyVulnerabilitiesTileId = 19,
  /*
  discarded tileIds:
  M365Category = 4,
  M365CategoryPercentage = 5,
  Kb4Phishing = 6,
  Kb4History = 7,
  Kb4PhishingProne = 8,
  M365BasicSecurity = 9,
  News = 10,
  DashboardInfo = 11
  */
}

export type TileWidth = 'half'|'full'|'small';
export type TilePosition = 'top'|'bottom';

export interface DashboardTile {
  id: DashboardTileId,
  title: string,
  className?: string,
  component: JSX.Element,
  /**
   * Flag indicating whether the tile is accessible by the logged in user.
   * If it is not accessible, it will not be displayed.
   * Tiles are visible by default.
   */
  requiredModuleRole?: {module:Module, role?:Role},
  fixedHeight?: boolean|number|string,
  width?:TileWidth,
  /**
   * If `true` the tile cannot be hidden.
   */
  disableHide?: boolean,
  /**
   * If `true` the tiles content will not be wrapped in a card.
   */
  noCard?: boolean,
  defaultHidden?: boolean,
  fixedPosition?: TilePosition,
  available?: boolean,
  hideFromSelector?: boolean
}

export interface IAvailableCustomerData {
  hasConsents:boolean,
  hasVulnerabilities:boolean,
  hasRisks:boolean,
  hasAssets:boolean,
  hasVulnerabilityScanJobs:boolean,
  hasVulnerabilityTrends:boolean
}

export const useDashboardTiles = () => {
  const { hasPending, isLoading } = useModuleConsent();
  const { hasModuleRole, hasAnyModuleRole } = useAccount();
  const showTerms = useShowTerms();
  const availableData = useAvailableCustomerData();
  const navigate = useNavigate();

  const dashboardTiles = useMemo(():DashboardTile[] => {
    const mDashboardTiles:DashboardTile[] = [
      {
        id: DashboardTileId.WelcomeTileId,
        title: 'Welcome back!',
        component: <Welcome availableData={availableData} />,
        width: 'full',
        fixedPosition: 'top',
        available: availableData !== undefined
          && !availableData.hasRisks
          && !availableData.hasVulnerabilities,
        hideFromSelector: true,
      },
      {
        id: DashboardTileId.ConnectedServicesTileId,
        title: 'Connect and update services',
        component: <ConnectedServices
          pendingOnly
          helpText={
            !availableData?.hasConsents
              ? (
                <Alert variant="info" className="p-3">
                  <div>
                    In order to assess your risk, our application must be connected to your services. Read our
                    {' '}
                    <Button variant="link" onClick={showTerms} className="p-0 m-0">Terms and Conditions</Button>
                    {' '}
                    if you would like to learn more.
                  </div>
                </Alert>
              )
              : null
          }
        />,
        requiredModuleRole: { module: Module.customerAdmin, role: 'readWrite' },
        disableHide: true,
        width: 'full',
        fixedPosition: 'top',
        available: !isLoading && hasPending,
        hideFromSelector: true,
      },
      {
        id: DashboardTileId.VulnerabilityScoreTileId,
        title: 'Vulnerability score',
        component: <VulnerabilityScoreTile />,
        noCard: true,
        requiredModuleRole: { module: Module.vulnerability, role: 'read' },
        fixedHeight: '16rem',
        width: 'half',
        className: 'vulnerability-score-tile',
        available: availableData?.hasVulnerabilities,
      },
      {
        id: DashboardTileId.VulnerabilitySummaryTileId,
        title: 'Vulnerability criticality',
        component: <VulnerabilitySummaryTile />,
        requiredModuleRole: { module: Module.vulnerability, role: 'read' },
        fixedHeight: '16rem',
        width: 'half',
        className: 'vulnerability-summary-tile',
        available: availableData?.hasVulnerabilities,
      },
      {
        id: DashboardTileId.VulnerabilityMatrixTileId,
        title: 'Open vulnerabilities',
        component: <VulnerabilityMatrix
          onClick={({ probability, impact }) => {
            const searchParams = new URLSearchParams();
            searchParams.set('probability', probability.toString());
            searchParams.set('impact', impact.toString());
            searchParams.set('status', VulnerabilityStatus.Open);
            const riskUri = Routes.getRouteRelativeUri(ROUTES.vulnerabilities, Object.fromEntries(searchParams));
            navigate({
              pathname: riskUri.pathname,
              search: searchParams.toString(),
            });
          }}
        />,
        requiredModuleRole: { module: Module.vulnerability, role: 'read' },
        width: 'half',
        className: 'vulnerability-matrix',
        available: availableData?.hasVulnerabilities,
      },
      {
        id: DashboardTileId.VulnerabilityTrendTileId,
        title: 'Vulnerability trend',
        component: <VulnerabilityTrendTile />,
        requiredModuleRole: { module: Module.vulnerability, role: 'read' },
        width: 'half',
        className: 'vulnerability-history',
        available: availableData.hasVulnerabilityTrends,
      },
      {
        id: DashboardTileId.TopVulnerabilitiesTileId,
        title: 'Critical vulnerabilities',
        component: <TopVulnerabilitiesTable />,
        requiredModuleRole: { module: Module.vulnerability, role: 'read' },
        width: 'full',
        defaultHidden: true,
        available: availableData?.hasVulnerabilities,
      },
      {
        id: DashboardTileId.MyVulnerabilitiesTileId,
        title: 'Vulnerabilities assigned to you',
        component: <MyVulnerabilitiesTable />,
        requiredModuleRole: { module: Module.vulnerability, role: 'readOwn' },
        width: 'full',
        defaultHidden: false,
        available: availableData?.hasVulnerabilities,
      },
      /*
      {
        id: DashboardTileId.RiskOverviewTileId,
        title: 'Risks by severity',
        component: <RiskOverviewChart
          risks={undefined}
          onClick={(s) => {
            if (!s.length) {
              return;
            }
            const searchParams = new URLSearchParams();
            searchParams.set('severity', s[0]);
            const riskUri = Routes.getRouteRelativeUri(ROUTES.risk, Object.fromEntries(searchParams));
            navigate({
              pathname: riskUri.pathname,
              search: searchParams.toString(),
            });
          }}
        />,
        requiredModuleRole: { module: Module.risk },
        available: availableData?.hasRisks,
        defaultHidden: true,
      },
      */
      {
        id: DashboardTileId.RiskMatrixTileId,
        title: 'Open risks',
        component: <RiskSummaryMatrix
          risks={undefined}
          onClick={({ probability, impact }) => {
            const searchParams = new URLSearchParams();
            searchParams.set('probability', probability.toString());
            searchParams.set('impact', impact.toString());
            const riskUri = Routes.getRouteRelativeUri(ROUTES.risk, Object.fromEntries(searchParams));
            navigate({
              pathname: riskUri.pathname,
              search: searchParams.toString(),
            });
          }}
        />,
        requiredModuleRole: { module: Module.risk },
        available: availableData?.hasRisks,
        defaultHidden: true,
      },
    ];

    return mDashboardTiles.filter((t) => (
      !t.requiredModuleRole || (t.requiredModuleRole.role
        ? hasModuleRole(t.requiredModuleRole.module, t.requiredModuleRole?.role)
        : hasAnyModuleRole(t.requiredModuleRole.module)
      )
    ));
  }, [availableData, hasAnyModuleRole, hasModuleRole, hasPending, isLoading, showTerms, navigate]);

  return dashboardTiles;
};

const getWidthClassNameFromSize = (tileWith:TileWidth|undefined) => {
  switch (tileWith ?? 'half') {
  case 'small':
    return 'col-md-6 col-xxl-3';
  case 'full':
    return 'col-xs-12';
  default:
    return 'col-md-12 col-lg-6';
  }
};

/**
 * React component for the dashboard.
 */
export const DashboardPage = () => {
  const { tileIds } = useDashboardStore();
  const dashboardTiles = useDashboardTiles();

  const filteredTiles = useMemo(
    () => {
      const actualTiles: DashboardTile[] = [];
      for (const id of tileIds) {
        const tile = dashboardTiles.find((t) => t.id === id);
        if (tile) {
          actualTiles.push(tile);
        }
      }
      return actualTiles;
    },
    [dashboardTiles, tileIds],
  );

  return (
    <div className="row">
      {filteredTiles
        .filter((tile) => tile.available ?? true)
        .map((tile) => {
          const style = typeof tile.fixedHeight === 'number'
            ? { height: `${tile.fixedHeight}px` }
            : typeof tile.fixedHeight === 'string'
              ? { height: tile.fixedHeight }
              : undefined;
          return (
            <div className={`${getWidthClassNameFromSize(tile.width)} ${tile.className}`} key={tile.id}>
              <Card className={`flex-fill w-100${tile.fixedHeight === true ? ' fixed-height-card' : ''}`} style={style}>
                {tile.noCard ? (
                  tile.component
                ) : (
                  <>
                    <Card.Header>
                      <Card.Title className="mb-0">{tile.title}</Card.Title>
                    </Card.Header>
                    <Card.Body className="py-3 overflow-auto">
                      {tile.component}
                    </Card.Body>
                  </>
                )}
              </Card>
            </div>
          );
        })}
    </div>
  );
};

export default DashboardPage;
