import React from 'react';
import DashboardSelectTilesModal from './DashboardSelectTilesModal';
import useSettingsContext from '../../contexts/SettingsContext';

const DashboardSettings = () => {
  const { settingsModalVisible: visible, setSettingsModalVisibile: show } = useSettingsContext();

  const handleClose = () => {
    show(false);
  };

  return (
    <div>
      <DashboardSelectTilesModal show={visible} handleClose={handleClose} />
    </div>
  );
};

export default DashboardSettings;
