import React, { useState, useMemo } from 'react';
import { SettingsContext } from '../contexts/SettingsContext';

export interface ISettingsContext {
  settingsModalVisible: boolean,
  setSettingsModalVisibile: (visible: boolean) => void,
  activeCustomerId:string|undefined,
  setActiveCustomerId:(customerId:string|undefined) => void
}

export const SettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const [settingsModalVisible, setSettingsModalVisibile] = useState(false);
  const [activeCustomerId, setActiveCustomerId] = useState<string>();

  const valueProvider = useMemo(() : ISettingsContext => ({
    settingsModalVisible,
    setSettingsModalVisibile,
    activeCustomerId,
    setActiveCustomerId,
  }), [activeCustomerId, settingsModalVisible]);

  return (
    <SettingsContext.Provider value={valueProvider}>
      { children }
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
