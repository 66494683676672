import React, { useMemo } from 'react';
import {
  Button, Stack,
} from 'react-bootstrap';
import { createColumnHelper } from '@tanstack/react-table';
import { useStore } from 'zustand';
import {
  IAssessmentAsset, IAssetQuestionContext,
} from './AssessmentTypes';
import { columnsToVisibilityState, useTableStoreV8 } from '../../common/table/TableStoreV8';
import { TableColumnDefV8 } from '../../common/table/ReactTableV8';
import AssessmentDiscardAssetButton from './AssessmentDiscardAssetButton';
import { AssessmentStateManager } from './AssessmentStateManager';
import RenderHtml from '../../components/RenderHtml';
import { TableFromArray } from '../../common/table/TableFromArray';
import { arrIncludesSomeWithEmptyFixFn } from '../../common/table/filters/TableFilter';

interface ISkippedQuestionsTableItem {
  id:string,
  questionMeta:IAssetQuestionContext,
}

interface IProps {
  stateManager:AssessmentStateManager,
}

export const SkippedQuestionsTable = (props:IProps) => {
  const { stateManager } = props;

  const columnHelper = createColumnHelper<ISkippedQuestionsTableItem>();

  const mData = useMemo(() => {
    const data:ISkippedQuestionsTableItem[] = [];
    const skippedQuestions = stateManager.getSkippedQuestions();
    Object.values(skippedQuestions).forEach((questionMetadataArray) => {
      questionMetadataArray.forEach((questionMeta) => {
        const { asset, index } = questionMeta;
        data.push({
          id: `${asset.uniqueId}::${JSON.stringify(index)}`,
          questionMeta,
        });
      });
    });

    return data;
  }, [stateManager]);

  const columns = useMemo(
    () => [
      {
        ...columnHelper.accessor('questionMeta', {
          id: 'asset',
          header: 'Asset',
          cell: ({ getValue }) => (getValue()?.asset.name),
          filterFn: arrIncludesSomeWithEmptyFixFn,
        }),
        formatter: (value:IAssessmentAsset) => value.name,
        meta: {
          className: 'text-nowrap pe-4',
        },
      },
      {
        ...columnHelper.accessor('questionMeta', {
          id: 'question',
          header: 'Question',
          cell: ({ getValue }) => (
            <RenderHtml>
              {getValue().question.text}
            </RenderHtml>
          ),
          filterFn: arrIncludesSomeWithEmptyFixFn,
        }),
        meta: {
          className: 'w-100 pe-4',
        },
        // formatter: (value:VulnerabilityStatus) => vulnerabilityStatusAsText(value),
      },
      {
        ...columnHelper.accessor('questionMeta', {
          id: 'action',
          header: 'Actions',
          cell: ({ getValue }) => {
            const value = getValue();
            return (
              <Stack direction="horizontal" gap={2}>
                <Button
                  onClick={() => stateManager.resumeQuestion(value.asset, value.index)}
                >
                  Resume
                </Button>
                <AssessmentDiscardAssetButton
                  stateManager={stateManager}
                />
              </Stack>
            );
          },
          meta: {
            className: 'text-nowrap text-end',
          },
        }),
        // formatter: (value:VulnerabilityStatus) => vulnerabilityStatusAsText(value),
      },
      /*
    columnHelper.accessor('mitigationPercent', {
      header: 'Percent mitigated',
      filterFn: 'weakEquals',
    }),
    columnHelper.accessor('impact', {
      header: 'Impact',
      filterFn: 'weakEquals',
    }),
    columnHelper.accessor('probability', {
      header: 'Probability',
      filterFn: 'weakEquals',
    }), */
    ] as TableColumnDefV8<ISkippedQuestionsTableItem, unknown>[],
    [columnHelper, stateManager],
  );

  const { store: tableStore } = useTableStoreV8(
    'assessmentSkippedQuestions',
    {
      visibilityState: columnsToVisibilityState(columns),
      pageSize: 100,
    },
  );

  const tableState = useStore(tableStore);

  return mData.length
    ? (
      <TableFromArray
        state={tableState}
        columnDefs={columns}
        data={mData}
        disableFilters
        disablePagination
        disableColumnSelect
      />
    )
    : <span>There are no valid skipped questions</span>;
};
