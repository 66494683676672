import { EntityType, ITaggedEntity } from '../types/EntityTypes';
import { PagedResult } from '../types/PagedResult';
import { useApi, useInvalidateQueries } from './GenericQuery';

interface IEntitesQueryArgs {
  search?:string|undefined,
  tagIds?:Array<string>|undefined,
  entityTypes?:Array<EntityType>|undefined
}

export const useEntitiesQuery = (args:IEntitesQueryArgs, enabled?:boolean|undefined, admin?:boolean|undefined) => (
  useApi<PagedResult<ITaggedEntity>, IEntitesQueryArgs>(
    (enabled === undefined || enabled) && (admin ? 'module/admin/entities' : 'entities'),
    args,
  ));

export const useInvalidateEntities = () => {
  const invalidateEntities = useInvalidateQueries('entities');
  const invalidateAdminEntities = useInvalidateQueries('module/admin/entities');

  return () => {
    invalidateEntities();
    invalidateAdminEntities();
  };
};
