import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../query/GenericQuery';
import { VulnerabilitiesTable } from './VulnerabilitiesTable';
import { PagedResult } from '../../types/PagedResult';
import { IVulnerability } from './Types';
import ROUTES from '../../routing/Routes';
import { useAccount } from '../../providers/AccountProvider';
import { Module } from '../../types/AccessTypes';
import { Direction, ISorting } from '../../common/table/PagedResultFilter';

export const MyVulnerabilitiesTable = ({
  sort,
}:{
  sort?:ISorting[],
}) => {
  const { user, hasModuleRole } = useAccount();
  const navigate = useNavigate();

  const { data: pagedVulnerabilities } = useApi<PagedResult<IVulnerability>>(
    'vulnerabilities',
    {
      assignedToId: user.id,
    },
  );

  return pagedVulnerabilities
    ? (
      <VulnerabilitiesTable
        isPaged={false}
        id="my-vulnerabilities"
        emptyText={(
          <>
            <p>
              There are no vulnerabilities assigned to you.
            </p>
            { hasModuleRole(Module.vulnerability, 'read')
              ? (
                <Button
                  onClick={() => navigate(ROUTES.vulnerabilities.uri)}
                >
                  Show all vulnerabilities
                </Button>
              ) : null }
          </>
        )}
        pagedResult={pagedVulnerabilities}
        hide={{
          actions: true,
          risk: true,
          status: true,
          uniqueId: true,
          assignedTo: true,
        }}
        disableFilters
        skipFilterFromSearchParamInitialization
        disablePagination
        sorting={sort ?? [
          { property: 'severity', direction: Direction.asc },
          { property: 'updated', direction: Direction.desc },
        ]}
      />
    )
    : <Spinner animation="border" />;
};
