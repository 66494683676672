import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../../authConfig';
import { useConfigContext } from '../../contexts/ConfigContext';
import ROUTES from '../../routing/Routes';
import { useToastStore } from '../../components/Toast';
import { useAutoLogin } from './AutoLoginHook';
import { LoadingCard } from '../../routing/LoadingTemplate';

const handleLogin = async (instance: IPublicClientApplication) => {
  // 'prompt: login' ensures that the login redirect is always used
  await instance.clearCache();
  await instance.loginRedirect({
    ...loginRequest,
    prompt: 'select_account',
  });
};

/**
 * React component for the sign-in page
 */
export const SignInCard = () => {
  const { instance: msalInstance } = useMsal();
  const navigate = useNavigate();
  const config = useConfigContext();
  const toastStore = useToastStore();
  const { attemptExecuteAutoLogin, isLoggingIn } = useAutoLogin();
  const [initialized, setInitialized] = useState(false);

  // Clear toast message log after logout
  useEffect(() => {
    toastStore.clearMessages();

    const executeAsyncAutologin = async () => {
      await attemptExecuteAutoLogin();
      setInitialized(true);
    };
    executeAsyncAutologin();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialized && !isLoggingIn && config.SIGNIN_PAGE_URI?.length) {
      const logoutAndRedirectToSignin = async () => {
        try {
          await msalInstance.clearCache();
          await msalInstance.logoutRedirect({
            onRedirectNavigate: () => false,
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(`Unable to logout of msal: ${err}`);
        }
        window.location.href = config.SIGNIN_PAGE_URI;
      };
      logoutAndRedirectToSignin();
    }
  }, [isLoggingIn, msalInstance, config.SIGNIN_PAGE_URI, initialized]);

  if (isLoggingIn) {
    return (
      <LoadingCard>
        Redirecting to identity provider
      </LoadingCard>
    );
  }

  if (config.SIGNIN_PAGE_URI?.length) {
    return (
      <LoadingCard>
        Redirecting
      </LoadingCard>
    );
  }

  return (
    <Card>
      <Card.Body className="text-center">
        <div>
          <div>Existing user?</div>
          <Button
            onClick={async () => handleLogin(msalInstance)}
            variant="link"
            className="p-0"
          >
            <img
              src={`${config.AZURE_REDIRECT_URI}/ms-symbollockup_signin_light.svg`}
              alt="Sign in with Microsoft"
              className="sign-in-with-ms"
            />
          </Button>
        </div>
        <div className="mt-5">
          <div>Want to secure your company?</div>
          <Button
            variant="primary"
            onClick={() => navigate(ROUTES.signup.uri)}
          >
            Sign up
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
