import axios from 'axios';
import React, { useState } from 'react';
import {
  Button, Col, Form, Row,
  Stack,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import ItemsSelector from '../../common/ItemsSelector';
import { useAccount } from '../../providers/AccountProvider';
import { Setting } from '../../types/Types';
import { EntityType } from '../../types/EntityTypes';
import { Module } from '../../types/AccessTypes';
import { useDownloadFromApi } from '../../common/hooks/useDownloadFromApi';

type Interval = 'daily'|'weekly'|'monthly';

interface IReportingState {
  enabled:boolean,
  interval:Interval,
  recipients:string[]
}

const settingKeyPrefix = 'reporting.vulnerability.';

export const CustomerReporting = () => {
  const { customer, getCustomerSetting } = useAccount();
  const [state, setState] = useState<IReportingState>({
    enabled: getCustomerSetting<string>(Module.vulnerability, `${settingKeyPrefix}${'enabled'}`, 'false') === 'true',
    interval: getCustomerSetting<Interval>(Module.vulnerability, `${settingKeyPrefix}${'interval'}`, 'weekly'),
    recipients: JSON.parse(getCustomerSetting(Module.vulnerability, `${settingKeyPrefix}${'recipients'}`, '[]')),
  });
  const [busy, setBusy] = useState<string>();

  const downloadFromApi = useDownloadFromApi();

  const saveSetting = async (settingSuffix:string, value:string|null) => {
    setBusy(settingSuffix);
    try {
      const { data: setting } = await axios.put<Setting>(
        '/api/v1/settings',
      {
        entityType: EntityType.Customer,
        entityId: customer.id,
        key: `${settingKeyPrefix}${settingSuffix}`,
        value,
        module: Module.vulnerability,
      } as Setting,
      );
      return setting.value;
    } finally {
      setBusy(undefined);
    }
  };

  return state
    ? (
      <div>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Check
              id="vulnerability-report-enabled"
              label="Stay informed with periodic cybersecurity vulnerability reports"
              checked={state?.enabled}
              disabled={busy === 'enabled'}
              onChange={async (e) => {
                setState(
                  {
                    ...state,
                    enabled: await saveSetting('enabled', e.target.checked ? 'true' : 'false') === 'true',
                  },
                );
              }}
            />
          </Col>
          { state.enabled
            ? (
              <>
                <Col md={6} className="mb-3">
                  <Form.Label>Recipients:</Form.Label>
                  <ItemsSelector
                    id="report-recipients"
                    items={state.recipients}
                    inputType="email"
                    removeEnabled
                    addEnabled
                    disabled={busy === 'recipients'}
                    addLabel="Add recipient email"
                    addPlaceholder="Enter recipient email..."
                    onChange={async (items) => {
                      setState(
                        {
                          ...state,
                          recipients: JSON.parse(await saveSetting('recipients', JSON.stringify(items))),
                        },
                      );
                    }}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Label>Interval:</Form.Label>
                  <Form.Select
                    required
                    value={state.interval}
                    disabled={busy === 'interval'}
                    onChange={async (e) => {
                      setState(
                        {
                          ...state,
                          interval: await saveSetting('interval', e.target.value) as Interval,
                        },
                      );

                      // Reset send-after to corrertly hook into the proper
                      // interval after tomorrows send.
                      await saveSetting('send-after', null);
                    }}
                  >
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </Form.Select>
                </Col>
              </>
            ) : null }
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Stack direction="horizontal" gap={2}>
              <Button
                variant="primary"
                onClick={() => downloadFromApi('reports/vulnerabilities')}
              >
                <Icon name="printer" size="16" className="me-2" />
                Download vulnerability report
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>
    )
    : null;
};
