import React from 'react';
import { Button, Card, Stack } from 'react-bootstrap';
import { useLogout } from '../../providers/BearerTokenProvider';

export const SignupPendingApprovalCard = () => {
  const logout = useLogout();

  return (
    <Card className="card-sm">
      <Card.Header>You&apos;re already signed up</Card.Header>
      <Card.Body>
        <p>
          You have already signed up but your account is not yet activated.
        </p>
        <p>
          Please be patient while we review your signup request. We&apos;ll get in touch when the account
          has been activated and you can log on.
        </p>
        <Stack direction="horizontal">
          <Button
            onClick={() => logout()}
            className="mt-3"
          >
            Log out
          </Button>
        </Stack>
      </Card.Body>
    </Card>
  );
};
