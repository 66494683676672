import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import {
  ISeverityComponents, Significance,
} from './Types';
import { useApi } from '../../query/GenericQuery';
import { ProbabilityAndImpactMatrix } from '../riskregistry/RiskMatrix';

export const VulnerabilityMatrix = ({
  cellClassName,
  onClick,
}:{
    cellClassName?: (item:ISeverityComponents) => string|undefined,
    onClick: (item:ISeverityComponents) => void
  }) => {
  const { data: matrixSummary } = useApi<Record<Significance, Record<Significance, number>>>(
    'vulnerabilities/severityMatrixSummary',
  );

  const maxMatrixCount = useMemo(() => (
    matrixSummary
      ? Math.max.apply(null, Object.values(matrixSummary).flatMap((r) => Object.values(r)))
      : null
  ), [matrixSummary]);

  return matrixSummary && maxMatrixCount
    ? (
      <ProbabilityAndImpactMatrix
        getCount={(item) => (
          (matrixSummary[item.probability] ? matrixSummary[item.probability][item.impact] : 0) ?? 0
        )}
        maxCount={maxMatrixCount}
        cellClassName={cellClassName}
        onClick={onClick}
      />
    )
    : <Spinner animation="border" />;
};
