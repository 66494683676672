/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Column, Table, FilterFn,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import { Form } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import RangeSelectFilterV8, { rangeSelectFilterV8 } from './RangeSelectFilterV8';
import { SelectFilterV8, MultiSelectReactTableFilterV8 } from './MultiSelectFilterV8';
import { PageableColumnDefV8, TableColumnDefV8 } from '../ReactTableV8';

export type FilterV8Props<T> = {
  column: Column<T>,
  table: Table<T>,
  size?: 'sm' | 'lg',
}

export const MinMaxFilterV8 = <T, >(props: Omit<FilterV8Props<T>, 'table'>) => {
  const { column, size } = props;

  const columnFilterValue = column.getFilterValue();

  return (
    <div className="flex space-x-2">
      <Form.Control
        size={size ?? 'sm'}
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [
          e.target.value,
          old?.[1],
        ])}
        placeholder="Min"
        className="form-border-radius"
      />
      <Form.Control
        size={size ?? 'sm'}
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [
          old?.[0],
          e.target.value,
        ])}
        placeholder="Max"
        className="form-border-radius"
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fuzzyFilterFn: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);

  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

/**
 * `arrIncludesSomeWithEmptyFix` filter that has better support for null and
 * undefined values.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrIncludesSomeWithEmptyFixFn: FilterFn<any> = (row, columnId, filterValue) => (
  !filterValue || filterValue.length === 0
  || filterValue.some((val:unknown) => (row.getValue<unknown>(columnId) ?? null) === val)
);

/**
 * Table filter for use with server side paged data (TableFromPageable).
 */
export const PagedResultTableFilter = <T, TFilter, TData, > ({
  columnDef,
  size,
  filterText,
  filterValues,
  setFilterValues,
}:{
  columnDef: unknown,
  size?: 'sm' | 'lg',
  filterText: string,
  filterValues: TFilter[]|undefined|null,
  setFilterValues: (values:TFilter[]) => void
}) => {
  const typedColumnDef = columnDef as PageableColumnDefV8<T, TData>;

  const [localFilterText, setLocalFilterText] = useState(filterText);

  useEffect(() => {
    setLocalFilterText(filterText);
  }, [filterText]);

  const activateFilterValues = async (values:unknown|unknown[]|null) => {
    const valuesAsArray = Array.isArray(values)
      ? values
      : values ? [values] : [];
    setFilterValues(valuesAsArray);
  };

  if (!typedColumnDef.customFilter) {
    return null;
  }

  if (typedColumnDef.customFilter.selectOptions) {
    return (
      <SelectFilterV8
        columnId={typedColumnDef.id ?? typedColumnDef.accessorKey ?? 'default'}
        columnDef={typedColumnDef}
        size={size}
        options={typedColumnDef.customFilter.selectOptions}
        filterValues={filterValues ?? null}
        setFilterValues={activateFilterValues}
        multiselect={typedColumnDef.customFilter?.supportMultiSelect}
      />
    );
  }

  return (
    <Form.Control
      size={size ?? 'sm'}
      type="text"
      className={`form-border-radius ${localFilterText ? ' has-selection' : ''}`}
      value={localFilterText}
      onKeyDown={(e) => {
        if (typeof typedColumnDef.customFilter?.filterFn !== 'function') return;
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
          const inputElement = e.target as HTMLInputElement;
          activateFilterValues(inputElement.value as TFilter);
        }
      }}
      onBlur={(e) => {
        const inputElement = e.target as HTMLInputElement;
        activateFilterValues(inputElement.value as TFilter);
      }}
      onChange={async (e) => {
        setLocalFilterText(e.target.value);
        // debouncedSetFilterValue(e.target.value as TFilter);
      }}
      placeholder="Search"
    />
  );
};

/**
 * Table filter for use with client side data (TableFromArray).
 */
export const ReactTableFilterV8 = <T, TData, > ({
  column,
  table,
  size,
}: FilterV8Props<T>) => {
  const columnFilterValue = column.getFilterValue();

  const [filterText, setFilterText] = useState<string>(columnFilterValue as string ?? '');

  useEffect(() => {
    setFilterText(columnFilterValue as string ?? '');
  }, [columnFilterValue]);

  const debouncedSetFilterValue = useDebouncedCallback(async (value) => {
    column.setFilterValue(value);
  }, 250);

  if (!column.getCanFilter()) {
    return null;
  }

  // column.columnDef.filterFn => the filter function
  // default / auto => normal string input field
  // Alternatively: add a custom value to the column def and use that instead
  const isMultiSelect = (typeof column.columnDef.filterFn === 'string'
  && ['arrIncludes', 'arrIncludesAll', 'arrIncludesSome'].includes(column.columnDef.filterFn ?? ''))
  || (
    typeof column.columnDef.filterFn === 'function'
    && (
      // Customer multi selects:
      [
        'strictArrIncludesSomeFn',
        // Minifying changes the function name, use name from function instead of string name
        arrIncludesSomeWithEmptyFixFn.name,
      ].includes(column.columnDef.filterFn.name))
  );

  if (isMultiSelect) {
    return (
      <MultiSelectReactTableFilterV8
        column={column}
        table={table}
        size={size}
      />
    );
  }

  if (typeof column.columnDef.filterFn === 'function' && column.columnDef.filterFn.name === rangeSelectFilterV8.name) {
    return (
      <RangeSelectFilterV8
        column={column}
        size={size}
        max={(column.columnDef as TableColumnDefV8<T, TData>).max}
        interval={(column.columnDef as TableColumnDefV8<T, TData>).interval}
      />
    );
  }

  return (
    <Form.Control
      size={size ?? 'sm'}
      type="text"
      value={filterText}
      onChange={(e) => {
        setFilterText(e.target.value);
        debouncedSetFilterValue(e.target.value);
      }}
      placeholder="Search"
      className={`form-border-radius ${filterText ? 'has-selection' : ''}`}
    />
  );
};
