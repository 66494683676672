import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useApi } from '../../query/GenericQuery';
import { Severity } from '../vulnerabilities/Types';

interface IVulnerabilitySummary {
  severityCount:Record<Severity, number>,
  score:Severity
}

const SeverityLink = ({
  severity,
  summary,
}:{
  severity: Severity,
  summary: IVulnerabilitySummary,
}) => {
  const { severityCount } = summary;

  return (
    <div>
      { severityCount[severity] > 0
        ? (
          <Link
            to={`/vulnerabilities?severity=${severity}&status=open`}
          >
            {severityCount[severity] ?? 0}
          </Link>
        )
        : severityCount[severity] ?? 0 }
    </div>
  );
};

export const VulnerabilitySummaryTile = () => {
  const { data: summary } = useApi<IVulnerabilitySummary>('vulnerabilities/summary');

  return summary
    ? (
      <Row>
        <Col xs={12} className="high col">
          <div className="severity severity-high-lighter">
            <h3>High</h3>
            <SeverityLink severity={Severity.High} summary={summary} />
          </div>
        </Col>
        <Col xs={6} className="medium col">
          <div className="severity severity-medium-lighter">
            <h3>Medium</h3>
            <SeverityLink severity={Severity.Medium} summary={summary} />
          </div>
        </Col>
        <Col xs={6} className="low col">
          <div className="severity severity-low-lighter">
            <h3>Low</h3>
            <SeverityLink severity={Severity.Low} summary={summary} />
          </div>
        </Col>
      </Row>
    )
    : <Spinner animation="border" />;
};
