import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { IStringDictionary } from '../../types/Types';
import DefaultApexOptions from '../../utils/DefaultApexOptions';
import {
  SecurityLevel, IVulnerability, VulnerabilityStatus, ITopicAndFunctionDto,
} from './Types';
import {
  containsSecurityLevel, vulnerabilityStatusAsIndex, resolveVulnerabilityStatus,
  vulnerabilityStatusAsHexColor,
} from './Utils';
import { useVulnerabilityStatusAsText } from '../../utils/TranslationUtils';
/**
 * Generate bar chart implementation percentage for the selected security level or all
 * security levels if `securityLevel` is not provided or `SecurityLevel.Unknown`.
 */
export const createImplementationPercentStackedChartData = (
  vulns: IVulnerability[]|undefined,
  securityLevel:SecurityLevel|undefined = undefined,
) => {
  const stats: Record<VulnerabilityStatus, IStringDictionary<number>> = {
    mitigated: {} as IStringDictionary<number>,
    open: {} as IStringDictionary<number>,
    unknown: {} as IStringDictionary<number>,
  };

  if (vulns === undefined) {
    return stats;
  }

  for (const vuln of vulns) {
    const topicFriendlyId = vuln.control.topic;
    if (containsSecurityLevel(securityLevel ?? SecurityLevel.Unknown, vuln.control.securityLevel)) {
      const compiledStatus = resolveVulnerabilityStatus(vuln);
      compiledStatus.forEach((v) => {
        if (!stats[v.status]) {
          // eslint-disable-next-line no-console
          console.error(`Invalid status ${v.status} for vulnerability ${vuln.id}, skipping`);
          return;
        }
        const currentValue = stats[v.status][topicFriendlyId] ?? 0;
        stats[v.status][topicFriendlyId] = currentValue + v.fraction;
      });
    }
  }
  return stats;
};

/**
 * React component (using hooks) for displaying a M365 stacked chart of implementation status.
 */
export function ImplemenationPercentStackedChart(
  {
    data,
    topics,
  }: {
    data: Record<VulnerabilityStatus, IStringDictionary<number>>,
    topics: Record<string, ITopicAndFunctionDto>
  },
) {
  const vulnerabilityStatusAsText = useVulnerabilityStatusAsText();

  const { categories, series } = useMemo(() => {
    const mSeries:{data:number[], name:string}[] = [];

    Object.values(VulnerabilityStatus).forEach((status:VulnerabilityStatus) => {
      mSeries[vulnerabilityStatusAsIndex(status)] = {
        name: vulnerabilityStatusAsText(status) ?? 'unknown',
        data: [],
      };
    });

    const mCategories:string[] = [];

    Object.keys(topics).forEach((topicFriendlyId) => {
      const topic = topics[topicFriendlyId];

      const topicCounts = Object.values(VulnerabilityStatus).map((status:VulnerabilityStatus) => ({
        status,
        topic,
        count: Math.round((data[status][topic.friendlyId] ?? 0) * 100) / 100,
      }));

      if (topicCounts.findIndex((c) => c.count > 0) >= 0) {
        if (mCategories[mCategories.length - 1] !== topic.friendlyId) {
          mCategories.push(`${topic.function.name}: ${topic.name}`);
        }
        const idx = mCategories.length - 1;
        topicCounts.forEach((c) => {
          mSeries[vulnerabilityStatusAsIndex(c.status)].data[idx] = c.count;
        });
      }
    });

    return {
      series: mSeries,
      categories: mCategories,
    };
  }, [data, topics, vulnerabilityStatusAsText]);

  const options = useMemo(():ApexCharts.ApexOptions => (
    {
      chart: {
        ...DefaultApexOptions.chart,
        stacked: true,
      },
      states: {
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        categories,
      },
      fill: {
        opacity: 1,
      },
      colors: [
        vulnerabilityStatusAsHexColor(VulnerabilityStatus.Mitigated),
        vulnerabilityStatusAsHexColor(VulnerabilityStatus.Unknown),
        vulnerabilityStatusAsHexColor(VulnerabilityStatus.Open),
      ],
      legend: {
        position: 'top',
        horizontalAlign: 'left',
      },
    }
  ), [categories]);

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      height="330"
    />
  );
}
