/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import {
  Button, Col, Form, Modal, Row, Stack,
} from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IUser } from '../../types/AccessTypes';
import { IModalBodyAndFooterProps } from '../../providers/NewModalProvider';
import { asNameUpn } from '../../utils/StringUtils';
import { useAccount } from '../../providers/AccountProvider';
import { getActiveStatusCssClass } from '../../utils/Utils';
import { ActiveStatus } from '../../types/AdminTypes';
import { ActiveStatusBadge } from '../../components/ActiveStatusBadge';

export const SelectAssigneeModal = ({
  close,
  assignedToId,
}:IModalBodyAndFooterProps&{assignedToId?:string}) => {
  const { user } = useAccount();
  const [busy, setBusy] = useState(false);
  const [assigneeQuery, setAssigneeQuery] = useState<string>();
  const [lastSearchedQuery, setLastSearchedQuery] = useState<string>();
  const [assignableUsers, setAssignableUsers] = useState<IUser[]>();
  const [selectedAssignee, setSelectedAssignee] = useState<IUser>();
  const [comment, setComment] = useState('');

  const okRef = useRef<HTMLButtonElement>(null);

  const queryUsers = async () => {
    setSelectedAssignee(undefined);
    setLastSearchedQuery(undefined);
    setAssignableUsers(undefined);
    setComment('');

    try {
      setBusy(true);
      const { data: rawUsers } = await axios.get<IUser[]>(
        `/api/v1/vulnerabilities/assignableUsers${assigneeQuery ? `?search=${encodeURIComponent(assigneeQuery)}` : ''}`,
      );
      const users = rawUsers.filter((u) => u.id !== assignedToId);
      setAssignableUsers(users);
      if (users.length === 1) {
        setSelectedAssignee(users[0]);
        setTimeout(() => {
          okRef.current?.focus();
        });
      }
    } catch (err) {
      toast.error(`Unable to fetch users: ${err}`);
    } finally {
      setLastSearchedQuery(assigneeQuery);
      setBusy(false);
    }
  };

  return (
    <>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await queryUsers();
              }}
            >
              <Stack direction="horizontal" gap={2}>
                <Form.Control
                  placeholder="Search..."
                  autoFocus
                  onChange={(e) => setAssigneeQuery(e.target.value)}
                />
                <Button
                  disabled={busy}
                  onClick={async () => queryUsers()}
                >
                  Search
                </Button>
              </Stack>
            </form>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="mt-3">
              { assignableUsers?.length === 0
                ? lastSearchedQuery === user.externalId
                  ? (
                    <span>
                      This is your email, and you already have access to the vulnerability.
                    </span>
                  )
                  : (
                    <span>
                      No assignable users match your search, please try again.
                    </span>
                  )
                : assignableUsers?.map((u) => (
                  <Form.Check
                    key={u.id}
                    id={u.id}
                    type="radio"
                    value={u.id}
                    label={(
                      <>
                        <span className={getActiveStatusCssClass(u.status)}>
                          { asNameUpn(u) }
                        </span>
                        { u.status === ActiveStatus.invited
                          ? <ActiveStatusBadge status={u.status} className="ms-2" />
                          : null }
                      </>
                    )}
                    checked={selectedAssignee?.id === u.id || assignableUsers.length === 1}
                    onChange={(e) => {
                      setSelectedAssignee(e.target.checked ? u : undefined);
                    }}
                  />
                ))}
            </div>
          </Col>
        </Row>
        { selectedAssignee
          ? (
            <Row className="mt-3 comment-row">
              <Col md={12}>
                <Form.Label>Comment:</Form.Label>
                <Form.Control
                  as="textarea"
                  value={comment}
                  required
                  onChange={(e) => setComment(e.target.value)}
                />
              </Col>
            </Row>
          ) : null }
      </Modal.Body>
      <Modal.Footer>
        <Button
          ref={okRef}
          disabled={!selectedAssignee || comment.length === 0}
          onClick={async () => { close(true, { selectedAssignee, comment }); }}
        >
          {selectedAssignee ? `Assign to ${selectedAssignee.name ?? selectedAssignee.externalId}` : 'Assign'}
        </Button>
        <Button
          variant="secondary"
          onClick={async () => { close(false, undefined); }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
};
