import { createContext, useContext } from 'react';
import { IModalContext } from '../providers/ModalProvider';

export const ModalContext = createContext<IModalContext | undefined>(undefined);

/**
 * Get modal states / functions
 *
 * Mostly used for the global confirm dialog which can be used from anywhere
 *
 * Is avaliable globally when the user is logged in
 * @returns the modal context (see IGlobalContext)
 */
export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('No ModalContext found when calling useModalContext');
  }
  return context;
};

export default useModalContext;
