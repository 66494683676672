/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import {
  Link, useParams,
} from 'react-router-dom';
import {
  Alert, Button, Card, Col, Form, Row, Spinner, Stack,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { QueryClient } from '@tanstack/react-query';
import { FormModal } from '../../common/modal';
import { RiskMatrix } from './RiskMatrix';
import { IRisk, RiskStatus } from '../../types/RiskTypes';
import { CardCloseBackButton } from '../../components/CardCloseButton';
import ROUTES from '../../routing/Routes';
import { EntityType } from '../../types/EntityTypes';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import RiskStatusBadge from './RiskStatus';
import { VulnerabilitiesTable } from '../vulnerabilities/VulnerabilitiesTable';
import RenderHtml from '../../components/RenderHtml';
import { getFilterUpdateForSelectedSeverityComponent, isRiskManaged, isSeverityComponentFiltered } from './Utils';
import RiskForm from './RiskForm';
import {
  IVulnerability, VulnerabilityStatus,
} from '../vulnerabilities/Types';
import { PagedResult } from '../../types/PagedResult';
import { Module } from '../../types/AccessTypes';
import { TableStateV8 } from '../../common/table/TableStoreV8';
import { IAccountDetails, useAccount } from '../../providers/AccountProvider';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { severityComponentsAsKey } from '../vulnerabilities/Utils';
import { EntityCardFooter } from '../../components/EntityCardFooter';
import { ValidPageSizes } from '../../common/table/PaginationV8';

interface IData {
  risk:IRisk,
  successor:IVulnerability|null,
  pagedVulnerabilities:PagedResult<IVulnerability>
}

/**
 * React component (using hooks) for showing details about a given risk. This includes the risk placement in the risk
 * matrix, risk details and changelog.
 */
export const RiskDetailPage:IComponentWithLoader<IData, {id?:string}> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    args?:{id?:string}|undefined,
  ) => {
    const risk = await getOrFetchFromApi<IRisk>(
      queryClient,
      args?.id && `risks/${args?.id}`,
    );

    return {
      risk,
      successor: risk.moduleRef
        ? await getOrFetchFromApi<IVulnerability>(
          queryClient,
          `risks/successor/${risk?.moduleRef}`,
        )
        : null,
      pagedVulnerabilities: await getOrFetchFromApi<PagedResult<IVulnerability>>(
        queryClient,
        'vulnerabilities',
        {
          riskId: risk?.riskId,
          sortBy: 'asset.friendlyId, control.sortIndex',
        },
      ),
    };
  },
  Component: () => {
    const { id } = useParams();
    const { hasModuleRole } = useAccount();

    const [showEditModal, setShowEditModal] = useState(false);
    const closeEditModal = () => setShowEditModal(false);
    const [vulnerabilitiesTableState, setVulnerabilitiesTableState] = useState<TableStateV8>();

    const { data: risk } = useApiLoaderData<IRisk, IData>(
      `risks/${id}`,
      (loaderData) => loaderData.risk,
    );

    const { data: successor } = useApiLoaderData<IVulnerability|null, IData>(
      risk.moduleRef && `risks/successor/${risk.moduleRef}`,
      (loaderData) => loaderData.successor,
    );

    const { data: pagedVulnerabilities } = useApiLoaderData<PagedResult<IVulnerability>, IData>(
      risk && 'vulnerabilities',
      (loaderData) => loaderData.pagedVulnerabilities,
      {
        riskId: risk.riskId,
        sortBy: 'asset.friendlyId, control.sortIndex',
      },
    );

    const [vulnerabilityCountBySignificance, maxCount] = useMemo(() : [Record<string, number>, number|undefined] => {
      if (!pagedVulnerabilities?.items) return [{}, undefined];
      const mCounts:Record<string, number> = {};
      let max = 0;
      pagedVulnerabilities.items.forEach((v) => {
        // We're only counting open vulnerabilities
        if (v.status !== VulnerabilityStatus.Open) {
          return;
        }

        const key = severityComponentsAsKey(v);

        if (!mCounts[key]) {
          mCounts[key] = 1;
        } else {
          mCounts[key] += 1;
        }

        if (mCounts[key] > max) {
          max = mCounts[key];
        }
      });
      return [mCounts, max];
    }, [pagedVulnerabilities]);

    const { getFilterValues, addOrUpdateFilterValues } = vulnerabilitiesTableState ?? {};

    return maxCount === undefined || !risk ? <Spinner animation="border" /> : (
      <div>
        <Row>
          <Col sm={12}>
            <Card className="flex-fill w-100">
              <Card.Header>
                <Icon name={ROUTES.risk.icon} />
                {' '}
                { risk.riskId }
                {' '}
                { risk.name }
                <CardCloseBackButton />
              </Card.Header>
              <Card.Body className="py-3 overflow-auto">
                { successor
                  ? (
                    <Row>
                      <Col md={12}>
                        <Alert variant="info" className="p-3">
                          <div>
                            This is a modified vulnerability-driven risk that is no longer maintained by
                            the application.
                            {' '}
                            { hasModuleRole(Module.risk, 'readWrite')
                              ? 'You '
                              : 'A user with access to the risk module ' }
                            have to manually manage and close the
                            risk as appropriate.
                            <br />
                            <br />
                            Vulnerability
                            {' '}
                            <Link to={`${ROUTES.vulnerabilities.uri}/${successor.id}`}>
                              {successor.control.friendlyId}
                              {'. '}
                              {successor.control.name}
                            </Link>
                            {' '}
                            { successor.risk
                              ? (
                                <span>
                                  associated with risk
                                  {' '}
                                  <Link to={`${ROUTES.risk.uri}/${successor.risk.riskId}`}>
                                    {successor.risk?.riskId}
                                    {'. '}
                                    {successor.risk.name}
                                  </Link>
                                  {' '}
                                </span>
                              )
                              : null }
                            has been added as this risk&apos;s successor
                            {successor.risk ? 's' : ''}
                            .
                            <br />
                            <br />
                            <Stack direction="horizontal" gap={2}>
                              <Button href={`${ROUTES.vulnerabilities.uri}/${successor.id}`} variant="secondary">
                                Goto vulnerability
                                {' '}
                                {successor.control.friendlyId}
                              </Button>
                              { successor.risk
                                ? (
                                  <Button href={`${ROUTES.risk.uri}/${successor.risk?.riskId}`} variant="secondary">
                                    Goto risk
                                    {' '}
                                    {successor.risk?.riskId}
                                  </Button>
                                )
                                : null }
                            </Stack>
                          </div>
                        </Alert>
                      </Col>
                    </Row>
                  )
                  : null }
                <Row>
                  <Col md={risk.status === RiskStatus.Open ? 6 : 12} className="mb-3">
                    <Form>
                      <Row>
                        <Col md={8} className="mb-3">
                          <div className="form-label">Name:</div>
                          <div>{risk.name}</div>
                        </Col>
                        <Col md={4} className="mb-3">
                          <div className="form-label">Status:</div>
                          <RiskStatusBadge status={risk.status} />
                        </Col>
                        <Col md={12} className="mb-3">
                          <div className="form-label">Description:</div>
                          <RenderHtml>
                            {risk.description}
                          </RenderHtml>
                        </Col>
                        { isRiskManaged(risk) ? null : (
                          <Col md={12} className="mb-3">
                            <div className="form-label">Owner:</div>
                            <div>{risk.owner}</div>
                          </Col>
                        ) }
                      </Row>
                    </Form>
                  </Col>
                  { risk.status === RiskStatus.Open
                    ? (
                      <Col md={6} className="mb-4">
                        <RiskMatrix
                          getCount={(item) => {
                            if (maxCount > 0) {
                              const key = severityComponentsAsKey(item);
                              return vulnerabilityCountBySignificance[key] ?? 0;
                            }

                            return item.probability === risk.probability && item.impact === risk.impact ? 1 : 0;
                          }}
                          maxCount={maxCount}
                          single={maxCount === 0}
                          onClick={(item) => {
                            if (addOrUpdateFilterValues && getFilterValues) {
                              addOrUpdateFilterValues(
                                getFilterUpdateForSelectedSeverityComponent({ ...item, getFilterValues }),
                              );
                            }
                          }}
                          cellClassName={(data) => (isSeverityComponentFiltered({ ...data, getFilterValues }) ? 'highlight' : '')}
                        />
                      </Col>
                    ) : null }
                </Row>
                { isRiskManaged(risk) ? null : (
                  <>
                    <Row>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Consequence:</div>
                        <div>{risk.consequence}</div>
                      </Col>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Response:</div>
                        <div>{risk.response}</div>
                      </Col>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Solution:</div>
                        <div>{risk.solution}</div>
                      </Col>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Reason:</div>
                        <div>{risk.reason}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Category:</div>
                        <div>{risk.category}</div>
                      </Col>
                      <Col md={6} className="mb-3">
                        <div className="form-label">Asset:</div>
                        <div>{risk.asset}</div>
                      </Col>
                    </Row>
                  </>
                ) }
                { isRiskManaged(risk) || !hasModuleRole(Module.riskUnmanaged, 'readWrite')
                  ? null
                  : (
                    <Row className="mb-3">
                      <Col md={12}>
                        <Button type="button" onClick={() => setShowEditModal(true)}>Edit</Button>
                      </Col>
                    </Row>
                  ) }
                <EntityCardFooter
                  timestamps={risk}
                  entity={{ entityId: risk.id, entityType: EntityType.Risk }}
                  linkRoute={ROUTES.risk}
                  additionalDetails={(
                    <span>
                      {`ID: ${risk.riskId}`}
                      {` (${risk.id})`}
                    </span>
                  )}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        { isRiskManaged(risk)
          ? (
            <Row>
              <Col>
                <Card>
                  <Card.Header>
                    Vulnerabilities
                  </Card.Header>
                  <Card.Body className="overflow-auto">
                    <VulnerabilitiesTable
                      isPaged={false}
                      id="risk-detail-vulnerabilities"
                      pagedResult={pagedVulnerabilities}
                      hide={{ risk: true }}
                      onInitialized={setVulnerabilitiesTableState}
                      emptyText={`${hasModuleRole(Module.vulnerability, 'read')
                        ? 'The risk has no vulnerabilities.'
                        : 'None of the risks vulnerabilities are assigned to you.'
                      }`}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )
          : (
            <FormModal
              itemType={`risk ${risk.riskId}`}
              isAdd={false}
              show={showEditModal}
              handleClose={closeEditModal}
              Form={<RiskForm risk={risk} handleClose={closeEditModal} />}
            />
          ) }
      </div>
    );
  },
};

export default RiskDetailPage;
