import React from 'react';
import './App.css';
// import { UnauthenticatedTemplate } from '@azure/msal-react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'react-toastify/dist/ReactToastify.min.css';
import { Modal } from 'react-bootstrap';
import { AlgizToastContainer } from './components/Toast';
import ModalProvider from './providers/ModalProvider';
import { useConfigContext } from './contexts/ConfigContext';
import TranslationProvider from './providers/TranslationProvider';
import { ApplicationInsightsProvider } from './contexts/AppInsightsContext';
import { AuthenticatedApp } from './routing/AuthenticatedApp';
import { UnauthenticatedApp } from './routing/UnauthenticatedApp';
import { WithAxiosBearer } from './utils/WithAxiosBearer';
import { BearerTokenProvider } from './providers/BearerTokenProvider';
import ErrorBoundary from './routing/ErrorBoundary';
import { AccountProvider } from './providers/AccountProvider';
import { AuthenticatedNoAccountApp } from './routing/AuthenticatedNoAccountApp';
import { ModulesProvider } from './providers/ModuleProvider';
import { AuthenticatedTemplate } from './AuthenticatedTemplate';
import { UnauthenticatedTemplate } from './UnauthenticatedTemplate';

// Set all Modals to be body scrollable
Modal.defaultProps = { scrollable: true, ...Modal.defaultProps };

/**
 * Defines the React app with layout and pages.
 */
const App = () => {
  const config = useConfigContext();

  return (
    <ErrorBoundary>
      <ApplicationInsightsProvider config={config}>
        <AlgizToastContainer />
        <UnauthenticatedTemplate>
          <UnauthenticatedApp />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <ModalProvider>
            <TranslationProvider>
              <BearerTokenProvider>
                <WithAxiosBearer>
                  <AccountProvider fallback={<AuthenticatedNoAccountApp />}>
                    <ModulesProvider>
                      <div className="print-background">
                        <img src={`${config.AZURE_REDIRECT_URI}/ivolv-logo-onwhite.svg`} alt="Ivolv Cybersecurity AS" />
                      </div>
                      <AuthenticatedApp />
                    </ModulesProvider>
                  </AccountProvider>
                </WithAxiosBearer>
              </BearerTokenProvider>
            </TranslationProvider>
          </ModalProvider>
          <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
        </AuthenticatedTemplate>
      </ApplicationInsightsProvider>
    </ErrorBoundary>
  );
};

export default App;
