import React, { useMemo } from 'react';
import {
  Alert, Badge, Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import {
  ICustomerUpdateModule, IModule, Module,
} from '../../types/AccessTypes';
import { getStringDateOnly, isFutureDate } from '../../utils/StringUtils';
import { ICustomerEssentials } from '../../types/AdminTypes';
import { useAccount } from '../../providers/AccountProvider';
import { ScanVulnerabilitiesButton } from '../../components/ScanVulnerabilitiesButton';
import { useModules } from '../../providers/ModuleProvider';

const buildHierarchy = (modules:IModule[]) => {
  if (!modules) return [];
  const modulesWithChildren:Record<number, IModule&{children:IModule[]}> = {};
  modules.forEach((m) => { modulesWithChildren[m.id] = { ...m, children: [] }; });
  Object.values(modulesWithChildren).forEach((m) => {
    if (m.parent) {
      modulesWithChildren[m.parent.id].children.push(m);
    }
  });
  return modulesWithChildren;
};

interface IProps {
  customer:ICustomerEssentials|undefined,
  customerModules:ICustomerUpdateModule[],
  disabled?:boolean,
  canBeAdmin?:boolean,
  onChange: (customerModules:ICustomerUpdateModule[]) => void
}

export const CustomerModuleSelector = (props:IProps) => {
  const {
    customerModules, canBeAdmin, disabled, onChange, customer,
  } = props;

  const { primaryCustomer, isAssociatedTo } = useAccount();
  const { modules } = useModules();

  const canSwitchTo = useMemo(() => (
    primaryCustomer
      ? customer && (isAssociatedTo?.find((c) => c.id === customer.id) || customer.id === primaryCustomer.id)
      : false
  ), [customer, isAssociatedTo, primaryCustomer]);

  const { moduleColumns, isAncestorsEnabledMap } = useMemo(() => {
    if (!modules) return {};
    const filteredModules = modules.filter((m) => m.id !== Module.customerAdmin && m.id > 0);
    const itemsPerColumn = Math.ceil(filteredModules.length / 3);
    const c = [...Array(3)].map((_, columnIndex) => (
      filteredModules.slice(columnIndex * itemsPerColumn, (columnIndex + 1) * itemsPerColumn)
    ));

    const hierarchy = buildHierarchy(modules);

    const isAncestorsEnabled = (moduleId:number) : boolean => {
      const module = hierarchy[moduleId];

      if (!module.parent) {
        return true;
      }
      const parentCustomerModule = customerModules.find((m) => m.moduleId === module.parent?.id);
      if (parentCustomerModule && parentCustomerModule.expires && !isFutureDate(parentCustomerModule.expires)) {
        return false;
      }
      if (!customerModules.find((m) => m.moduleId === module.parent?.id)) {
        return false;
      }
      return isAncestorsEnabled(module.parent.id);
    };

    const mIsAncestorsEnabledMap:Record<number, boolean> = {};
    Object.values(hierarchy).forEach((m) => { mIsAncestorsEnabledMap[m.id] = isAncestorsEnabled(m.id); });

    return {
      moduleColumns: c,
      isAncestorsEnabledMap: mIsAncestorsEnabledMap,
    };
  }, [customerModules, modules]);

  return moduleColumns
    ? (
      <Row className="mb-3 module-selector">
        {moduleColumns.map((column, columnIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={columnIndex} xs={12} md={4}>
            {column.map((module) => {
              const customerModule = customerModules.find((m) => m.moduleId === module.id);
              const formCheck = (
                <Form.Check
                  type="checkbox"
                  id={module.id.toString()}
                  checked={
                    isAncestorsEnabledMap[module.id]
                    && !!customerModule
                    && (!customerModule.expires || isFutureDate(customerModule?.expires)
                    )
                  }
                  disabled={(
                    !isAncestorsEnabledMap[module.id] || disabled || (module.id === Module.admin && !canBeAdmin)
                  )}
                  label={(
                    <>
                      {
                        customerModule?.expires
                          ? (
                            <Alert variant="secondary" className="px-1 pb-1 m-0">
                              <div>
                                {module.name}
                                <div className="mt-1">
                                  <Badge bg="danger" className="fw-light">
                                    { isFutureDate(customerModule.expires)
                                      ? 'Expires:'
                                      : 'Expired:' }
                                    {' '}
                                    {getStringDateOnly(customerModule.expires)}
                                    <Button
                                      variant="link"
                                      className="text-white ms-2 p-0"
                                      size="sm"
                                      onClick={(e) => {
                                        // Prevent toggle checkbox
                                        e.preventDefault();

                                        const newCustomerModules = [...customerModules];
                                        const expiringModule = newCustomerModules.find((m) => m.moduleId === module.id);
                                        if (expiringModule) {
                                          expiringModule.expires = undefined;
                                        }

                                        onChange(newCustomerModules);
                                      }}
                                    >
                                      <Icon name="x-circle" />
                                    </Button>
                                  </Badge>
                                </div>
                              </div>
                            </Alert>
                          )
                          : module.name
                      }
                      { canSwitchTo
                      && isAncestorsEnabledMap[module.id]
                      && !!customerModule
                      && module.jobTypeKeys
                      && module.jobTypeKeys.includes('scan')
                      && (!customerModule?.expires || isFutureDate(customerModule.expires))
                        ? (
                          <div className="mt-1">
                            <ScanVulnerabilitiesButton
                              module={module.id}
                              size="sm"
                              className="mb-2"
                              customerId={customer?.id}
                              label={(busy) => (
                                busy
                                  ? <Spinner animation="border" size="sm" />
                                  : <Icon name="refresh-cw" size={14} />
                              )}
                            />
                          </div>
                        )
                        : null }
                    </>
                  )}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const idx = customerModules.findIndex((m) => m.moduleId === module.id);
                      if (idx >= 0) {
                        customerModules[idx].expires = undefined;
                        onChange([...customerModules]);
                      } else {
                        onChange([...customerModules, { moduleId: module.id }]);
                      }
                    } else {
                      onChange([...customerModules.filter((m) => m.moduleId !== module.id)]);
                    }
                  }}
                />
              );

              return isAncestorsEnabledMap[module.id]
                ? (
                  <div key={module.id.toString()}>
                    { formCheck }
                  </div>
                )
                : (
                  <OverlayTrigger
                    key={module.id.toString()}
                    overlay={(
                      <Tooltip className="tooltip-sm">
                        Parent module is disabled
                        <br />
                        (
                        {module.parent?.name}
                        )
                      </Tooltip>
                    )}
                    placement="auto"
                  >
                    <div className="ancestor-disabled">
                      { formCheck }
                    </div>
                  </OverlayTrigger>
                );
            })}
          </Col>
        ))}
      </Row>
    )
    : <Spinner animation="border" />;
};

export default CustomerModuleSelector;
