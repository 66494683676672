/* eslint-disable no-plusplus */
import React, { useMemo, useState } from 'react';
import {
  Button, Col, Form, Row, Stack,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { IAssessmentSyncAsset } from './AssessmentTypes';
import { AssetType } from '../../types/Types';
import { useAssetTypeAsText } from '../../utils/TranslationUtils';
import { AssessmentStateManager } from './AssessmentStateManager';
import { IAsset } from '../../types/AssetsTypes';

interface IAssetSelectorProps {
  stateManager: AssessmentStateManager,
  customerAssets: IAsset[]
}

const AssetSelector = (props:IAssetSelectorProps) => {
  const { stateManager, customerAssets } = props;

  const [editAsset, setEditAsset] = useState<IAssessmentSyncAsset&{customOption?:boolean}|undefined>();
  const assetTypeAsText = useAssetTypeAsText();

  const selectableAssets = useMemo(() => (
    stateManager.resolveSelectableAssets(customerAssets)
  ), [customerAssets, stateManager]);

  const setAndInitializeEditAsset = async (asset:IAssessmentSyncAsset&{customOption?:boolean}) => {
    const newAsset = { ...asset };
    const assets = stateManager.getAssets();
    if (newAsset.customOption || !newAsset.id) {
      newAsset.name = newAsset.name.trim();
      newAsset.uniqueId = Math.max(...assets.map((a) => a.uniqueId)) + 1;
      delete newAsset.id;
    }

    if (newAsset.customOption) {
      setEditAsset(newAsset);
    } else {
      stateManager.activateAsset(newAsset);
    }
  };

  const setAssetType = (assetType:AssetType) => {
    if (editAsset) {
      editAsset.type = assetType;
      setEditAsset({ ...editAsset });
    } else {
      setEditAsset(undefined);
    }
  };

  const cancel = () => {
    setEditAsset(undefined);
  };

  const setSelectedAsset = (asset?:IAssessmentSyncAsset) => {
    stateManager.activateAsset(asset);
  };

  return (
    <Row>
      <Col md={12}>
        { editAsset
          ? (
            <>
              <div className="form-control">{editAsset.name}</div>
              { !editAsset.customOption
                ? (
                  <>
                    <div className="form-label mt-3">Type</div>
                    <div className="form-control">{assetTypeAsText(editAsset.type, false)}</div>
                  </>
                )
                : (
                  <div className="pt-3">
                    <Form>
                      <Form.Label>
                        What type is this asset?
                      </Form.Label>
                      <Form.Select
                        onChange={(v) => setAssetType(v.target.value as AssetType)}
                      >
                        <option>Select the most applicable type</option>
                        { stateManager.getAssessableAssetTypes().map((assetType) => (
                          <option key={assetType} value={assetType}>{assetTypeAsText(assetType, false)}</option>
                        )) }
                      </Form.Select>
                    </Form>
                  </div>
                ) }
              <Stack direction="horizontal" gap={1} className="pt-3">
                <Button onClick={cancel}>Back</Button>
                <Button
                  onClick={() => setSelectedAsset(editAsset)}
                  disabled={!editAsset.type}
                >
                  Continue
                </Button>
              </Stack>
            </>
          )
          : (
            <Typeahead
              id="assetPicker"
              labelKey="name"
              options={selectableAssets}
              placeholder="Select asset or enter new assets name"
              positionFixed
              allowNew
              newSelectionPrefix="Add asset: "
              onChange={async (o) => {
                if (o?.length) await setAndInitializeEditAsset(o[0] as IAssessmentSyncAsset);
              }}
            />
          )}
      </Col>
    </Row>
  );
};

export default AssetSelector;
