import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

interface CommonModalProps {
  show: boolean,
  handleClose: () => void,
  children: JSX.Element | JSX.Element[] | string | null | (JSX.Element | null)[],
  Footer?: JSX.Element | JSX.Element[],
  title: string,
  size?: ModalProps['size'],
  backdrop?: ModalProps['backdrop'],
  /** Enabled by default */
  closeButton?: boolean,
}

/**
 * A generic modal using the React Boostrap Modal components for easier use
 * @param props CommonModalProps
 */
export const CommonModal = (props: CommonModalProps) => {
  const {
    show, handleClose, title, children, Footer, size, backdrop, closeButton,
  } = props;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop={backdrop}
      size={size}
    >
      <Modal.Header closeButton={closeButton ?? true}>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      {!!Footer && (
        <Modal.Footer>
          {Footer}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CommonModal;
