import React, { useMemo } from 'react';
import {
  Col, Container, Modal, Row, Spinner, Card,
} from 'react-bootstrap';
import {
  Link, useLoaderData,
} from 'react-router-dom';
import { Icon } from '@ailibs/feather-react-ts';
import { createColumnHelper } from '@tanstack/react-table';
import { QueryClient } from '@tanstack/react-query';
import { useStore } from 'zustand';
import {
  IControlAlias, IControlDetails, IVulnerability, IVulnerabilitySnapshot,
} from './Types';
import { useSecurityLevelAsText } from '../../utils/TranslationUtils';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { ControlVulnerabilitiesTable } from './VulnerabilitiesTable';
import ROUTES from '../../routing/Routes';
import { CardCloseBackButton } from '../../components/CardCloseButton';
import RenderHtml from '../../components/RenderHtml';
import { TableColumnDefV8 } from '../../common/table';
import { useTableStoreV8, columnsToVisibilityState } from '../../common/table/TableStoreV8';
import { PagedResult } from '../../types/PagedResult';
import { getStringDate } from '../../utils/StringUtils';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { IAccountDetails } from '../../providers/AccountProvider';
import { TableFromArray } from '../../common/table/TableFromArray';
import { ValidPageSizes } from '../../common/table/PaginationV8';

interface IData {
  control:IControlDetails,
  vulnerabilities:PagedResult<IVulnerability>,
  snapshot:IVulnerabilitySnapshot|undefined
}

interface IParams {
  id:string|undefined,
  snapshotId:string|undefined
}

export const ControlPage:IComponentWithLoader<IData, IParams> = (
  {
    loader: async (
      queryClient:QueryClient,
      account:IAccountDetails,
      pageSize:ValidPageSizes,
      params?:IParams,
    ) : Promise<IData> => {
      const { id, snapshotId } = params ?? {};
      const control = await getOrFetchFromApi<IControlDetails>(
        queryClient,
        id && `controls/${encodeURIComponent(id)}`,
        {
          snapshotId,
        },
      );

      const snapshot = snapshotId
        ? await getOrFetchFromApi<IVulnerabilitySnapshot>(
          queryClient,
          snapshotId && `vulnerabilitySnapshots/${snapshotId}`,
        )
        : undefined;

      // If we're projecting an alias framework, fetch vulnerabilities from main library
      const controlId = control?.equivalents?.length
        ? control.equivalents.find((c) => c.frameworkFriendlyId === 'ivolv')?.id ?? control.id
        : control?.id;

      return {
        control,
        snapshot,
        vulnerabilities: await getOrFetchFromApi<PagedResult<IVulnerability>>(
          queryClient,
          'vulnerabilities',
          {
            controlId,
            sortBy: 'asset.friendlyId',
            snapshotId,
          },
        ),
      };
    },
    Component: () => {
      const securityLevelAsText = useSecurityLevelAsText();

      const { control, snapshot } = useLoaderData() as Awaited<IData>;

      const { data: vulnerabilities } = useApiLoaderData<PagedResult<IVulnerability>, IData>(
        'vulnerabilities',
        (loaderData) => loaderData.vulnerabilities,
        {
          controlId: control.id,
          sortBy: 'asset.friendlyId',
          snapshotId: snapshot?.id,
        },
      );

      const columnHelper = createColumnHelper<IControlAlias>();

      const controlAliasColumns = useMemo<TableColumnDefV8<IControlAlias, unknown>[]>(() => [
        columnHelper.accessor('frameworkFriendlyId', {
          header: 'Framework',
        }),
        columnHelper.accessor('frameworkVersion', {
          header: 'Version',
        }),
        columnHelper.accessor('friendlyId', {
          header: 'Control',
          enableHiding: false,
        }),
        columnHelper.accessor('name', {
          header: 'Name',
        }),
        columnHelper.accessor('securityLevel', {
          header: 'Security level',
          cell: ({ getValue }) => securityLevelAsText(getValue()),
        }),
        columnHelper.display({
          id: 'link',
          cell: ({ row }) => (
            <Link to={`${ROUTES.control.uri}/${row.original.id}`} className="btn btn-light">
              <Icon size="20" name="arrow-right-circle" />
            </Link>
          ),
          meta: {
            className: 'text-end',
          },
        }),
      ] as TableColumnDefV8<IControlAlias, unknown>[], [columnHelper, securityLevelAsText]);

      const { store: tableStore } = useTableStoreV8(
        'control-alias',
        {
          visibilityState: columnsToVisibilityState(controlAliasColumns),
        },
      );

      const tableState = useStore(tableStore);

      return (
        <>
          { snapshot
            ? (
              <Row>
                <Col md={12}>
                  <Card className="highlight-card-header">
                    <Card.Body
                      role="button"
                      aria-label="Expand filters"
                    >
                      Snapshot:
                      {' '}
                      <span className="value">
                        { getStringDate(snapshot.created) }
                      </span>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )
            : null }
          <Row>
            <Col md={12}>
              <Card>
                <Card.Header>
                  <Icon name={ROUTES.control.icon} />
                  {' '}
                  { control.friendlyId }
                  {' '}
                  { control.name }
                  <CardCloseBackButton />
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={2} className="mb-3">
                      <div className="form-label">Friendly Id:</div>
                      <div className="form-control">{control.friendlyId}</div>
                    </Col>
                    <Col md={8} className="mb-3">
                      <div className="form-label">Name:</div>
                      <div className="form-control">{control.name}</div>
                    </Col>
                    <Col md={2} className="mb-3">
                      <div className="form-label">Security level:</div>
                      <div>
                        {securityLevelAsText(control.securityLevel)}
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <div className="form-label">Function:</div>
                      <div className="form-control">{control.functionName}</div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <div className="form-label">Topic:</div>
                      <div className="form-control">{control.topicName}</div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <div className="form-label">Description:</div>
                      <RenderHtml className="form-control">
                        {control.description}
                      </RenderHtml>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col md={12}>
              <Card>
                <Card.Header>
                  Vulnerabilties
                </Card.Header>
                <Card.Body className="overflow-auto">
                  { vulnerabilities
                    ? (
                      <ControlVulnerabilitiesTable
                        control={control}
                        pagedVulnerabilities={vulnerabilities}
                      />
                    )
                    : <Spinner animation="border" /> }
                </Card.Body>
              </Card>
            </Col>

            { !control?.equivalents || control?.equivalents.length === 0 ? null : (
              <Col md={12}>
                <Card>
                  <Card.Header>
                    Associated frameworks
                  </Card.Header>
                  <Card.Body className="overflow-auto">
                    <Row>
                      <Modal.Title />
                      <Container>
                        <TableFromArray
                          state={tableState}
                          columnDefs={controlAliasColumns}
                          data={control.equivalents}
                          paginationMinData={10}
                          disableFilters
                          disableColumnSelect
                        />
                      </Container>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )}

          </Row>
        </>
      );
    },
  });

export default ControlPage;
