import React from 'react';
import {
  Button, Card, Stack,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { useConfigContext } from '../../contexts/ConfigContext';
import { loginRequest } from '../../authConfig';

export const AcceptInviteUnauthenticatedCard = () => {
  const config = useConfigContext();
  const navigate = useNavigate();
  const { instance } = useMsal();

  async function handleLogin() {
    // 'prompt: login' ensures that the login redirect is always used
    await instance.loginPopup({
      ...loginRequest,
      prompt: 'select_account',
    });
  }

  return (
    <Card className="fill card-md">
      <Card.Header>You&apos;ve been invited!</Card.Header>
      <Card.Body>
        You have been invited to collaborate on improving the Cyber reliciance of one of our customers.
        <br />
        <br />
        For us to connect everything we need you to sign in with your Microsoft Entra ID account.
        <br />
        <br />
        Click the
        {' '}
        <em>Sign in</em>
        {' '}
        button below to get started.
      </Card.Body>
      <div className="card-button-footer">
        <Stack direction="horizontal" gap={2}>
          <Button
            onClick={async () => handleLogin()}
            autoFocus
            variant="link"
            className="p-0"
          >
            <img
              src={`${config.AZURE_REDIRECT_URI}/ms-symbollockup_signin_light_short.svg`}
              alt="Microsoft Login Button"
              className="sign-in-with-ms-short"
            />
          </Button>
          <Button
            variant="secondary"
            className="ms-auto"
            onClick={() => navigate('/')}
          >
            Cancel
          </Button>
        </Stack>
      </div>
    </Card>
  );
};
