import React from 'react';
import {
  Form, ListGroup, ListGroupItem, Modal,
} from 'react-bootstrap';
import { Reorder } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.css';
import { useDashboardStore } from './DashboardStore';
import { useDashboardTiles } from './DashboardPage';

interface IDashboardEditModalProps {
  show: boolean,
  handleClose: () => void,
}

/**
 * Modal for selecting which charts/"modules" to show on the dashboard
 * and also in what order
 */
export const DashboardSelectTilesModal = (props: IDashboardEditModalProps) => {
  const {
    show, handleClose,
  } = props;
  const { tileIds, updateTileIds } = useDashboardStore();
  const dashboardTiles = useDashboardTiles();

  const hasVisibleTiles = !!dashboardTiles.find((t) => tileIds.includes(t.id));

  return (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Form>
        <Modal.Header closeButton>
          <strong>
            Select modules and drag to re-order
          </strong>
        </Modal.Header>
        <Modal.Body>
          { dashboardTiles.length
            ? (
              <>
                {hasVisibleTiles
                  ? (
                    <Form.Group>
                      <Reorder.Group
                        axis="y"
                        onReorder={updateTileIds}
                        values={tileIds}
                        as="div"
                      >
                        <ListGroup variant="flush">
                          {tileIds.map((tileId) => {
                            const tile = dashboardTiles.find((t) => t.id === tileId && !t.hideFromSelector);
                            if (!tile?.id) return null;
                            return (
                              <Reorder.Item
                                key={tile.id}
                                value={tile.id}
                                className="reorder-item"
                                dragListener={!tile.fixedPosition}
                              >
                                <ListGroupItem className="list-group-item">
                                  <Form.Check
                                    disabled={tile.disableHide}
                                    id={tile.id.toString()}
                                    type="checkbox"
                                    label={tile.title}
                                    checked
                                    onChange={() => updateTileIds(tileIds.filter((x) => x !== tile.id))}
                                  />
                                  { !tile.fixedPosition
                                    ? (
                                      <div className="icon-wrapper">
                                        <FontAwesomeIcon icon={faGripVertical} />
                                      </div>
                                    )
                                    : null }
                                </ListGroupItem>
                              </Reorder.Item>
                            );
                          })}
                        </ListGroup>
                      </Reorder.Group>
                    </Form.Group>
                  )

                  : null }
                <Form.Group className={hasVisibleTiles ? 'mt-3' : ''}>
                  {dashboardTiles.filter((x) => !x.hideFromSelector && !tileIds.includes(x.id)).map((tile) => (
                    <Form.Check
                      key={tile.id}
                      id={tile.id.toString()}
                      type="checkbox"
                      label={tile.title}
                      checked={tileIds.includes(tile.id)}
                      onChange={() => updateTileIds([...tileIds, tile.id])}
                    />
                  ))}
                </Form.Group>
              </>
            )
            : <div className="mb-3">You do not have access to any dashboard tiles.</div>}
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default DashboardSelectTilesModal;
