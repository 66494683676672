import React, { useCallback, useMemo } from 'react';
import {
  Alert, Button, Col, Modal, Row, Spinner,
} from 'react-bootstrap';
import { createColumnHelper } from '@tanstack/react-table';
import { useStore } from 'zustand';
import { TableColumnDefV8 } from '../../common/table';
import { AdminAccount, AdminCustomer } from '../../types/AdminTypes';
import AdminUserAddForm from './AdminUserAddForm';
import { AdminAccountDetailsModalContent } from './AdminAccountDetailsModalContent';
import { useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { useTranslation } from '../../providers/TranslationProvider';
import { columnsToVisibilityState, useTableStoreV8 } from '../../common/table/TableStoreV8';
import { AccountProvider, Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { useGetAccountProviderAsText } from '../../utils/TranslationUtils';
import { useNewModalContext } from '../../providers/NewModalProvider';
import { ActiveStatusBadge } from '../../components/ActiveStatusBadge';
import { TableFromArray } from '../../common/table/TableFromArray';
import { arrIncludesSomeWithEmptyFixFn } from '../../common/table/filters/TableFilter';

export const AdminCustomerAccountTab = (props:{customer:AdminCustomer}) => {
  const { customer } = props;

  const getAccountProviderAsText = useGetAccountProviderAsText();
  const { hasModuleRole } = useAccount();
  const i18n = useTranslation();

  const { pushModal } = useNewModalContext();

  const handleShowAccount = (account: AdminAccount) => {
    pushModal({
      title: 'Account details',
      size: 'xl',
      ModalBodyAndFooter: ({ close, setValue, value }) => (
        <AdminAccountDetailsModalContent close={close} setValue={setValue} value={value} />
      ),
      value: {
        account,
        customer,
      },
    });
  };

  const columnHelper = createColumnHelper<AdminAccount>();

  const userColumns = useMemo<TableColumnDefV8<AdminAccount, unknown>[]>(() => [
    columnHelper.accessor('name', {
      header: 'Name',
    }),
    columnHelper.accessor('externalId', {
      header: 'ExternalId',
      cell: ({ getValue, row }) => (row.original.provider === 'azureAppRegistration'
        ? <code>{getValue()}</code>
        : <span>{getValue()}</span>),
    }),
    {
      ...columnHelper.accessor('provider', {
        header: 'Provider',
        cell: ({ getValue }) => getAccountProviderAsText(getValue()),
        filterFn: arrIncludesSomeWithEmptyFixFn,
      }),
      formatter: (provider:AccountProvider) => getAccountProviderAsText(provider),
    },
    {
      ...columnHelper.accessor('status', {
        header: 'Enabled',
        cell: ({ getValue }) => <ActiveStatusBadge status={getValue()} />,
        filterFn: arrIncludesSomeWithEmptyFixFn,
      }),
      formatter: (value) => i18n.getString(`entity.status.${value}`),
    },
  ] as TableColumnDefV8<AdminAccount, unknown>[], [columnHelper, getAccountProviderAsText, i18n]);

  const { data: pagedAccounts, invalidate: refreshAccounts } = useApi<PagedResult<AdminAccount>>(
    'module/admin/accounts',
    { customerId: customer.id, sortBy: 'name' },
  );

  const pushAddAccountModal = useCallback(async () => {
    if (await pushModal({
      title: 'Add account',
      size: 'lg',
      ModalBodyAndFooter: ({ close }) => (
        <Modal.Body>
          <AdminUserAddForm
            customer={customer}
            handleClose={(update:boolean|undefined) => close(!!update, true)}
          />
        </Modal.Body>
      ),
    })) {
      await refreshAccounts();
    }
  }, [customer, pushModal, refreshAccounts]);

  const { store: tableStore } = useTableStoreV8(
    'adminUsersTable',
    {
      visibilityState: columnsToVisibilityState(userColumns),
    },
  );

  const tableState = useStore(tableStore);

  return !pagedAccounts
    ? <Spinner animation="border" />
    : (
      <>
        {pagedAccounts.items.length ? (
          <Row>
            <Col md={12} className="overflow-auto">
              <TableFromArray
                data={pagedAccounts.items}
                state={tableState}
                columnDefs={userColumns}
                disableColumnSelect
                disablePagination={pagedAccounts.items.length <= 10}
                onRowClick={(_, account) => {
                  handleShowAccount(account);
                }}
                rowClassNameResolver={(account) => {
                  if (account.provider === 'azureAppRegistration') {
                    if (!customer.canBeAdmin) {
                      return 'text-muted account-not-supported';
                    }
                  }
                  return !account.active ? 'text-muted' : undefined;
                }}
              />
            </Col>
          </Row>
        ) : (
          <Alert variant="info" className="p-3">
            Customer has no registered accounts.
          </Alert>
        )}
        { !hasModuleRole(Module.admin, 'readWrite') ? null : (
          <Row className="mt-3">
            <Col md={12}>
              <Button onClick={async () => {
                await pushAddAccountModal();
              }}
              >
                Add account
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
};

export default AdminCustomerAccountTab;
