/* eslint-disable no-nested-ternary */
import React, {
  useMemo, useState,
} from 'react';
import {
  Button, Card, Form, Spinner,
  Stack,
} from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Icon } from '@ailibs/feather-react-ts';
import { useInfiniteQuery } from '@tanstack/react-query';
import { IEntityComment, IEntityUpdateComment, IHaveEntityId } from '../types/EntityTypes';
import { PagedResult } from '../types/PagedResult';
import RenderHtml from './RenderHtml';
import { asNameUpn, getStringDate } from '../utils/StringUtils';
import { Method, getMemoizedQueryKey, useInvalidateQueries } from '../query/GenericQuery';

export const useAddEntityComment = (entity:IHaveEntityId) => {
  const invalidateEntityComments = useInvalidateQueries(
    `entityComments/${entity.entityType}/${entity.entityId}`,
  );

  return async (entityComment:IEntityUpdateComment) => {
    const { data: addedComment } = await axios.put<IEntityComment>(
      '/api/v1/entityComments',
      entityComment,
    );

    await invalidateEntityComments();

    return addedComment;
  };
};

export const EntityCommentInput = ({
  entity,
  onCommentAdded,
}:{
  entity:IHaveEntityId,
  onCommentAdded?: (addedComment:IEntityComment) => void
}) => {
  const [comment, setComment] = useState('');
  const [busy, setBusy] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [focused, setFocused] = useState(false);
  const invalidateEntityQueries = useInvalidateQueries(`entityComments/${entity.entityType}/${entity.entityId}`);

  const addEntityComment = useAddEntityComment(entity);

  const addComment = async () => {
    setBusy(true);
    try {
      const addedComment = await addEntityComment({
        ...entity,
        entityId: entity.entityId.toString(),
        comment,
      });

      setComment('');
      setIsPreview(false);
      toast.success('Comment added!', {
        toastId: 'commentAdded',
        updateId: 'commentAdded',
      });

      await invalidateEntityQueries();

      if (onCommentAdded) {
        onCommentAdded(addedComment);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Unable to add comment: ${err}`);
    } finally {
      setBusy(false);
    }
  };

  return (
    <div className="mb-3">
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await invalidateEntityQueries();
          addComment();
        }}
      >
        <div>
          { isPreview
            ? (
              <RenderHtml className="preview form-control">
                {comment}
              </RenderHtml>
            )
            : (
              <Form.Control
                as="textarea"
                value={comment}
                disabled={busy}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                className={`growable ${comment.length > 0 ? 'dirty' : ''}`}
                placeholder="Add comment..."
                onChange={(e) => setComment(e.target.value)}
              />
            ) }
        </div>
        { focused || comment.length
          ? (
            <div className="mt-2">
              <Stack direction="horizontal" gap={2}>
                <Button
                  disabled={busy || comment.length === 0}
                  type="submit"
                >
                  Add
                </Button>
                <Button
                  variant="link"
                  disabled={busy || comment.length === 0}
                  onClick={() => setIsPreview(!isPreview)}
                >
                  { isPreview ? 'Edit' : 'Markdown preview' }
                </Button>
              </Stack>
            </div>
          ) : null }
      </form>
    </div>
  );
};

export const EntityCommentsCard = ({
  entity,
  hideAdd,
}:{
  entity:IHaveEntityId,
  hideAdd?:boolean
}) => {
  const [pageCount, setPageCount] = useState<number>();
  const commentsPerPage = 15;

  const {
    data, fetchNextPage, hasNextPage,
  } = useInfiniteQuery({
    queryKey: getMemoizedQueryKey(Method.GET, `entityComments/${entity.entityType}/${entity.entityId}`, undefined),
    queryFn: async ({ pageParam }) => {
      const { data: pagedComments } = await axios.get<PagedResult<IEntityComment>>(
        `/api/v1/entityComments/${entity.entityType}/${entity.entityId}?pageSize=${commentsPerPage}&page=${pageParam}`,
      );
      setPageCount(pagedComments.pageCount);
      return pagedComments.items;
    },
    getNextPageParam: (lastPage, allPages) => {
      if (!lastPage.length || !pageCount) {
        return undefined;
      }
      if (allPages.length === pageCount) {
        return undefined;
      }
      return allPages.length + 1;
    },
    initialPageParam: 1,
  });

  const comments = useMemo(() => data?.pages.reduce((acc, page) => [...acc, ...page], []), [data]);

  if (hideAdd && (!comments || comments?.length <= 0)) {
    return null;
  }

  return (
    <Card className="entity-comments">
      <Card.Header>Comments</Card.Header>
      <Card.Body>
        { hideAdd !== true
          ? (
            <EntityCommentInput
              entity={entity}
            />
          )
          : null }
        { !comments
          ? <Spinner animation="border" />
          : comments.length === 0
            ? hideAdd ? 'No comments.' : ''
            : (
              <ul>
                { comments.map((c) => (
                  <li key={c.id}>
                    <div className="byline">
                      <Icon name="message-square" />
                      <span className="author">
                        { asNameUpn(c.author) }
                      </span>
                      <span className="time">
                        {' @ '}
                        { getStringDate(c.created) }
                      </span>
                    </div>
                    <RenderHtml>{ c.comment }</RenderHtml>
                  </li>
                ))}
              </ul>
            )}
        { hasNextPage
          ? (
            <Stack direction="horizontal" gap={2} className="mt-3">
              <Button
                variant="primary"
                onClick={async () => fetchNextPage()}
              >
                Load more
              </Button>
            </Stack>
          )
          : null }
      </Card.Body>
    </Card>
  );
};
