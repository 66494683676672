/* eslint-disable no-param-reassign */
import React from 'react';
import {
  marked, MarkedOptions, Token, Tokens,
} from 'marked';
import DOMPurify from 'dompurify';
import './RenderHtml.css';

interface IProps {
  children?:string|undefined,
  as?:React.ElementType,
  className?:string,
  inline?:boolean,
  /**
   * Defaults to ['br']
   */
  allowedTags?: string[],
  allowedAttributes?: string[],
}

const render = (
  input:string,
  inline?:boolean,
  allowedTags?:string[],
  allowedAtteributes?:string[],
  options?:MarkedOptions,
) => (
  inline ? marked.parseInline : marked.parse
)(DOMPurify.sanitize(input, {
  ALLOWED_TAGS: allowedTags ?? ['br'],
  ADD_ATTR: allowedAtteributes ?? [],
}), options);

const toAlertClassNameSuffix = (code:string) => {
  switch (code) {
  case 'd':
    return 'danger';
  case 'i':
    return 'info';
  case 's':
    return 'success';
  case 'p':
    return 'primary';
  case 'r':
    return 'secondary';
  default:
    return 'warning';
  }
};

const RenderHtml = (props:IProps) => {
  const {
    children, className, as, inline, allowedAttributes, allowedTags,
  } = props;

  const Component = as ?? 'div';

  const containerClassName = 'rendered-markdown';
  const innerClassName = className ? `${className } ${containerClassName}` : containerClassName;

  return children
    ? (
      <Component
        className={innerClassName}
        dangerouslySetInnerHTML={{
          __html: render(children, inline, allowedTags, allowedAttributes, {
            extensions: {
              renderers: {
                // Customize code blocks and add a copy to clipboard button
                code: (token:Token) => {
                  const code = token as Tokens.Code;
                  return code.lang === 'copyable'
                    ? `
                    <div class="code-box">
                      <pre><code>${code.text.slice(3, -3)}</code></pre>
                      <button type="text" title="Copy to clipboard" class="btn btn-link" onclick="copyClipboard(this)">Copy to clipboard</button>
                    </div>`
                    : false;
                },
                alertParagraph: (token:Token) => {
                  const t = token as Tokens.Paragraph;
                  const alertClassName = toAlertClassNameSuffix(t.text[2]);
                  return `<div class="alert alert-${alertClassName} p-3">${t.text.slice(3, -3)}</div>`;
                },
              },
              childTokens: {},
            },
            walkTokens(token) {
              if (token.type === 'paragraph') {
                if (token.raw.match(/^\$\$[wdispr]/) && token.raw.endsWith('\n$$')) {
                  token.type = 'alertParagraph';
                }
              } else if (token.type === 'codespan') {
                token.text = token.text.replace(/&amp;lt;/g, '&lt;').replace(/&amp;gt;/g, '&gt;');
              } else if (token.type === 'code') {
                token.escaped = true;
                if (token.text.startsWith('$$\n') && token.text.endsWith('\n$$')) {
                  token.lang = 'copyable';
                }
              }
            },
          }),
        }}
      />
    )
    : <Component className={innerClassName} />;
};

export default RenderHtml;
