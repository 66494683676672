import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IState {
  sidebarVisible: boolean|undefined,
}

interface IStateActions {
  toggleSidebar: (visible:boolean) => void,
}

export const useGlobalStore = (initialState:IState) => create<IState & IStateActions>()(
  persist(
    (set) => ({
      sidebarVisible: initialState.sidebarVisible,
      toggleSidebar: (visible:boolean) => (
        set((state) => ({ ...state, sidebarVisible: visible }))
      ),
    }),
    {
      name: 'globalStore',
    },
  ),
)();

export default useGlobalStore;
