import React from 'react';

export const TermsAndConditions = () => (
  <div className="p-3">
    <h3>Bruksvilkår for Ivolv Cybersecurity Platform</h3>
    <p>
      Sist oppdatert: 31.05.2024
    </p>

    <p>
      Velkommen til Ivolv AS! Vi tilbyr avanserte cybersikkerhetstjenester designet
      for å beskytte dine digitale eiendeler og gi dyp innsikt i ditt IT-miljø. Ved å
      få tilgang til, eller bruke våre tjenester, samtykker du i å være bundet av
      disse bruksvilkårene (&quot;Vilkår&quot;). Hvis du ikke godtar våre vilkår, kan du ikke
      bruke våre tjenester.
    </p>

    <h4>Tjenestebeskrivelse</h4>

    <p>
      <em>Ivolv Cybersecurity Platform</em>
      {' '}
      tilbyr abonnementsbaserte
      cybersikkerhetstjenester, inkludert, men ikke begrenset til, trusseldeteksjon,
      sårbarhetsvurdering, hendelsesrespons og sikkerhetsinnsikt i kundens miljø
      (&quot;Tjenester&quot;). Våre tjenester er designet for å forbedre din digitale
      sikkerhetsstilling gjennom proaktiv overvåking og analyse.
    </p>

    <h4>Brukerforpliktelser</h4>

    <ul>
      <li>
        <em>Registrering av konto</em>
        : Du må registrere en konto for å få tilgang til våre
        tjenester. Du er selv ansvarlig for å oppgi nøyaktig, riktig og fullstendig
        informasjon om din virksomhet og tilhørende brukerdetaljer.
      </li>
      <li>
        <em>Bruk av tjenester</em>
        : Du samtykker i å bare benytte våre tjenester til lovlige
        formål og i samsvar med disse vilkårene.  Du skal ikke misbruke tjenestene på
        noen måte som kan skade, deaktivere, overbelaste eller forringe dem.
      </li>
      <li>
        <em>Databeskyttelse</em>
        : Du er ansvarlig for å sikre at din bruk av våre tjenester er
        i samsvar med alle gjeldende databeskyttelseslover. Du må innhente alle
        nødvendige samtykker og rettigheter for å dele data med oss.
      </li>
    </ul>

    <h4>Personvern og datasikkerhet</h4>

    <ul>
      <li>
        <em>Databehandling</em>
        : Vi vil behandle data mottatt fra eller omhandlende ditt
        IT-miljø i henhold til vår
        {' '}
        <a href="#personvernpolicy">Personvernpolicy</a>
        , som skisserer vår praksis
        vedrørende databeskyttelse og personvern. Databehandlingsgrunnlaget i dette
        tilfellet er for å oppfylle vår del av kontrakten med å levere
        sikkerhetstjenester til din virksomhet.
      </li>
      <li>
        <em>Konfidensialitet</em>
        : Vi opprettholder streng konfidensialitet av dine data og din
        informasjon med mindre det kreves ved lov eller for formålet med å tilby våre
        tjenester.
      </li>
    </ul>

    <h4>Immaterielle rettigheter</h4>

    <p>
      Alle immaterielle rettigheter i tjenestene, inkludert programvare, dokumentasjon
      og innhold levert som en del av tjenestene, eies av Ivolv AS eller dets
      lisensgivere. Du gis en ikke-eksklusiv, ikke-overførbar, tilbakekallelig lisens
      til å bruke tjenestene for din interne virksomhetsdrift underlagt disse
      vilkårene.
    </p>

    <h4>Oppsigelse og terminering av tjenesten</h4>

    <p>
      Vi kan avslutte din tilgang til våre tjenester når som helst ved brudd på disse
      vilkårene eller enhver annen avtale mellom deg og Ivolv AS. Ved oppsigelse
      opphører din rett til å bruke tjenestene umiddelbart.
    </p>

    <p>
      Du kan selv avslutte tjenesten ved å sende en e-post til
      {' '}
      <a href="mailto:support@ivolv.no">support@ivolv.no</a>
      . Ved konkurs hos noen av partene,
      vil avtalen opphøre med umiddelbar virkning.
    </p>

    <p>
      Ivolv AS tar forbehold om at vi kan endre vilkår for bruk av tjenesten med 30
      dagers varsel.
    </p>

    <h4>Ansvarsfraskrivelse</h4>

    <p>
      Tjenestene leveres som de er uten garantier av noe slag, enten uttrykte eller
      underforståtte. Ivolv AS gir ingen garanti for at tjenestene vil være
      kontinuerlig tilgjengelige eller feilfrie.
    </p>

    <h4>Ansvarsbegrensning</h4>

    <p>
      Dersom Ivolv AS skal holdes ansvarlig for erstatning til kunden som et resultat av
      brudd på avtalen, skal denne ikke under noen omstendighet omfatte erstatning for
      indirekte tap eller følgeskade av noen slag, inkludert, men ikke begrenset til
      tap av data, produksjon, inntekt, gevinst eller krav fra tredjepart.
    </p>
    <p>
      Samlet erstatning som kan kreves kompensert er begrenset oppad til et beløp
      tilsvarende betalt vederlag for abonnementet samlet siste 6 måneder.
    </p>

    <h4>Lovvalg og verneting</h4>

    <p>
      Eventuelle tvister mellom partene vedrørende avtalen skal søkes løst gjennom
      forhandlinger. Dersom dette ikke fører frem er partene omforent om Sør-Rogaland
      tingrett som verneting.
    </p>
    <p>
      Avtalen skal fortolkes i samsvar med norsk rett.
    </p>

    <br />

    <h3 id="personvernpolicy">Personvernpolicy</h3>

    <h4>Slik beskytter vi dine opplysninger</h4>

    <p>
      Vi tar ditt personvern på alvor og har robuste sikkerhetsmekanismer for å
      beskytte dine personlige data. For å sikre informasjonen din mot uautorisert
      tilgang, endringer eller tap, har vi innført flere tiltak, inkludert
      implementering av fysiske, tekniske og organisatoriske sikkerhetstiltak,
      herunder blant annet:
    </p>

    <ul>
      <li>
        Kryptering av all datakommunikasjon til og fra våre systemer.
      </li>
      <li>
        Streng kontroll av tilgang til personlige data, begrenset til nødvendige
        ansatte, databehandlere og deres underleverandører.
      </li>
    </ul>

    <h4>Hvilke opplysninger behandler vi</h4>

    <ul>
      <li>Navn</li>
      <li>Epost</li>
      <li>Firmanavn</li>
    </ul>

    <p>
      I tillegg vil tjenesten ha tilgang til de personopplysninger som eksponeres
      gjennom kundens integrasjoner, godkjent og initiert fra kundens side.
    </p>

    <h4>Hvor lenge lagrer vi opplysningene</h4>

    <p>
      Data vil bli fullstendig slettet 30 dager etter at tjenesten opphører.
    </p>

    <h4>Dine rettigheter</h4>

    <p>
      Du kan når som helst trekke tilbake ditt samtykke til behandling av
      personopplysninger når du bruker tjenestene hos Ivolv AS.
    </p>

    <p>
      Som bruker har du rett til å få innsyn i de personopplysningene Ivolv AS behandler
      om deg, samt informasjon om tidligere behandlet data i forbindelse med din bruk
      av våre tjenester. Du har også rett til å se hvilke sikkerhetstiltak vi har for
      å beskytte ditt personvern, forutsatt at dette innsynet ikke kompromitterer
      sikkerheten i våre systemer.
    </p>

    <p>
      Du kan be om at informasjon om deg blir korrigert hvis dataene er feilaktige
      eller ufullstendige. Du kan også be om at opplysninger slettes hvis du mener at
      de ikke lenger er nødvendige for det formålet de ble samlet inn for. For å
      korrigere eller slette data i eksterne tjenester tilgjengelig gjennom Ivolv AS, må
      du kontakte oss på
      {' '}
      <a href="mailto:support@ivolv.no">support@ivolv.no</a>
      .
    </p>

    <h4>Databehandling</h4>

    <p>
      Alle personlige opplysninger du gir til oss, blir forsvarlig lagret og fortrolig
      behandlet. Opplysningene blir bare lagret så lenge det er nødvendig for at vi
      skal kunne levere den aktuelle tjenesten, og vi lagrer ikke mer enn det vi må.
      Vi vil aldri selge eller utlevere dine personlige opplysninger til en tredjepart
      for bruk av informasjon til andre formål enn beskrevet i denne
      personvernerklæringen. Vår overordnede målsetning for personvern er å samle inn
      så lite personinformasjon som mulig. Vi benytter Microsoft som vår
      underbehandler og leverandør av hosting plattform for lagring, behandling og
      drift av tjenesten.
    </p>
  </div>
);
