import React, { useRef } from 'react';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import { QueryClient } from '@tanstack/react-query';
import { AssetsTable } from './AssetsTable';
import { IAsset } from '../../types/AssetsTypes';
import { PagedResult } from '../../types/PagedResult';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import { ElementScrollRestoration } from '../../routing/ElementScrollRestoration';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';

export const AssetsPage:IComponentWithLoader<PagedResult<IAsset>, never> = {
  loader: (queryClient:QueryClient): Promise<PagedResult<IAsset>> => (
    getOrFetchFromApi<PagedResult<IAsset>>(
      queryClient,
      'assets',
    )
  ),
  Component: () => {
    const { data: pagedAssets } = useApiLoaderData<PagedResult<IAsset>, PagedResult<IAsset>>(
      'assets',
      (loaderData) => loaderData,
    );

    const scrollContainerRef = useRef(null);

    return (
      <div>
        <Row>
          <Col>
            <Card className="fill-content">
              <Card.Header>
                <Card.Title>Assets</Card.Title>
              </Card.Header>
              <Card.Body ref={scrollContainerRef} className="overflow-auto" id="assets-card">
                <AssetsTable pagedAssets={pagedAssets} />
              </Card.Body>
            </Card>
            <ElementScrollRestoration targetId="assets-card" />
          </Col>
        </Row>
      </div>
    );
  },
};

export default AssetsPage;
