export const buildRanges = (actualInterval:number, actualMax:number) => {
  // start at 1, 1-2, 3-4, 5-6, 7-8, 9-10
  const array : string[] = [];
  let i = 1;
  while (i <= (actualMax || 10)) {
    // Custom logic to get 1-10, 10-20 instead of 1-10, 11-20, etc
    if (i !== 1 && actualMax === 100) {
      array.push(`${i - 1}-${i - 1 + (actualInterval ?? 2) - 0}`);
    } else {
      array.push(`${(i === 1 ? 0 : i)}-${i + (actualInterval ?? 2) - 1}`);
    }
    i += (actualInterval ?? 2);
  }
  return array;
};
