import React, { useCallback, useMemo } from 'react';
import {
  Badge,
  Button, Card, Form, OverlayTrigger, Stack, Tooltip,
} from 'react-bootstrap';
// @ts-ignore
import naturalSort from 'javascript-natural-sort';
import {
  IAssessmentSyncVulnerability, IAssessmentVulnerability,
} from './AssessmentTypes';
import RenderHtml from '../../components/RenderHtml';
import { AssessmentStateManager } from './AssessmentStateManager';
import useModalContext from '../../contexts/ModalContext';
import { VulnerabilityStatus } from '../vulnerabilities/Types';
import { useAccount } from '../../providers/AccountProvider';

interface IProps {
  stateManager:AssessmentStateManager
}

const getBadgeBackgroundFromVulnerabiltyStatusString = (status:string) => (
  status === VulnerabilityStatus.Mitigated ? 'success' : 'danger'
);

const AssessmentStepCard = (props:IProps) => {
  const { stateManager } = props;

  const { openPromiseConfirm } = useModalContext();
  const { user } = useAccount();

  const callbackRef = useCallback((el:HTMLButtonElement) => {
    if (el && el.parentElement?.firstElementChild === el) {
      el.focus();
    }
  }, []);

  const { question } = stateManager.getActive();

  const onCompleteStep = async (vulnerability:IAssessmentSyncVulnerability) => {
    const { confirmed, value } = await openPromiseConfirm({
      title: 'Add an observational comment?',
      content: (setValue, _, vulnAsUnknown) => {
        const vuln = vulnAsUnknown as IAssessmentVulnerability;
        return (
          <>
            <div className="mb-3">
              A
              { vuln.status === VulnerabilityStatus.Mitigated ? '' : 'n' }
              {' '}
              <Badge
                bg={getBadgeBackgroundFromVulnerabiltyStatusString(vuln?.status)}
                style={{ fontSize: '100%' }}
              >
                {vuln?.status}
              </Badge>
              {' '}
              vulnerability was identified based on your response.
            </div>
            <div className="mb-3">
              <Form.Label>Summary</Form.Label>
              <div className="form-control">{vuln?.summary}</div>
            </div>
            <div className="mb-3">
              <Form.Label>Details</Form.Label>
              <div className="form-control">{vuln?.details}</div>
            </div>
            <div className="mb-3">
              Do you want to add an additional comment?
            </div>
            <div>
              <Form.Label>Comment (optional)?</Form.Label>
              <Form.Control as="textarea" autoFocus onChange={(e) => setValue(e.target.value)} rows={5} />
            </div>
          </>
        );
      },
      okText: 'Add comment',
      cancelText: 'No comment',
      data: vulnerability,
      size: 'lg',
    });

    const commentAsString = value as string;

    const comment = commentAsString
      ? `Comment by ${user?.name} &lt;${user?.externalId}&gt;:<br/><br/>${commentAsString.trim()}`
      : undefined;

    return confirmed && value
      ? {
        ...vulnerability,
        details: vulnerability.details
          ? `${vulnerability.details}<br/><br/>${comment}`
          : comment,
      }
      : vulnerability;
  };

  // Create a local copy of the options with questions sorted, use index from file to
  // determine answer index to prevent sorting from messing up persisted answers.
  const options = useMemo(() => {
    const mOptions = (question ? [...question.options] : []).map((o, i) => ({ option: o, index: i }));
    mOptions.sort((a, b) => naturalSort(a.option.value, b.option.value));
    return mOptions;
  }, [question]);

  return question
    ? (
      <Card>
        <Card.Header>Question</Card.Header>
        <Card.Body>

          <RenderHtml as="p">
            {question.text}
          </RenderHtml>
          <Stack direction="horizontal" gap={2} className="mb-3">
            { options.map(({ option, index }) => (
              <Button
                ref={callbackRef}
                // eslint-disable-next-line react/no-array-index-key
                key={`${stateManager.getCurrentStepKey()}_${option.value}_${index}`}
                onClick={async () => stateManager.registerAnswer(
                  { option, question, index },
                  onCompleteStep,
                )}
              >
                { option.value }
              </Button>
            )) }
          </Stack>
        </Card.Body>
        <Card.Footer>
          <Stack direction="horizontal" gap={2}>
            <Button
              variant="secondary"
              onClick={() => stateManager.undoToPrevious()}
              disabled={!stateManager.canUndo()}
            >
              Undo last answer
            </Button>
            <OverlayTrigger
              overlay={(
                <Tooltip className="tooltip-sm">
                  Defer this question to a later time. The question will be listed in the assessments
                  unanswered questions list, allowing you to resume the question later.
                </Tooltip>
              )}
            >
              <Button
                variant="secondary"
                onClick={() => stateManager.skipCurrentQuestion()}
                disabled={!stateManager.canSkip()}
              >
                Skip this question
              </Button>
            </OverlayTrigger>
          </Stack>
        </Card.Footer>
      </Card>
    )
    : <div>Done!</div>;
};

export default AssessmentStepCard;
