import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import DefaultApexOptions from '../../utils/DefaultApexOptions';
import { SecurityLevel, IVulnerability, VulnerabilityStatus } from './Types';
import {
  vulnerabilityStatusAsIndex, resolveVulnerabilityStatus, vulnerabilityStatusAsHexColor,
} from './Utils';
import { useVulnerabilityStatusAsText } from '../../utils/TranslationUtils';

const ensureStatus = (
  stats: Record<SecurityLevel, {status:VulnerabilityStatus, count:number}[]>,
  status:VulnerabilityStatus,
  securityLevel:SecurityLevel,
) => {
  const idx = vulnerabilityStatusAsIndex(status) * 2;
  if (!stats[securityLevel][idx]) {
    // eslint-disable-next-line no-param-reassign
    stats[securityLevel][idx] = { status, count: 0 };
  }
  return stats[securityLevel][idx];
};

/**
 * Generate a dataset compatible with the implementation percent donut chart.
 */
export const createSecurityLevelImplementationStats = (vulns: IVulnerability[]|undefined) => {
  const stats: Record<SecurityLevel, {status:VulnerabilityStatus, count:number}[]> = {
    basic: [],
    improved: [],
    advanced: [],
    unknown: [],
  };

  if (!vulns) return stats;

  for (const vuln of vulns) {
    for (const v of resolveVulnerabilityStatus(vuln)) {
      const item = ensureStatus(stats, v.status, vuln.control.securityLevel);
      item.count += v.fraction;
      item.count = Math.round(item.count * 100) / 100;
    }
  }

  return stats;
};

export interface IDataPoint {
  status: VulnerabilityStatus,
  count: number
}

interface IProps {
  data: IDataPoint[],
  onClick?: (selectedDatapoints:IDataPoint[]) => void,
  activeVulnerabilityStatus?: VulnerabilityStatus
}

/**
 * React component for displaying a vulnerability implementation donut chart.
 */
export function ImplementationPercentDonutChart(props:IProps) {
  const { data, onClick, activeVulnerabilityStatus } = props;

  const vulnerabilityStatusAsText = useVulnerabilityStatusAsText();

  const [mSeries, mColors, mLabels] = useMemo(() => {
    const labels:string[] = [];
    const colors:string[] = [];
    const series:number[] = [];

    Object.values(VulnerabilityStatus).forEach((s) => {
      const index = vulnerabilityStatusAsIndex(s);
      labels[index] = vulnerabilityStatusAsText(s) ?? '';
      colors[index] = vulnerabilityStatusAsHexColor(s);
      series[index] = 0;
    });

    if (data.length) {
      data.forEach((details) => {
        series[vulnerabilityStatusAsIndex(details.status)] = details.count;
      });
    }
    return [series, colors, labels];
  }, [data, vulnerabilityStatusAsText]);

  const options = useMemo(() => {
    const opts:ApexCharts.ApexOptions = {
      chart: {
        ...DefaultApexOptions.chart,
        animations: {
          enabled: true,
          speed: 0,
        },
        events: {
          // https://github.com/apexcharts/apexcharts.js/issues/16
          animationEnd(chart) {
            if (activeVulnerabilityStatus) {
              chart.ctx.toggleDataPointSelection(vulnerabilityStatusAsIndex(activeVulnerabilityStatus));
            }
          },
        },
      },
      states: {
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            background: '#efeff1',
          },
        },
      },
      labels: mLabels,
      colors: mColors,
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 0,
      },
    };

    if (onClick) {
      if (opts.chart) {
        if (!opts.chart.events) {
          opts.chart.events = {};
        }
        opts.chart.events.dataPointSelection = (
          a,
          b,
          selection:{dataPointIndex:number, selectedDataPoints:number[][]},
        ) => {
          const selectedDataPoint = selection.selectedDataPoints[0] as number[];
          // Toggling between having and not having an active severity, triggers
          // a re-render (see key in Chart). If a re-render is happening while
          // we're handling the dataPointSelection an error is thrown from
          // axios. Doing this in a timeout causes the onClick to be triggered
          // after this handler is done, and fixes the issue.
          setTimeout(() => {
            if (selectedDataPoint?.length) {
              const selectedIndex = selectedDataPoint[0];
              const dataPoint = data.find((item) => item && vulnerabilityStatusAsIndex(item.status) === selectedIndex);
              onClick(dataPoint ? [dataPoint] : []);
            } else {
              onClick([]);
            }
          });
        };
      }

      if (opts.states?.active?.filter) {
        opts.states.active.filter.type = 'darken';
      }

      if (opts.plotOptions?.pie) {
        opts.plotOptions.pie.expandOnClick = true;
      }
    }

    return opts;
  }, [mLabels, mColors, onClick, activeVulnerabilityStatus, data]);

  return (
    <Chart
      options={options}
      series={mSeries}
      type="donut"
      height="300"
      key={activeVulnerabilityStatus ?? 'none'}
    />
  );
}

export default ImplementationPercentDonutChart;
