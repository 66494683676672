import React, { useMemo, useState } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import {
  Card, Col, Row,
} from 'react-bootstrap';
import { groupVulnerabilityControlsByTopic, createTopicProgressData } from './Utils';
import VulnerabilityTopicTable from './VulnerabilityTopicTable';
import { IVulnerabilityListOptions } from '../../types/Types';
import { IVulnerability, SecurityLevel, VulnerabilityStatus } from './Types';
import VulnerabilityFilter from './VulnerabilityFilter';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { ILoaderData, vulnerabilitiesLoader } from './loader';
import { VulnerabilityControlQueryUtil, VulnerabilityControlSubFilters, IVulnerabilityControlFilter } from './VulnerabilityControlFilter';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { PagedResult } from '../../types/PagedResult';
import { useApiLoaderData } from '../../query/GenericQuery';

interface IStatusLevelPair {
  level:SecurityLevel,
  status:VulnerabilityStatus|undefined
}

export const VulnerabilitiesByControlPage:IComponentWithLoader<ILoaderData, IVulnerabilityListOptions> = {
  loader: vulnerabilitiesLoader,
  Component: () => {
    const [, setLocalFilters] = useState<IVulnerabilityControlFilter>();
    const searchParamsTuple = useSearchParams();
    const { getCustomerSetting } = useAccount();

    const {
      options,
      snapshot: selectedSnapshot,
    } = useLoaderData() as Awaited<ILoaderData>;

    const { data: pagedVulnerabilities } = useApiLoaderData<PagedResult<IVulnerability>, ILoaderData>(
      'vulnerabilities',
      (loaderData) => loaderData.pagedVulnerabilities,
      options,
    );

    const vulnerabilities = pagedVulnerabilities.items;

    const customerSecurityLevel = getCustomerSetting(Module.none, 'security-level', SecurityLevel.Unknown);

    const queryUtil = useMemo(() => new VulnerabilityControlQueryUtil(
      customerSecurityLevel,
      (self) => {
        const filter = self.getFilters();
        setLocalFilters({
          softSecurityLevels: filter.softSecurityLevels,
          softVulnerabilityStatuses: filter.softVulnerabilityStatuses,
        } = filter);
      },
      searchParamsTuple,
    ), [customerSecurityLevel, searchParamsTuple]);

    const filters = queryUtil.getFilters();

    const vulnerabilitiesByTopic = groupVulnerabilityControlsByTopic(
      vulnerabilities,
      filters.securityLevel ?? SecurityLevel.Advanced,
    );
    const topics = Object.keys(vulnerabilitiesByTopic);

    const getActiveStatusLevelPairFromFilter = (
      filter:IVulnerabilityControlFilter|undefined,
    ) : IStatusLevelPair|undefined => {
      if (filter
        && filter.softSecurityLevels?.length === 1
        && filter.softVulnerabilityStatuses?.length === 1
      ) {
        const level = filter.softSecurityLevels[0];
        const status = filter.softVulnerabilityStatuses[0];
        return { level, status };
      }
      return undefined;
    };

    const [, setActiveStatusLevelPair] = useState<IStatusLevelPair|undefined>(
      getActiveStatusLevelPairFromFilter(filters),
    );

    const hiddenStatuses = useMemo(() => {
      if (!filters.softVulnerabilityStatuses?.length) return [];
      const mHiddenStatuses:VulnerabilityStatus[] = [];
      Object.values(VulnerabilityStatus).forEach((s) => {
        if (!filters.softVulnerabilityStatuses?.includes(s)) mHiddenStatuses.push(s);
      });
      return mHiddenStatuses;
    }, [filters.softVulnerabilityStatuses]);

    const hiddenSecurityLevels = useMemo(() => {
      if (!filters.softSecurityLevels?.length) return [];
      const mHiddenSecurityLevels:SecurityLevel[] = [];
      Object.values(SecurityLevel).forEach((s) => {
        if (!filters.softSecurityLevels?.includes(s)) mHiddenSecurityLevels.push(s);
      });
      return mHiddenSecurityLevels;
    }, [filters.softSecurityLevels]);

    const topicProgressData = useMemo(
      () => createTopicProgressData(vulnerabilitiesByTopic, topics),
      [vulnerabilitiesByTopic, topics],
    );

    const hasVulnerabilities = useMemo(
      () => vulnerabilities?.length,
      [vulnerabilities],
    );

    return (
      <>
        <Row>
          <Col md={12}>
            <VulnerabilityFilter<IVulnerabilityControlFilter, VulnerabilityControlQueryUtil>
              queryUtil={queryUtil}
              customerSecurityLevel={customerSecurityLevel}
              /* snapshots={snapshots}
              selectedSnapshot={selectedSnapshot} */
              setFilters={(f) => {
                setActiveStatusLevelPair(getActiveStatusLevelPairFromFilter(f));
                const newLocalFilters:IVulnerabilityControlFilter = {};
                if (f.softSecurityLevels?.length) {
                  newLocalFilters.softSecurityLevels = f.softSecurityLevels;
                }
                if (f.softVulnerabilityStatuses?.length) {
                  newLocalFilters.softVulnerabilityStatuses = f.softVulnerabilityStatuses;
                }
                setLocalFilters(newLocalFilters);
              }}
              childrenFilterLabel={(
                filters.softSecurityLevels?.length !== 3
                || filters.softVulnerabilityStatuses?.length !== 3)
                ? <span>Control visibility is filtered</span>
                : undefined}
            >
              <VulnerabilityControlSubFilters
                queryUtil={queryUtil}
                maxSecurityLevel={filters.securityLevel}
              />
            </VulnerabilityFilter>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Header>
                Vulnerabilities by topic
              </Card.Header>
              <Card.Body>
                { !hasVulnerabilities
                  ? (
                    <div>
                      There are no vulnerabilities for the selected scope.
                    </div>
                  )
                  : Object.entries(vulnerabilitiesByTopic).map((record) => {
                    const [topic, controls] = record;

                    return (
                      <React.Fragment key={topic}>
                        <VulnerabilityTopicTable
                          name={topic}
                          progress={topicProgressData.get(topic) ?? 0}
                          hiddenStatuses={hiddenStatuses}
                          hiddenSecurityLevels={hiddenSecurityLevels}
                          snapshot={selectedSnapshot}
                          data={controls}
                        />
                      </React.Fragment>
                    );
                  })}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  },
};

export default VulnerabilitiesByControlPage;
