import { useCallback, useMemo, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';

const redirectTargets = ['dashboard', 'signup'] as const;
export type RedirectTarget = typeof redirectTargets[number];

const SEARCH_PARAM_LOGIN_KEY = 'autologin';
const SEARCH_PARAM_REDIRECT_KEY = 'redirect';

export const useAutoLogin = () => {
  const { instance } = useMsal();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const url = useMemo(() => new URL(document.location.href), []);

  const autoLoginRequested = url.searchParams.has(SEARCH_PARAM_LOGIN_KEY);

  const clearAutoLoginRequest = useCallback(() => {
    url.searchParams.delete(SEARCH_PARAM_LOGIN_KEY);
    url.searchParams.delete(SEARCH_PARAM_REDIRECT_KEY);
    window.history.replaceState({}, '', url);
  }, [url]);

  const autologinState:{
    loginPromise?: Promise<void>,
  } = {};

  const attemptExecuteAutoLogin = useCallback(async () => {
    if (!autoLoginRequested) {
      return false;
    }
    if (isLoggingIn) {
      return true;
    }

    setIsLoggingIn(true);

    // Remove URL parameters to ensure msal redirects to non-autologin URL
    clearAutoLoginRequest();
    await instance.clearCache();

    if (!autologinState.loginPromise) {
      autologinState.loginPromise = instance.loginRedirect({
        ...loginRequest,
        prompt: 'select_account',
      });
    }
    await autologinState.loginPromise;

    return true;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoggingIn,
    attemptExecuteAutoLogin,
    // clearAutoLoginRequest,
  };
};
