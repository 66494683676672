/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import {
  Button, Card, Stack,
} from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useAccount } from '../../providers/AccountProvider';
import { useShowTerms } from '../../components/useShowTerms';
import { useApi } from '../../query/GenericQuery';
import { LoadingCard } from '../../routing/LoadingTemplate';
import { useLogout } from '../../providers/BearerTokenProvider';

export const AcceptInviteCard = () => {
  const { user, refreshAccess } = useAccount();
  const navigate = useNavigate();
  const showTerms = useShowTerms();
  const logout = useLogout(window.location.href);
  const [searchParams] = useSearchParams();

  const customerId = searchParams.get('customerId');
  const accountId = searchParams.get('accountId');
  if (!customerId) throw new Error('Missing customerId parameter');
  if (!accountId) throw new Error('Missing accountId parameter');

  const { data: hasInvite, isLoading } = useApi<boolean>(
    `accountInvite/hasInvite/${customerId}`,
  );

  useEffect(() => {
    if (user.id !== accountId) {
      logout();
    }
  }, [accountId, logout, user]);

  const acceptInvite = async () => {
    try {
      await axios.post(`api/v1/accountInvite/accept?customerId=${customerId}`);
      toast.success('Invitation accepted!');
      refreshAccess();
      navigate('/');
    } catch (err) {
      const axiosError = err as AxiosError;
      if (axiosError.response?.status === 403) {
        toast.error('You are not allowed to accept this invite.', {
          toastId: 'accept-error',
          updateId: 'accept-error',
        });
      } else {
        toast.error('Something unexpected happened while attempting tp accept the invite. Please contact support@ivolv.no if the problem persists.', {
          toastId: 'accept-error',
          updateId: 'accept-error',
        });
      }
    }
  };

  if (!isLoading && !hasInvite) {
    navigate('/');
  }

  return isLoading
    ? (
      <LoadingCard>
        Verifying invitation
      </LoadingCard>
    )
    : (
      <Card className="fill card-md">
        <Card.Header>
          You&apos;ve been invited!
        </Card.Header>
        <Card.Body>
          Welcome! Let&apos;s finalize your invitation.
          <br />
          <br />
          Read the
          {' '}
          <a
            href="#"
            onClick={() => { showTerms(); }}
            className="m-0 p-0"
          >
            Terms of Use for the Ivolv Cybersercurity Platform
          </a>
          , and click the
          {' '}
          <em>Accept invitation</em>
          {' '}
          button below to accept the terms and activate your account when ready.
        </Card.Body>
        <div className="card-button-footer">
          <Stack direction="horizontal" gap={2}>
            <div>
              <Button
                variant="primary"
                onClick={async () => acceptInvite()}
                autoFocus
              >
                Accept invitation and terms of use
              </Button>
            </div>
            <Button
              variant="secondary"
              className="ms-auto"
              onClick={() => logout()}
            >
              Log out
            </Button>
          </Stack>
          <div className="small">
            By accepting the invitation you are also consenting to the
            {' '}
            <a
              href="#"
              onClick={() => { showTerms(); }}
              className="m-0 p-0"
            >
              Ivolv AS Terms of Use
            </a>
          </div>
        </div>
      </Card>
    );
};
