import { Configuration } from '@azure/msal-browser';

const clientId = '747a530b-ca88-4d05-9bc4-e0fdafe96f78';

export const createMsalConfig = ({ redirectUri }:{ redirectUri: string }) : Configuration => ({
  auth: {
    clientId,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
});

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [`${clientId}/.default`],
};
