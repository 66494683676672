import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { createAlgizRouter } from './Router';
import { LoadingTemplate } from './LoadingTemplate';
import { useAccount } from '../providers/AccountProvider';
import { ValidPageSizes } from '../common/table/PaginationV8';
import { useDefaultTableStoreV8 } from '../common/table/TableStoreV8';

export const AuthenticatedApp = () => {
  const queryClient = useQueryClient();
  const account = useAccount();

  const { store: tableStore } = useDefaultTableStoreV8();
  const paginationState = tableStore.getState().pagination;

  const router = createAlgizRouter(
    queryClient,
    account,
    paginationState.pageSize as ValidPageSizes,
  );

  return (
    <RouterProvider
      router={router}
      fallbackElement={(
        <LoadingTemplate>
          Fetching data
        </LoadingTemplate>
      )}
    />
  );
};
