import React, { useState } from 'react';
import ConfirmModal from '../common/modal/ConfirmModal';
import { ModalContext } from '../contexts/ModalContext';

export interface IModalContext {
  openModal: (
    options: {
      title: string,
      content: string | JSX.Element | JSX.Element[] | undefined,
      closeText?: string,
      size?: 'sm'|'lg'|'xl',
    },
  ) => Promise<unknown>,

  /**
   * Call to open a confirm dialog (much like `window.confirm('Some text')`)
   * @param onConfirm this is called when user presses confirm/cancel buttons in the dialog, and returns the result
   * @param title an optional custom title
   */
  openConfirm: (
    options: {
      title?: string,
      content?: string | JSX.Element | JSX.Element[] | undefined,
      okText?: string,
      cancelText?: string,
      size?: 'sm'|'lg'|'xl'
    },
  ) => Promise<boolean>,

  openConfirmLegacy: (
    onConfirm: (confirmed: boolean) => void,
    title?: string,
    content?: string | JSX.Element | JSX.Element[] | undefined,
    okText?: string,
    cancelText?: string,
    size?: 'sm'|'lg'|'xl'
  ) => void,

  openPromiseConfirm: (
    options: {
      title: string,
      content: (
        setValue:(value:unknown) => void,
        setOkDisabled:(enabled:boolean) => void,
        data:unknown|undefined,
      ) => (
        string | JSX.Element | JSX.Element[] | undefined
      ),
      okText?: string,
      cancelText?: string,
      data?: unknown,
      size?: 'sm'|'lg'|'xl'
    },
  ) => Promise<{confirmed:boolean, value:unknown}>
}

/**
 * A provider which adds a ConfirmModal to the "global" context.
 * Call openConfirm from useGlobalContext to open a confirm dialog.
 */
export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const { Provider } = ModalContext;
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [onConfirm, setOnConfirm] = useState<(confirmed: boolean, value: unknown) => void>();
  const [confirmTitle, setConfirmTitle] = useState<string>();
  const [confirmContent, setConfirmContent] = useState<string|JSX.Element|JSX.Element[]|undefined>(undefined);
  const [confirmOkText, setConfirmOkText] = useState<string>();
  const [confirmCancelText, setConfirmCancelText] = useState<string>();
  const [value, setValue] = useState<unknown>('');
  const [modalSize, setModalSize] = useState<'sm'|'lg'|'xl'|undefined>();
  const [okDisabled, setOkDisabled] = useState(false);
  const [returnValues, setReturnValues] = useState<{confirmed:boolean, value:unknown}>();

  const onClose = (c:boolean, v:unknown) => {
    setReturnValues({ confirmed: c, value: v });
    setValue(undefined);
    setShowConfirm(false);
  };

  const onExited = () => {
    if (onConfirm) {
      onConfirm(!!returnValues?.confirmed, returnValues?.value);
    }
    setReturnValues(undefined);
  };

  const openModal = (
    options: {
      title: string,
       content: string | JSX.Element | JSX.Element[] | undefined,
      closeText?: string,
      size?: 'sm'|'lg'|'xl',
    },
  ) => {
    let resolver:(value:undefined) => void;
    const promise = new Promise<undefined>((resolve) => {
      resolver = resolve;
    });

    setOnConfirm(() => () => {
      setTimeout(() => {
        if (resolver) resolver(undefined);
      });
    });

    setConfirmTitle(options.title);
    setShowConfirm(true);
    setConfirmContent(options.content);
    setConfirmOkText(options.closeText ?? 'Close');
    setConfirmCancelText(undefined);
    setModalSize(options.size);

    return promise;
  };

  const openConfirm = (
    options: {
      title?: string,
      content?: string | JSX.Element | JSX.Element[] | undefined,
      okText?: string,
      cancelText?: string,
      size?: 'sm'|'lg'|'xl',
    },
  ) => {
    let resolver:(confirmed:boolean) => void;
    const promise = new Promise<boolean>((resolve) => {
      resolver = (c:boolean) => {
        resolve(c);
      };
    });

    setOnConfirm(() => (confirmed:boolean) => {
      setTimeout(() => {
        if (resolver) resolver(confirmed);
      });
    });

    setConfirmTitle(options.title);
    setConfirmContent(options.content);
    setConfirmOkText(options.okText ?? 'Ok');
    setConfirmCancelText(options.cancelText ?? 'Cancel');
    setModalSize(options.size);
    setShowConfirm(true);

    return promise;
  };

  const openConfirmLegacy = (
    func: (confirmed: boolean) => void,
    title?: string,
    content?: string | JSX.Element | JSX.Element[] | undefined,
    okText?: string,
    cancelText?: string,
    size?: 'sm'|'lg'|'xl',
  ) => {
    setOnConfirm(() => (confirmed:boolean) => func(confirmed));
    setConfirmTitle(title);
    setConfirmContent(content);
    setConfirmOkText(okText ?? 'Ok');
    setConfirmCancelText(cancelText ?? 'Cancel');
    setModalSize(size);
    setShowConfirm(true);
  };

  const openPromiseConfirm = (
    options: {
      title: string,
      content: (
        setValue:(value:unknown) => void,
        setOkDisabled:(enabled:boolean) => void,
        data: unknown|undefined
      ) => string | JSX.Element | JSX.Element[] | undefined,
      okText?: string,
      cancelText?: string,
      data?: unknown,
      size?: 'sm'|'lg'|'xl',
    },
  ) => {
    let resolver:(confirmed:boolean, value:unknown) => void;
    const promise = new Promise<{confirmed:boolean, value:unknown}>((resolve) => {
      resolver = (c:boolean, v:unknown) => {
        resolve({ confirmed: c, value: v });
      };
    });

    // https://stackoverflow.com/a/58458899
    setOnConfirm(() => (confirmed:boolean, v:unknown) => {
      setTimeout(() => {
        if (resolver) resolver(confirmed, v);
      });
    });

    setConfirmTitle(options.title);
    setConfirmOkText(options.okText ?? 'Ok');
    setConfirmContent(options.content(setValue, setOkDisabled, options.data));
    setConfirmCancelText(options.cancelText ?? 'Cancel');
    setModalSize(options.size);
    setShowConfirm(true);

    return promise;
  };

  return (
    <Provider value={{
      openModal, openConfirmLegacy, openConfirm, openPromiseConfirm,
    }}
    >
      {children}
      <ConfirmModal
        show={showConfirm}
        onClose={onClose}
        onExited={onExited}
        content={confirmContent}
        title={confirmTitle}
        okText={confirmOkText}
        value={value}
        size={modalSize}
        cancelText={confirmCancelText}
        okDisabled={okDisabled}
      />
    </Provider>
  );
};

export default ModalProvider;
