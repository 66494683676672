import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { createMsalConfig } from './authConfig';
import App from './App';
// Font import(s)
// Alt. specify in index.html
// /xyz is the weight
import '@fontsource/inter';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { ConfigContextProvider, loadConfig } from './contexts/ConfigContext';
import SettingsProvider from './providers/SettingsProvider';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

loadConfig().then((config) => {
  const msalInstance = new PublicClientApplication(createMsalConfig({
    redirectUri: config.AZURE_REDIRECT_URI,
  }));

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
  });

  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <ConfigContextProvider config={config}>
          <SettingsProvider>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </SettingsProvider>
        </ConfigContextProvider>
      </MsalProvider>
    </React.StrictMode>,
  );
});
