import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from 'react-router-dom';
import { useEntitiesQuery } from '../query/EntityQueries';
import { ITaggedEntity } from '../types/EntityTypes';
import { useEntityTypeAsText } from '../utils/TranslationUtils';
import { getEntityUri } from './Utils';

/**
 * React component for searching for tags (and itemtypes that are tagged)
 *
 * Added to the topbar of the page.
 */
const EntitySearch = () => {
  const navigate = useNavigate();
  const entityTypeAsText = useEntityTypeAsText();

  const [searchText, setSearchText] = useState('');

  const minQueryLength = 2;
  const enabled = searchText.length >= minQueryLength;

  const {
    data: tagMappings,
    isLoading,
  } = useEntitiesQuery(
    { search: searchText },
    enabled,
  );

  return (
    <AsyncTypeahead
      id="tag-topbar-search"
      labelKey="entityId"
      className="search"
      searchText={searchText}
      filterBy={() => true}
      onSearch={(s) => setSearchText(s)}
      options={tagMappings?.items ?? []}
      isLoading={enabled && isLoading}
      placeholder="Search"
      minLength={minQueryLength}
      selected={[]}
      highlightOnlyResult
      renderMenuItemChildren={(o) => {
        const entity = o as ITaggedEntity;
        return (
          <div key={`${entity.entityType}-${entity.entityId}`}>
            <div className="fw-bold text-capitalize">
              {entityTypeAsText(entity.entityType)}
            </div>
            <div style={{ fontSize: 12 }} className="fst-italic text-break text-wrap">
              {entity.name}
            </div>
          </div>
        );
      }}
      onChange={(selected) => (selected[0]
        ? navigate(getEntityUri(selected[0] as ITaggedEntity))
        : undefined)}
    />
  );
};

export default EntitySearch;
