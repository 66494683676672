import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useMsal } from '@azure/msal-react';
import {
  Alert, Button, Card, Stack,
} from 'react-bootstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useConfigContext } from '../contexts/ConfigContext';
import { loginRequest } from '../authConfig';
import useSettingsContext from '../contexts/SettingsContext';
import { LoadingTemplate } from '../routing/LoadingTemplate';
import { NoSidebarTemplate } from '../AuthenticatedAppTemplate';

export const useLogout = (redirectPath?:string|undefined) => {
  const queryClient = useQueryClient();
  const { instance: msal } = useMsal();
  const settings = useSettingsContext();

  return useCallback(() => {
    settings.setActiveCustomerId(undefined);
    msal.logoutRedirect({
      onRedirectNavigate: () => false,
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
    queryClient.clear();
    window.location.href = redirectPath ?? '/';
  }, [msal, queryClient, redirectPath, settings]);
};

interface IBearerTokenData {
  token: string,
  userName: string,
  tenantId: string,
  expiresOn: Date|null
}

export interface IBearerTokenContext {
  tokenData: IBearerTokenData,
  refresh: () => void,
}

export const BearerTokenContext = createContext<IBearerTokenContext|undefined>(undefined);

export const useBearerTokenContext = () => {
  const context = useContext(BearerTokenContext);
  if (!context) {
    throw new Error('No BearerTokenContext found when calling useBearerTokenContext');
  }
  return context;
};

export const BearerTokenProvider = (
  { children }: {children:React.ReactElement },
) => {
  const config = useConfigContext();
  const { activeCustomerId } = useSettingsContext();
  const { instance: msalInstance } = useMsal();
  const logout = useLogout();
  const [tokenData, setTokenData] = useState<IBearerTokenData>();
  const [tokenRefreshError, setTokenRefreshError] = useState<string>();
  const [refreshRequested, setRefreshRequested] = useState<number>();
  const [hasTimeout, setHasTimeout] = useState<boolean>(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('apiToken')) {
      setTokenData({
        token: params.get('apiToken') ?? '',
        userName: 'unknown',
        tenantId: 'unknown',
        expiresOn: new Date(),
      });
      return () => {};
    }

    const timeouts:NodeJS.Timeout[] = [];
    // Setup the timeout indicator
    setHasTimeout(false);
    timeouts.push(setTimeout(() => {
      setHasTimeout(true);
    }, 7000));

    const acquireToken = async () => {
      const accounts = msalInstance.getAllAccounts();
      let response;
      try {
        // Try to aquire the token silently first.
        response = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          redirectUri: config?.AZURE_REDIRECT_URI,
          account: accounts.length ? accounts[0] : undefined,
        });
      } catch (e) {
        // If this fails (e.g. access token has expired), use popup.
        response = await msalInstance.acquireTokenPopup({
          ...loginRequest,
          redirectUri: config?.AZURE_REDIRECT_URI,
          account: accounts.length ? accounts[0] : undefined,
        });
      }

      return response
        ? {
          token: response.accessToken,
          userName: response.account.username,
          tenantId: response.tenantId,
          expiresOn: response.expiresOn,
        }
        : undefined;
    };

    acquireToken()
      .then((t) => {
        // Don't update if token and expiry is the same
        if (t?.token === tokenData?.token && t?.expiresOn === tokenData?.expiresOn) {
          return;
        }
        setTokenData(t);
        setTokenRefreshError(undefined);
      })
      .catch((e) => {
        setTokenData(undefined);
        setTokenRefreshError(e);
      });

    return () => {
      timeouts.forEach((t) => clearTimeout(t));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    msalInstance,
    config,
    activeCustomerId,
    logout,
    refreshRequested,
    queryClient,
  ]);

  const providerValue = useMemo(() => (
    tokenData
      ? {
        tokenData,
        refresh: () => {
          // If its more than 5 seconds since we last requested a token, renew request
          if (!refreshRequested || (refreshRequested + 5000) < new Date().getTime()) {
            setRefreshRequested(new Date().getTime());
          }
        },
      }
      : undefined
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [tokenData]);

  if (tokenRefreshError) {
    return (
      <NoSidebarTemplate>
        <Card className="card-sm">
          <Card.Header>Authenticating</Card.Header>
          <Card.Body>
            <p>
              To continue, we need to refresh your login.
            </p>
            <p>
              If the login window does not appear, you should check that your browser has not blocked it.
              If it has, please allow pop-ups for this site and try again.
            </p>
            <p>
              If you&apos;re still having trouble you can retry authentication with the button below.
              If the issue persists, please log out and then log in again.
            </p>
            <Stack direction="horizontal" gap={2} className="mt-3">
              <Button
                onClick={() => window.location.reload()}
              >
                Reload
              </Button>
              <Button
                variant="secondary"
                onClick={() => logout()}
              >
                Logout
              </Button>
            </Stack>
          </Card.Body>
        </Card>
      </NoSidebarTemplate>
    );
  }

  return providerValue
    ? (
      <BearerTokenContext.Provider value={providerValue}>
        {children}
      </BearerTokenContext.Provider>
    )
    : (
      <LoadingTemplate>
        { !hasTimeout
          ? 'Acquiring token'
          : (
            <Card className="fill card-md transparent">
              <Card.Body>
                Acquiring token...

                <Alert variant="warning" className="p-3 mt-3 text-start">
                  <div>
                    <p>
                      Token acquisition is taking longer than expected, and a login popup window
                      should have appeared. If it does not, you should check that your browser has not blocked it.
                      If it has, please allow pop-ups for this site and try again.
                    </p>
                    <p>
                      If you&apos;re still having trouble you can retry authentication with the button below.
                      If the issue persists, please log out and then log in again.
                    </p>
                    <Stack direction="horizontal" gap={2} className="mt-3">
                      <Button
                        onClick={() => window.location.reload()}
                      >
                        Reload
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => logout()}
                      >
                        Logout
                      </Button>
                    </Stack>
                  </div>
                </Alert>
              </Card.Body>
            </Card>
          )}
      </LoadingTemplate>
    );
};
