import { AxiosError } from 'axios';
import { IErrorDetails } from '../types/Types';

export const asClientError = (err:unknown) => {
  if (err instanceof AxiosError) {
    return {
      status: err.response?.status ?? -1,
      title: err.response?.statusText ?? 'An unknown error occured',
      details: (err.response?.data as IErrorDetails).detail,
    };
  }
  return null;
};
