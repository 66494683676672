import { ColumnFilter, Row } from '@tanstack/react-table';
import React from 'react';
import { NoClipDropdown } from '../../NoClipDropdown';
import { buildRanges } from './Utils';
import { FilterV8Props } from './TableFilter';
import { getFilterName } from './FilterHelpersV8';

type RangeSelectFilterV8<T> = Omit<FilterV8Props<T>, 'table'> & {
  max?: number,
  interval?: number,
};

type ColumnFilterValue = ColumnFilter['value'];

/**
 * Custom filter for filtering out rows in a range (multi)select
 */
export const rangeSelectFilterV8 = <T, >(
  row: Row<T>,
  columnId: string,
  filterValue: ColumnFilterValue,
): boolean => {
  const filters = filterValue as string[];
  if (!filters || filters.length === 0) return true;

  // map from 1-10 to 1,2,...,9,10 !
  const actualFilters: number[] = [];
  for (const value of filters) {
    const split = value.replace('search_', '').split('-');
    const first = Number.parseInt(split[0], 10);
    if (split.length > 1) {
      const last = Number.parseInt(split[1], 10);
      let x = first;
      while (x <= last) {
        actualFilters.push(x);
        x += 1;
      }
      if (Number.isNaN(last) || first >= last) actualFilters.push(first);
    } else {
      actualFilters.push(first);
    }
  }

  // @ts-ignore
  return actualFilters.includes(row.original[columnId] as number);
};

export const RangeSelectFilterV8 = <T, >(props: RangeSelectFilterV8<T>) => {
  const {
    column, size, max, interval,
  } = props;

  const actualMax = max ?? 100;
  const actualInterval = interval ?? 10;

  const items = React.useMemo(() => (
    buildRanges(actualInterval, actualMax)
  ), [actualInterval, actualMax]);

  const columnFilterValue = column.getFilterValue() as string[] | undefined;

  const handleChecked = (checked: boolean, value: ColumnFilterValue) => {
    if (checked) {
      if (!columnFilterValue) {
        column.setFilterValue([value]);
      } else {
        const newFilterValue = [...columnFilterValue, value];
        column.setFilterValue(newFilterValue);
      }
    } else if (columnFilterValue) {
      const newFilterValue = columnFilterValue.filter((v) => v !== value);
      column.setFilterValue(newFilterValue);
    }
  };

  return (
    <NoClipDropdown
      className={columnFilterValue?.length ? 'has-selection' : undefined}
      filterLabel={getFilterName(columnFilterValue, 'All', true)}
      filterId={column.id}
      itemId={(i) => i as string}
      itemLabel={(i) => i as string}
      checked={(i) => (
        columnFilterValue?.includes(i as string) ?? false
      )}
      onCheck={handleChecked}
      size={size}
      values={items}
    />
  );
};

export default RangeSelectFilterV8;
