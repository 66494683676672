import React from 'react';
import {
  Outlet, createBrowserRouter,
} from 'react-router-dom';
import ROUTES from './Routes';
import {
  NoSidebarTemplate,
} from '../AuthenticatedAppTemplate';
import { SignupCard } from '../pages/signin/SignupCard';
import { SignInCard } from '../pages/signin/SignIn';
import { TermsAndConditionsCard } from '../pages/signin/TermsAndConditionsCard';
import { AcceptInviteUnauthenticatedCard } from '../pages/signin/AcceptInviteUnauthenticatedCard';

// eslint-disable-next-line react-hooks/rules-of-hooks
export const createUnauthenticatedAlgizRouter = () => (
  createBrowserRouter([
    {
      element: (
        <NoSidebarTemplate>
          <Outlet />
        </NoSidebarTemplate>
      ),
      children: [
        {
          path: ROUTES.signup.uri,
          element: <SignupCard />,
        },
        {
          path: ROUTES.acceptInvite.uri,
          element: <AcceptInviteUnauthenticatedCard />,
        },
        {
          path: '*',
          element: <SignInCard />,
        },
        {
          path: ROUTES.termsAndConditions.uri,
          element: (
            <TermsAndConditionsCard />
          ),
        },
      ],
    },
  ])
);
