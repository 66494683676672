import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { Spinner } from 'react-bootstrap';
import { useStore } from 'zustand';
import { IAsset } from '../../types/AssetsTypes';
import { TableColumnDefV8, TableCellDateFormattedV8 } from '../../common/table';
import { PagedResult } from '../../types/PagedResult';
import { useAssetTypeAsText } from '../../utils/TranslationUtils';
import { columnsToVisibilityState, useTableStoreV8 } from '../../common/table/TableStoreV8';
import { AssetType } from '../../types/Types';
import { TableFromArray } from '../../common/table/TableFromArray';
import { arrIncludesSomeWithEmptyFixFn } from '../../common/table/filters/TableFilter';
import { dateStringFilterFn } from '../../common/table/filters/DateStringFilterV8';

/**
 * React table for showing assets. Note that it uses the generic ReactTable component.
 * @see ReactTable
 */
export const AssetsTable = (props:{pagedAssets:PagedResult<IAsset>}) => {
  const { pagedAssets } = props;
  const navigate = useNavigate();
  const assetTypeAsText = useAssetTypeAsText();

  const columnHelper = createColumnHelper<IAsset>();

  const assetColumns = React.useMemo<TableColumnDefV8<IAsset, unknown>[]>(() => (
    [
      {
        ...columnHelper.accessor('friendlyId', {
          header: 'Unique ID',
        }),
        disableColumnDefault: true,
      },
      columnHelper.accessor('name', {
        header: 'Name',
        enableHiding: false,
      }),
      {
        ...columnHelper.accessor('type', {
          header: 'Type',
          cell: ({ getValue }) => (
            <span>
              {assetTypeAsText(getValue())}
            </span>
          ),
          filterFn: arrIncludesSomeWithEmptyFixFn,
        }),
        formatter: (v) => assetTypeAsText(v as AssetType, false),
      },
      columnHelper.accessor('created', {
        header: 'Created',
        cell: ({ getValue }) => TableCellDateFormattedV8(getValue()),
        filterFn: dateStringFilterFn,
      }),
    ] as TableColumnDefV8<IAsset, unknown>[]
  ), [assetTypeAsText, columnHelper]);

  const onRowClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, object: IAsset) => {
    if (object.id) {
      if (e.button === 3 || e.ctrlKey) {
        window.open(`/asset/${object?.id}`, '_blank');
      } else {
        navigate(`/asset/${object?.id}`);
      }
    }
  };

  const { store: tableStore } = useTableStoreV8(
    'asset',
    {
      visibilityState: columnsToVisibilityState(assetColumns),
    },
  );

  const tableState = useStore(tableStore);

  return (
    <div>
      {
        pagedAssets
          ? (
            // Note that the id of the table below is used when saving filter
            // values to session storage. That means, if you change the id it
            // will clear all saved filters in session storage for all users.
            <TableFromArray
              state={tableState}
              columnDefs={assetColumns}
              data={pagedAssets.items}
              onRowClick={onRowClick}
            />
          )
          : <Spinner animation="border" className="mt-3" />
      }
    </div>
  );
};

export default AssetsTable;
