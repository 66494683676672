/* eslint-disable no-nested-ternary */
import React, {
  ReactElement,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Badge, Dropdown, DropdownButton, OverlayTrigger, Spinner, Tooltip,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import {
  ControlAction, IActionTarget, IControl, IControlDetails, IVulnerability, Severity,
  Significance, VulnerabilityStatus,
} from './Types';
import { useApi, useInvalidateQueries } from '../../query/GenericQuery';
import { asNameUpn, getStringDate } from '../../utils/StringUtils';
import ControlRemediateModal from './ControlRemediateModal';
import ControlUndoModal from './ControlUndoModal';
import { useTranslation } from '../../providers/TranslationProvider';
import { IRisk } from '../../types/RiskTypes';
import RenderHtml from '../../components/RenderHtml';
import {
  columnsToVisibilityState, TableStateV8, useTableStoreV8,
} from '../../common/table/TableStoreV8';
import VulnerabilityStatusBadge from './VulnerabilityStatusBadge';
import {
  useGetAssetNameAsText, useGetSeverityAsText, useGetSignificanceAsText,
  useVulnerabilityStatusAsText,
} from '../../utils/TranslationUtils';
import { IUser, Module } from '../../types/AccessTypes';
import { severityAsCssClassName } from '../vulnerabilities/Utils';
import { IJob, IPage, IVulnerabilityListOptions } from '../../types/Types';
import { startAutomationJobAndAwaitResult } from '../../utils/AutomationJobRunner';
import { useAccount } from '../../providers/AccountProvider';
import ROUTES from '../../routing/Routes';
import { useModules } from '../../providers/ModuleProvider';
import { TableFromPageable } from '../../common/table/TableFromPageable';
import { PagedResult } from '../../types/PagedResult';
import { PageableColumnDefV8 } from '../../common/table';
import {
  createPageableColumnHelper, IPagedTableFilter, ISorting, useAllModuleOptions,
} from '../../common/table/PagedResultFilter';
import { TableFromArray } from '../../common/table/TableFromArray';

interface IAction {
  name: ControlAction,
  action: () => void,
}

interface IActionContext {
  vulnerability: IVulnerability,
  timestamp?: Date,
  name: ControlAction
}

const startAnalyzis = async (vuln:IVulnerability, controlDetails:IControlDetails) => (await axios.post<IJob>(
  `/api/v1/module/vulnerabilityJobs/controlStart/${vuln.sourceModuleId}/execute-control-action/${vuln.asset.friendlyId}/${controlDetails?.frameworkFriendlyId}/${controlDetails?.friendlyId}/${vuln.uniqueId ? `${vuln.uniqueId}/` : '' }analyze`,
)).data;

const VulnerabilityActionDropdown = (params:{vuln:IVulnerability, actions:IAction[], active:string|undefined}) => {
  const { actions, vuln, active } = params;
  const i18n = useTranslation();

  return !actions.length ? null : (
    <DropdownButton
      id={`actions-${vuln.id}`}
      drop="down-centered"
      align="end"
      title="Actions"
      onClick={(ev) => {
        ev.stopPropagation();
      }}
    >
      { actions.map((a) => (
        <Dropdown.Item
          key={a.name}
          disabled={a.name === active}
          onClick={(ev) => {
            ev.stopPropagation();
            a.action();
          }}
        >
          {i18n.getString(`vulnerability.action.${a.name}`)}
          { a.name === active
            ? <Spinner animation="grow" size="sm" className="ms-2" />
            : null }
        </Dropdown.Item>
      )) }
    </DropdownButton>
  );
};

const getVulnerabilityActions = (
  actionTargets:IActionTarget[],
  vuln:IVulnerability,
  control:IControlDetails,
  setActiveContext: (context:IActionContext|undefined) => void,
  invalidateQueries: () => void,
  signal:AbortSignal|undefined,
) => {
  const actions = [] as IAction[];

  // TODO: limited to m365 for now, open after we've tested with another module
  if (vuln.sourceModuleId !== Module.m365) {
    return actions;
  }

  const supportAction = (action:ControlAction) => (
    !!actionTargets && !!actionTargets.find((a) => a.action === action && a.uniqueId === vuln.uniqueId)
  );

  if (supportAction('impact') && supportAction('configure') && vuln.status === VulnerabilityStatus.Open) {
    actions.push({
      name: 'configure',
      action: () => {
        setActiveContext({ name: 'configure', vulnerability: vuln });
      },
    });
  }

  if (supportAction('revert')) {
    actions.push({
      name: 'revert',
      action: async () => {
        const { data: timestamp } = await axios.get<Date>(
          `/api/v1/module/vulnerabilityJobs/revertTimestamp/${vuln.asset.friendlyId}/${control.frameworkFriendlyId}/${control.friendlyId}${vuln.uniqueId ? `/${vuln.uniqueId}` : '' }`,
        );

        if (!timestamp) {
          toast.warn('Control has no undo configuration and changes cannot be reverted');
          return;
        }

        setActiveContext({ name: 'revert', vulnerability: vuln, timestamp });
      },
    });
  }

  if (supportAction('analyze')) {
    actions.push({
      name: 'analyze',
      action: async () => {
        setActiveContext({ name: 'analyze', vulnerability: vuln });
        try {
          toast.info('Started update', {
            toastId: 'analysis-status',
          });
          try {
            await startAutomationJobAndAwaitResult(() => startAnalyzis(vuln, control), signal);
            toast.success('Vulnerability analysis has been updated', {
              toastId: 'analysis-status',
              updateId: 'analysis-status',
            });
            invalidateQueries();
          } finally {
            setActiveContext(undefined);
          }
        } catch (err) {
          // Ignore
        }
      },
    });
  }

  return actions as IAction[];
};

const getRiskUri = (risk:IRisk) => `/risk/${encodeURIComponent(risk.riskId)}`;
const getControlUri = (control:IControl, selectedSnapshotId:number|undefined) => (
  `/control/${encodeURIComponent(control.id)}${(selectedSnapshotId ? `?snapshot_id=${ selectedSnapshotId}` : '')}`
);

export interface IVulnerabilityColumns {
    id?:string,
    status?: boolean,
    created?: boolean,
    updated?: boolean,
    asset?: boolean,
    details?: boolean,
    summary?: boolean,
    risk?: boolean,
    control?: boolean,
    actions?: boolean,
    uniqueId?: boolean,
    severity?: boolean,
    probability?: boolean,
    impact?: boolean,
    moduleId?: boolean,
    assignedTo?: boolean
}

const allSignificances = [
  Significance.VeryLow,
  Significance.Low,
  Significance.Medium,
  Significance.High,
  Significance.VeryHigh,
];

const allSeverities = [
  Severity.Low,
  Severity.Medium,
  Severity.High,
];

const allStatuses = [
  VulnerabilityStatus.Unknown,
  VulnerabilityStatus.Mitigated,
  VulnerabilityStatus.Open,
];

export const vulnerabilityTableStoreId = 'vulnerabilities-table';

/**
 * A generic component to display a list of vulnerabilities with an optional vulnerability action menu.
 * @param params:IProps
 * @returns The vulnerability list component
 */
export const VulnerabilitiesTable = ({
  id,
  pagedResult,
  pagedTableFilter,
  queryOptions,
  RowSubMenu,
  hide,
  isPaged,
  emptyText,
  disableFilters,
  disableColumnSelect,
  selectedSnapshotId,
  disablePagination,
  isLoading,
}:{
  id: string,
  pagedResult: PagedResult<IVulnerability>,
  setPage?: (page:IPage) => void,
  setFilters?: (filters:IVulnerabilityListOptions) => void,
  resetFilters?: () => void,
  isFiltered?: boolean,
  isPaged: boolean,
  queryOptions?: IVulnerabilityListOptions,
  RowSubMenu?:React.FunctionComponent<{vuln:IVulnerability}>,
  hide?:IVulnerabilityColumns,
  emptyText?:string|ReactElement,
  disableFilters?:boolean,
  disableColumnSelect?:boolean,
  sorting?: ISorting[],
  setSorting?: (sorting:ISorting[]) => void,
  selectedSnapshotId?:number,
  onInitialized?:(state:TableStateV8) => void,
  skipFilterFromSearchParamInitialization?:boolean,
  disablePagination?: boolean,
  pagedTableFilter?: IPagedTableFilter<IVulnerabilityListOptions>
  isLoading?: boolean,
}) => {
  const { hasModuleRole } = useAccount();
  const navigate = useNavigate();

  const {
    pageableQuery,
    setPage: pagedSetPage,
    reset: pagedReset,
    setSorting: pagedSetSorting,
    sorting: pagedSorting,
    appendQuery: pagedAppendQuery,
    isFiltered: pagedIsFiltered,
  } = pagedTableFilter ?? {};

  const isEnabled = useCallback(
    (
      columnName:'id'|'status'|'created'|'updated'|'uniqueId'|'asset'|'details'|'summary'|'risk'|'actions'|'control'|'severity'|'impact'|'probability'|'moduleId'|'assignedTo',
    ) => typeof hide === 'undefined' || !hide[columnName],
    [hide],
  );

  const columnHelper = createPageableColumnHelper<IVulnerability>();

  const vulnerabilityStatusAsText = useVulnerabilityStatusAsText();
  const severityAsText = useGetSeverityAsText();
  const significanceAsText = useGetSignificanceAsText();
  const getAssetName = useGetAssetNameAsText();
  const { getModuleNameOrDefault } = useModules();
  const allModuleOptions = useAllModuleOptions();

  const tableColumns = useMemo(() => {
    const c = [];

    if (isEnabled('status')) {
      c.push(
        columnHelper.accessor(
          'status',
          {
            header: 'Status',
            cell: ({ row }) => (
              <VulnerabilityStatusBadge vulnerability={row.original} />
            ),
            formatter: (value: VulnerabilityStatus) => vulnerabilityStatusAsText(value),
            meta: {
              className: 'vuln-status-col',
            },
          },
          {
            filterFn: (values: VulnerabilityStatus[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  status: values,
                });
              }
            },
            filterPropertyName: 'status',
            supportMultiSelect: true,
            selectOptions: allStatuses,
          },
        ),
      );
    }

    if (isEnabled('severity')) {
      c.push(
        columnHelper.accessor(
          'severity',
          {
            header: 'Severity',
            cell: ({ getValue }) => {
              const value = getValue();
              return value && value !== Severity.None ? (
                <Badge bg="none" className={severityAsCssClassName(value)}>
                  {severityAsText(value)}
                </Badge>
              ) : '';
            },
            formatter: (value: Severity) => (value ? severityAsText(value) : 'None'),
          },
          {
            filterPropertyName: 'severity',
            filterFn: (values: Severity[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  severity: values.length ? values[0] : undefined,
                });
              }
            },
            sortPropertyName: 'severity',
            supportMultiSelect: false,
            selectOptions: allSeverities,
          },
        ),
      );
    }

    if (isEnabled('impact')) {
      c.push(
        columnHelper.accessor(
          'impact',
          {
            header: 'Impact',
            cell: ({ row, getValue }) => (row.original.status === 'open'
              ? significanceAsText(getValue())
              : ''),
            defaultHidden: true,
            formatter: significanceAsText,
          },
          {
            filterPropertyName: 'impact',
            filterFn: (values: Significance[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  impact: values,
                });
              }
            },
            supportMultiSelect: true,
            selectOptions: allSignificances,
          },
        ),
      );
    }
    if (isEnabled('probability')) {
      c.push(
        columnHelper.accessor(
          'probability',
          {
            header: 'Probability',
            cell: ({ row, getValue }) => (row.original.status === 'open'
              ? significanceAsText(getValue())
              : ''),
            defaultHidden: true,
            formatter: significanceAsText,
          },
          {
            filterPropertyName: 'probability',
            filterFn: (values: Significance[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  probability: values,
                });
              }
            },
            supportMultiSelect: true,
            selectOptions: allSignificances,
          },
        ),
      );
    }

    if (isEnabled('asset')) {
      c.push(
        columnHelper.accessor(
          'asset',
          {
            header: 'Asset',
            cell: ({ getValue }) => getAssetName(getValue()),
          },
          {
            filterPropertyName: 'asset',
            sortPropertyName: 'asset.name',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  asset: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('id')) {
      c.push(
        columnHelper.accessor(
          'id',
          {
            header: 'Id',
            defaultHidden: true,
          },
          {
            filterPropertyName: 'id',
            filterFn: (values: number[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  id: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('uniqueId')) {
      c.push(
        columnHelper.accessor(
          'uniqueId',
          {
            header: 'Unique ID',
            defaultHidden: true,
          },
          {
            filterPropertyName: 'uniqueId',
            sortPropertyName: 'uniqueId',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  uniqueId: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('summary')) {
      c.push(
        columnHelper.accessor(
          'summary',
          {
            header: 'Summary',
            cell: ({ getValue }) => <RenderHtml>{getValue()}</RenderHtml>,
          },
          {
            filterPropertyName: 'summary',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  summary: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('risk')) {
      c.push(
        columnHelper.accessor(
          'risk',
          {
            header: 'Risk ID',
            cell: ({ getValue }) => {
              const risk = getValue();
              return risk && hasModuleRole(Module.risk, 'read') ? (
                <OverlayTrigger overlay={<Tooltip>Go to risk</Tooltip>}>
                  <Link
                    to={getRiskUri(risk)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {risk?.riskId}
                  </Link>
                </OverlayTrigger>
              ) : (
                risk?.riskId
              );
            },
            defaultHidden: true,
          },
          {
            filterPropertyName: 'risk',
            sortPropertyName: 'risk.riskId',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  risk: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('control')) {
      c.push(
        columnHelper.accessor(
          'control',
          {
            header: 'Control ID',
            cell: ({ row }) => {
              const vuln = row.original;
              return vuln.control
                && hasModuleRole(Module.vulnerability, 'read') ? (
                  <OverlayTrigger overlay={<Tooltip>Go to control</Tooltip>}>
                    <Link
                      to={getControlUri(vuln.control, selectedSnapshotId)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {vuln.control?.friendlyId}
                    </Link>
                  </OverlayTrigger>
                ) : (
                  vuln.control?.friendlyId
                );
            },
            defaultHidden: true,
          },
          {
            filterPropertyName: 'control',
            sortPropertyName: 'control.friendlyId',
            filterFn: (values: string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  control: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (isEnabled('created')) {
      c.push(
        columnHelper.accessor('created', {
          header: 'Created',
          cell: ({ row }) => {
            const vuln = row.original;
            return getStringDate(vuln.created);
          },
          defaultHidden: true,
          enableColumnFilter: false,
          enableSorting: false,
        }),
      );
    }

    if (isEnabled('updated')) {
      c.push(
        columnHelper.accessor('updated', {
          header: 'Updated',
          cell: ({ row }) => {
            const vuln = row.original;
            return getStringDate(vuln.updated ?? vuln.created);
          },
          defaultHidden: true,
          enableColumnFilter: false,
          enableSorting: false,
        }),
      );
    }

    if (isEnabled('moduleId')) {
      c.push(
        columnHelper.accessor(
          'sourceModuleId',
          {
            header: 'Module',
            cell: ({ getValue }) => getModuleNameOrDefault(getValue()),
            formatter: getModuleNameOrDefault,
            defaultHidden: true,
          },
          {
            filterPropertyName: 'sourceModuleIds',
            filterFn: (values: number[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  sourceModuleIds: values,
                });
              }
            },
            selectOptions: allModuleOptions,
            supportMultiSelect: true,
          },
        ),
      );
    }

    if (isEnabled('assignedTo')) {
      c.push(
        columnHelper.accessor(
          'assignedTo',
          {
            header: 'Assignee',
            cell: ({ getValue }) => {
              const value = getValue();
              return value ? asNameUpn(value) : '';
            },
            formatter: (user: IUser) => asNameUpn(user),
            defaultHidden: true,
          },
          {
            filterPropertyName: 'assignedTo',
            filterFn: (values:string[]) => {
              if (pagedAppendQuery) {
                pagedAppendQuery({
                  assignedTo: values.length ? values[0] : undefined,
                });
              }
            },
          },
        ),
      );
    }

    if (RowSubMenu) {
      c.push(
        columnHelper.display({
          id: 'submenu',
          header: 'Actions',
          cell: ({ row }) => <RowSubMenu vuln={row.original} />,
          enableColumnFilter: false,
        }),
      );
    }

    return c;
  }, [
    isEnabled,
    RowSubMenu,
    columnHelper,
    vulnerabilityStatusAsText,
    pagedAppendQuery,
    severityAsText,
    significanceAsText,
    getAssetName,
    hasModuleRole,
    selectedSnapshotId,
    getModuleNameOrDefault,
    allModuleOptions,
  ]);

  const { store: tableStore } = useTableStoreV8(
    vulnerabilityTableStoreId,
    {
      visibilityState: columnsToVisibilityState(tableColumns),
    },
  );

  const tableState = useStoreWithEqualityFn(tableStore);

  if (pagedResult.items.length === 0 && emptyText) {
    return emptyText;
  }

  return isPaged
    ? (
      <TableFromPageable
        id={id}
        pagedResult={pagedResult}
        setPage={(page) => {
          if (pagedSetPage != null) pagedSetPage(page);
        }}
        pageSize={pagedTableFilter?.pageableQuery.pageSize ?? -1}
        resetFilters={() => { if (pagedReset) pagedReset(); }}
        filterValues={pageableQuery ?? queryOptions ?? {}}
        isFiltered={pagedIsFiltered ?? false}
        className="vulnerabilities-table"
        state={tableState}
        isLoading={isLoading}
        columnDefs={tableColumns as PageableColumnDefV8<IVulnerability, unknown>[]}
        disablePagination={disablePagination}
        hover={false}
        disableFilters={disableFilters}
        disableColumnSelect={disableColumnSelect}
        sorting={pagedSorting}
        setSorting={(newSorting) => { if (pagedSetSorting) pagedSetSorting(newSorting); }}
        /* defaultSorting={sort ?? defaultSorting} */
        alternatingRowColors
        onRowClick={(_, vuln) => {
          navigate(`${ROUTES.vulnerability.uri}/${vuln.id}`);
        }}
      />
    )
    : (
      <TableFromArray
        data={pagedResult.items}
        className="vulnerabilities-table"
        state={tableState}
        columnDefs={tableColumns as PageableColumnDefV8<IVulnerability, unknown>[]}
        disablePagination={disablePagination}
        hover={false}
        disableFilters={disableFilters}
        disableColumnSelect={disableColumnSelect}
        /* defaultSorting={sort ?? defaultSorting} */
        alternatingRowColors
        onRowClick={(_, vuln) => {
          navigate(`${ROUTES.vulnerability.uri}/${vuln.id}`);
        }}
      />
    );
};

interface IControlVulnerabilitiesTableProps {
  control:IControlDetails,
  pagedVulnerabilities:PagedResult<IVulnerability>
}

/**
 * A vulnerability tailored for use to display vulnerabilities associated with a control.
 * @param props:IControlDetails
 * @returns The control vulnerability table
 */
export const ControlVulnerabilitiesTable = (props:IControlVulnerabilitiesTableProps) => {
  const { control, pagedVulnerabilities } = props;
  const { data: allModulesControlActions } = useApi<Record<number, IActionTarget[]>>(
    control
    && `module/vulnerabilityJobs/controlActions/${encodeURIComponent(control.friendlyId)}`,
  );

  // TODO: Support control actions from any module
  // TODO: How do we link asset to module to determine what actions to support?
  const controlActions:IActionTarget[] = allModulesControlActions && allModulesControlActions[Module.m365]
    ? allModulesControlActions[Module.m365]
    : [];

  const abortSignalRef = useRef<AbortController>();

  useEffect(() => {
    abortSignalRef.current = new AbortController();
    return () => {
      abortSignalRef.current?.abort();
    };
  }, []);

  const [activeActionContext, setActiveActionContext] = useState<IActionContext>();

  const invalidateVulnerabilities = useInvalidateQueries('vulnerabilities');

  const invalidateQueries = () => {
    invalidateVulnerabilities();
  };

  const closeModal = () => {
    setActiveActionContext(undefined);
    invalidateQueries();
  };

  return !pagedVulnerabilities
    ? <Spinner animation="border" />
    : (
      <VulnerabilitiesTable
        id="control-vulnerabilities"
        pagedResult={pagedVulnerabilities}
        isPaged={false}
        /* Noop setPage as this component only supports client side paging */
        setPage={() => {}}
        emptyText="Control has no associated vulnerabilities."
        RowSubMenu={({ vuln }) => (
          <>
            <VulnerabilityActionDropdown
              vuln={vuln}
              actions={
                getVulnerabilityActions(
                  controlActions,
                  vuln,
                  control,
                  setActiveActionContext,
                  invalidateQueries,
                  abortSignalRef.current?.signal,
                )
              }
              active={activeActionContext?.name}
            />
            <ControlRemediateModal
              control={control}
              vulerability={vuln}
              handleClose={closeModal}
              start
              show={activeActionContext?.name === 'configure' && activeActionContext?.vulnerability.id === vuln.id}
            />
            <ControlUndoModal
              control={control}
              vulerability={vuln}
              handleClose={closeModal}
              show={activeActionContext?.name === 'revert' && activeActionContext?.vulnerability.id === vuln.id}
              undoTimestamp={activeActionContext?.timestamp}
            />
          </>
        )}
        hide={{ control: true }}
      />
    );
};
