/* eslint-disable no-await-in-loop */
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  Alert, Spinner,
} from 'react-bootstrap';
import {
  IVulnerability, IControlDetails, IControlConfigureResult, ExecutionStatus,
} from './Types';
import { IJob } from '../../types/Types';
import { startAutomationJobAndAwaitResult } from '../../utils/AutomationJobRunner';
import { getStringDate } from '../../utils/StringUtils';
import WizardModal, { IStep, ModalProps } from '../../components/WizardModal';
import RenderHtml from '../../components/RenderHtml';

interface UndoModalProps extends ModalProps {
  vulerability: IVulnerability,
  control: IControlDetails,
  undoTimestamp?: Date,
}

const startUndo = async (vuln:IVulnerability, controlDetails:IControlDetails) => (await axios.post<IJob>(
  `/api/v1/module/vulnerabilityJobs/controlStart/${vuln.sourceModuleId}/execute-control-action/${vuln.asset.friendlyId}/${controlDetails?.frameworkFriendlyId}/${controlDetails?.friendlyId}/revert`,
)).data;

const ControlUndoModal = (props: UndoModalProps) => {
  const {
    show, undoTimestamp, handleClose, vulerability: vuln, control: controlDetails,
  } = props;

  const [configurationResult, setConfigurationResult] = useState<IControlConfigureResult|undefined>();

  const abortControllerRef = useRef<AbortController>();

  useEffect(() => {
    abortControllerRef.current = new AbortController();
    return () => {
      abortControllerRef.current?.abort();
    };
  }, []);

  const close = () => {
    setConfigurationResult(undefined);
    handleClose();
  };

  const steps:IStep[] = [
    {
      title: 'Preparing to undo',
      body: (
        <div>
          { undoTimestamp
            ? (
              <>
                <p>
                  To undo we&apos;ll revert to the previous control configuration
                  for
                  {' '}
                  <em>{vuln.asset.name}</em>
                  .
                </p>
                <div>
                  <Alert variant="warning" className="p-2">
                    Previous configuration was last active
                    {' '}
                    {getStringDate(undoTimestamp)}
                    .
                  </Alert>
                </div>
              </>
            )
            : <p>This will revert to the previous active configuration.</p>}
        </div>
      ),
      buttonText: 'Revert to previous configuration',
      activeBody: (
        <p>
          Reverting, please wait...
          {' '}
          <Spinner animation="grow" size="sm" as="span" />
        </p>
      ),
      activeTitle: 'Reverting, please wait...',
      fn: async () => {
        const result = await startAutomationJobAndAwaitResult<IControlConfigureResult>(
          () => startUndo(vuln, controlDetails),
          abortControllerRef.current?.signal,
        );
        setConfigurationResult(result);
      },
    },
    {
      title: 'Configuration reverted',
      body: (
        <div>
          { configurationResult?.status === ExecutionStatus.Ok
            ? (
              <>
                <p>
                  The previous configuration has been applied.
                </p>
                { configurationResult && configurationResult.details ? (
                  <Alert variant="info" className="p-2">
                    <RenderHtml>
                      { configurationResult.details }
                    </RenderHtml>
                  </Alert>
                ) : null }
              </>
            )
            : (
              <Alert variant="warning">
                { configurationResult?.details ?? 'An error occured while configuring, and the module provided no additional information.' }
              </Alert>
            )}
        </div>
      ),
      buttonText: 'Close',
      fn: () => close(),
    },
  ];

  return (
    <WizardModal steps={steps} handleClose={handleClose} show={show} />
  );
};

export default ControlUndoModal;
