import React, { ReactElement, useEffect } from 'react';
import {
  Button, Card, Stack,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { NoSidebarTemplate } from '../AuthenticatedAppTemplate';
import { useConfigContext } from '../contexts/ConfigContext';

export interface IErrorTemplateProps {
  status:number,
  title?:string|undefined,
  details?:string|ReactElement|undefined
}

export const ErrorTemplate = ({ status, title, details }:IErrorTemplateProps) => {
  const config = useConfigContext();

  useEffect(() => {
    if (status === 504) {
      const interval = setInterval(() => {
        window.location.reload();
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
    return undefined;
  }, [status]);

  const errorDetails = details && status < 500
    ? <p>{details}</p>
    : (
      <>
        <p>
          The API is unavailable
          { status > 0
            ? (
              <>
                {' '}
                <span>
                  (status:
                  {' '}
                  {status}
                  )
                </span>
              </>
            )
            : null }
        </p>
        <p>
          Please wait a few minutes and try again. If the problem persists,
          please contact
          {' '}
          <a href="mailto:support@ivolv.no">support@ivolv.no</a>
          {' '}
          for assistance.
        </p>
        <p>
          Sorry for the inconvenience.
        </p>
      </>
    );

  return (
    <NoSidebarTemplate>
      <Card className="card-sm">
        <Card.Header>
          <Icon name="alert-triangle" className="me-2" />
          {title ?? 'API load error'}
        </Card.Header>
        <Card.Body>
          { errorDetails }
          <Stack direction="horizontal" gap={2}>
            { status !== 504
              ? (
                <Button
                  variant="primary"
                  onClick={async () => window.history.back()}
                >
                  Back
                </Button>
              )
              : null }
            <Button
              variant="secondary"
              onClick={async () => window.location.reload()}
            >
              Retry
            </Button>
            { window.location.href !== config.AZURE_REDIRECT_URI && status !== 504
              ? (
                <Button
                  variant="secondary"
                  onClick={async () => { window.location.href = config.AZURE_REDIRECT_URI; }}
                >
                  Go to start page
                </Button>
              )
              : null }
          </Stack>
        </Card.Body>
      </Card>
    </NoSidebarTemplate>
  );
};
