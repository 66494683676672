import React from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useGetSeverityAsText, useVulnerabilityStatusAsText } from '../../utils/TranslationUtils';
import {
  getImplementationPercentText, vulnerabilityAsColorClassNames,
} from './Utils';
import { IStatusableVulnerability, VulnerabilityStatus } from './Types';
import { severityAsCssClassName, asSeverity } from '../vulnerabilities/Utils';

const VulnerabilityStatusBadge = ({
  vulnerability,
  className,
  style,
}:{
  vulnerability:IStatusableVulnerability,
  className?:string|undefined,
  style?:React.CSSProperties|undefined
}) => {
  const vulnerabilityStatusAsText = useVulnerabilityStatusAsText();
  const severityAsText = useGetSeverityAsText();

  const badgeClassNames = `${vulnerabilityAsColorClassNames(vulnerability)}${className ? ` ${className}` : ''}`;
  const severity = asSeverity(vulnerability);

  return vulnerability.status === VulnerabilityStatus.Mitigated
    ? (
      <Badge
        bg="none"
        className={badgeClassNames}
      >
        { vulnerabilityStatusAsText(vulnerability.status) }
        { getImplementationPercentText(vulnerability) }
      </Badge>
    )
    : (
      <OverlayTrigger
        overlay={(
          <Tooltip>
            Severity:
            {' '}
            <Badge
              style={{ fontSize: '0.7rem' }}
              bg="none"
              pill
              className={`text-shadow ${severityAsCssClassName(severity)}`}
            >
              {severityAsText(severity)}
            </Badge>
          </Tooltip>
        )}
      >
        <Badge bg="none" className={badgeClassNames} style={style}>
          { vulnerabilityStatusAsText(vulnerability.status) }
          { getImplementationPercentText(vulnerability) }
        </Badge>
      </OverlayTrigger>
    );
};

export default VulnerabilityStatusBadge;
