/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Alert, Col, Row, Spinner,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { Link } from 'react-router-dom';
import { useApi } from '../../query/GenericQuery';
import { useGetSeverityAsText } from '../../utils/TranslationUtils';
import ROUTES, { Routes } from '../../routing/Routes';
import { Severity } from '../vulnerabilities/Types';

interface IVulnerabilitySummary {
  severityCount:Record<Severity, number>,
  score?:Severity
}

interface ISeverityDetails {
  alertVariant:'danger'|'warning'|'success'|'light',
  iconName: 'alert-circle'|'check-circle',
  text: string|React.ReactElement
}

const getSeverityDetails = (severity:Severity|undefined):ISeverityDetails => {
  if (severity === Severity.High) {
    return {
      alertVariant: 'danger',
      iconName: 'alert-circle',
      text: (
        <>
          Your vulnerability level is rated as high.
          <br />
          <br />
          Your security is at immediate risk due to certain vulnerabilities.
          Please address and mitigate the most critical issues promptly.
        </>
      ),
    };
  }
  if (severity === Severity.Medium) {
    return {
      alertVariant: 'warning',
      iconName: 'alert-circle',
      text: (
        <>
          Your vulnerability level is currently assessed as medium.
          <br />
          <br />
          Please review your vulnerabilities and prioritize mitigating the most critical ones.
        </>
      ),
    };
  }
  if (severity === Severity.Low) {
    return {
      alertVariant: 'success',
      iconName: 'check-circle',
      text: (
        <>
          Your vulnerability level is considered low, indicating a generally strong security posture.
          However, there are a few vulnerabilities of low severity that still require attention.
        </>
      ),
    };
  }
  return {
    alertVariant: 'success',
    iconName: 'check-circle',
    text: (
      <>
        Good job!
        <br />
        You have no vulnerabilities to address at the moment.
      </>
    ),
  };
};

export const VulnerabilityScoreTile = () => {
  const { data: summary } = useApi<IVulnerabilitySummary>('vulnerabilities/summary');

  const severityAsText = useGetSeverityAsText();

  const { alertVariant, iconName, text } = getSeverityDetails(summary?.score);

  const searchParams = new URLSearchParams();
  searchParams.set('status', 'open');

  return summary
    ? (
      <Alert
        variant={alertVariant}
        className="flex-fill mb-0 vulnerability-score-tile"
      >
        <div className="m-auto text-center overflow-hidden">
          <Row>
            <Col md={12}>
              <div className="p-0 px-4">
                <h5>Vulnerability level</h5>
                <h1>
                  <Icon name={iconName} size="36" className="me-2" />
                  { summary?.score ? severityAsText(summary?.score) : 'OK' }
                </h1>
                <div>
                  { text }
                </div>
                { summary.score
                  ? (
                    <Link
                      className="btn btn-primary mt-3"
                      to={
                        Routes.getRouteRelativeUri(
                          ROUTES.vulnerabilities,
                          Object.fromEntries(searchParams),
                          true,
                        ).toString()
                      }
                    >
                      Look into it
                    </Link>
                  )
                  : null }
              </div>
            </Col>
          </Row>
        </div>
      </Alert>
    )
    : (
      <div className="flex-fill mb-0 d-flex">
        <div className="m-auto text-center">
          <Spinner animation="border" />
        </div>
      </div>
    );
};
