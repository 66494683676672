import { Instance, createPopper } from '@popperjs/core';
import React, {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { CheckDropdownItem, CheckDropdownToggle } from './table/filters/FilterHelpersV8';

/**
 * React bootstrap Dropdown replacement tailored for our table filter usage.
 * Uses a custom popper instance that renders the dropdown in body to prevent clipping
 * to table container.
 */
export const NoClipDropdown = <T, >({
  className,
  filterLabel,
  values,
  checked,
  onCheck,
  size,
  itemId,
  itemLabel,
  disabled,
  filterId,
  multiselect,
}:{
  className:string|undefined
  filterLabel:ReactNode|string|undefined,
  itemLabel:(item:T) => ReactNode,
  itemId:(item:T) => string,
  filterId:string,
  values:T[],
  size:'sm'|'lg'|undefined,
  checked:(value:unknown) => boolean,
  onCheck:(checked:boolean, value:T) => void,
  disabled?: boolean,
  multiselect?: boolean,
}) => {
  const toggleRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const [popper, setPopper] = useState<Instance>();

  useEffect(() => {
    if (toggleRef.current && popperRef.current) {
      const popperInstance = createPopper(toggleRef.current, popperRef.current, {
        placement: 'left-start',
        strategy: 'fixed',
      });

      setPopper(popperInstance);

      return () => {
        setPopper(undefined);
        popperInstance.destroy();
      };
    }

    return undefined;
  }, [popperRef]);

  useEffect(() => {
    if (popper) {
      popper.update();
    }
    // We need to update popper when values change, in case that affects dropdown size and thus positioning
  }, [popper, values]);

  return disabled
    ? <div className="form-control form-control-sm readonly rbt-input">All</div>
    : (
      <Dropdown className={className}>
        <Dropdown.Toggle ref={toggleRef} as={CheckDropdownToggle} id="dropdown-basic">
          {filterLabel}
        </Dropdown.Toggle>
        <div ref={popperRef}>
          <Dropdown.Menu
            className="check-dropdown-menu"
            style={{ maxWidth: '200px' }}
          >
            {values.map((i) => (
              <Dropdown.Item
                key={`${filterId}_${itemId(i) ?? 'empty'}`}
                id={`${filterId}_${itemId(i) ?? 'empty'}`}
                as={CheckDropdownItem}
                value={i}
                label={itemLabel(i)}
                checked={checked(i)}
                onCheck={async (wasChecked:boolean, value:unknown) => onCheck(wasChecked, value as T)}
                size={size}
                type={multiselect ? 'checkbox' : 'radio'}
              />
            ))}
          </Dropdown.Menu>
        </div>
      </Dropdown>
    );
};
