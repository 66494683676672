import { QueryClient } from '@tanstack/react-query';
import { IVulnerabilityListOptions } from '../../types/Types';
import { ITopicAndFunctionDto, IVulnerability, IVulnerabilitySnapshot } from './Types';
import { PagedResult } from '../../types/PagedResult';
import { getOrFetchFromApi } from '../../query/GenericQuery';
import { IAccountDetails } from '../../providers/AccountProvider';
import { ValidPageSizes } from '../../common/table/PaginationV8';

export interface ILoaderData {
  snapshot: IVulnerabilitySnapshot|undefined,
  snapshots: IVulnerabilitySnapshot[],
  pagedVulnerabilities: PagedResult<IVulnerability>,
  options: IVulnerabilityListOptions|undefined,
  topics: Record<string, ITopicAndFunctionDto>
}

export interface ISnapshotListOptions {
  granularity: 'logarithmic'|'all',
  maxItems?: number,
}

export interface ISnapshotMetricsOptions extends ISnapshotListOptions {
  sourceModules?: number[],
}

export const vulnerabilitiesLoader = async (
  queryClient:QueryClient,
  account:IAccountDetails,
  pageSize:ValidPageSizes,
  options?:IVulnerabilityListOptions|undefined,
) : Promise<ILoaderData> => {
  const snapshots = await getOrFetchFromApi<IVulnerabilitySnapshot[], ISnapshotListOptions>(
    queryClient,
    'vulnerabilitySnapshots',
    {
      granularity: 'logarithmic',
      maxItems: 100,
    },
  );

  const optionsWithPageSize:IVulnerabilityListOptions = {
    ...(options ?? {}),
    pageSize,
  };
  const pagedVulnerabilities = await getOrFetchFromApi<PagedResult<IVulnerability>, IVulnerabilityListOptions>(
    queryClient,
    'vulnerabilities',
    optionsWithPageSize,
  );

  const topics = await getOrFetchFromApi<Record<string, ITopicAndFunctionDto>>(
    queryClient,
    'controls/ivolv/topics',
  );

  return {
    snapshot: snapshots.find((s) => s.id === optionsWithPageSize?.snapshotId),
    snapshots: snapshots ?? [],
    pagedVulnerabilities,
    topics,
    options: optionsWithPageSize,
  };
};
