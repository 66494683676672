import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';
import {
  Button, Form, Stack,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ActiveStatus, AdminCustomer } from '../../types/AdminTypes';
import { useGetAccountProviderAsText } from '../../utils/TranslationUtils';
import { AccountProvider } from '../../types/AccessTypes';

const getIdLabelFromProvider = (provider:AccountProvider) => {
  switch (provider) {
  case 'azureAppRegistration':
    return 'AppId';
  case 'azureEntraIdUser':
    return 'UPN';
  default:
    return 'ExternalId';
  }
};

interface AdminUserFormProps {
  customer: AdminCustomer,
  handleClose: (update?: boolean) => void,
}

interface ICreateAccount {
  name:string,
  externalId:string,
  provider:AccountProvider,
  customerId:string,
  status:ActiveStatus
}

const AdminUserAddForm = ({ customer, handleClose }: AdminUserFormProps) => {
  const [formState, setFormState] = useState<ICreateAccount>({
    name: '', externalId: '', provider: 'azureEntraIdUser', customerId: customer.id, status: ActiveStatus.active,
  });

  const getAccountProviderAsText = useGetAccountProviderAsText();

  const addAccountMutation = useMutation({
    mutationFn: async () => axios.post<ICreateAccount>(
      '/api/v1/module/admin/accounts',
      formState,
    ),
    onSuccess: () => {
      toast.success('Account was added');
      handleClose(true);
    },
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await addAccountMutation.mutateAsync();
  };

  const enabledProvides:AccountProvider[] = [
    'azureEntraIdUser',
  ];
  if (customer.canBeAdmin) {
    enabledProvides.push('azureAppRegistration');
  }

  return (
    <Form onSubmit={onSubmit}>
      <>
        <Form.Group className="mb-3">
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            value={formState.name}
            autoComplete="off"
            required
            onChange={(e) => setFormState((state) => ({ ...state, name: e.target.value }))}
          />
        </Form.Group>
        <Form.Label>Provider</Form.Label>
        <Form.Select
          value={formState.provider}
          required
          disabled={enabledProvides.length <= 1}
          onChange={(e) => setFormState((state) => ({ ...state, provider: e.target.value as AccountProvider }))}
          className="mb-3"
        >
          <option value="azureEntraIdUser">{getAccountProviderAsText('azureEntraIdUser')}</option>
          { customer.canBeAdmin
            ? <option value="azureAppRegistration">{getAccountProviderAsText('azureAppRegistration')}</option>
            : null }
        </Form.Select>
        <Form.Group className="mb-4">
          <Form.Label>
            { getIdLabelFromProvider(formState.provider) }
            :
          </Form.Label>
          <Form.Control
            type="externalId"
            value={formState.externalId}
            required
            onChange={(e) => setFormState((state) => ({ ...state, externalId: e.target.value }))}
          />
        </Form.Group>
      </>
      <Stack direction="horizontal" gap={2}>
        <Button type="submit">Add account</Button>
        <Button variant="secondary" type="button" onClick={() => handleClose()}>Cancel</Button>
      </Stack>
    </Form>
  );
};

export default AdminUserAddForm;
