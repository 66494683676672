import React from 'react';
import {
  OverlayTrigger, Stack, Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@ailibs/feather-react-ts';
import ROUTES from '../routing/Routes';
import { ReturnToPrimaryCustomerButton } from '../pages/MultiTenantSelectCustomerPage';
import { useAccount } from '../providers/AccountProvider';

export const MultiTenantBar = () => {
  const { customer, primaryCustomer } = useAccount();

  return (
    <div className="active-customer-bar">
      <Stack direction="horizontal" gap={3}>
        <span className="ms-auto" />
        <span className="customer-name">
          {customer?.name}
        </span>
        <Link
          type="button"
          className={`btn ${customer?.id === primaryCustomer?.id ? 'btn-warning' : 'btn-danger'}`}
          to={ROUTES.multiTenantCustomerSelect.uri}
        >
          <Icon name="users" size="16" />
          {' '}
          <span>Switch organization</span>
        </Link>
        { customer?.id !== primaryCustomer?.id
          ? (
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip>
                  Back home to
                  {' '}
                  {primaryCustomer?.name}
                </Tooltip>
              )}
            >
              <span>
                <ReturnToPrimaryCustomerButton variant="secondary">
                  <Icon name="home" size={18} />
                </ReturnToPrimaryCustomerButton>
              </span>
            </OverlayTrigger>
          )
          : null }
      </Stack>
    </div>
  );
};
