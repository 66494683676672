import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { createAlgizNoAccountRouter } from './Router';
import { LoadingTemplate } from './LoadingTemplate';

export const AuthenticatedNoAccountApp = () => {
  const router = createAlgizNoAccountRouter();

  return (
    <RouterProvider
      router={router}
      fallbackElement={(
        <LoadingTemplate>
          Fetching data
        </LoadingTemplate>
      )}
    />
  );
};
