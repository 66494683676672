import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useModalContext from '../../contexts/ModalContext';
import { AssessmentStateManager } from './AssessmentStateManager';

interface IProps {
  stateManager:AssessmentStateManager,
  disabled?: boolean,
  children?: React.ReactNode|string,
  onDiscarded?: () => void
}

const AssessmentDiscardButton = (props:IProps) => {
  const {
    stateManager,
    disabled,
    children,
    onDiscarded,
  } = props;
  const { openConfirmLegacy } = useModalContext();

  return (
    <Button
      variant="danger"
      disabled={disabled}
      onClick={() => openConfirmLegacy(
        (confirmed) => {
          if (confirmed) {
            stateManager.discard();
            toast.warning('Assessment has been discarded');
            if (onDiscarded) onDiscarded();
          }
        },
        'Discard assessment?',
        'Discarding the assessment will delete all answers and identified vulnerabilities.',
        'Discard assessment',
        'Keep assessment',
      )}
    >
      { children ?? 'Discard assessment' }
    </Button>
  );
};

export default AssessmentDiscardButton;
