import React from 'react';

/**
 * The webpage footer, is included in all main pages automatically
 */
export function Footer() {
  return (
    <div className="footer-container">
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-start text-muted">
              <p className="mb-0 text-nowrap">
                <span className="d-none d-sm-inline">
                  Ivolv Cybersecurity Platform
                  {' '}
                </span>
                &copy; 2022 -
                {' '}
                {new Date().getFullYear()}
                {' '}
                <a href="https://ivolv.no/" className="text-muted" target="_blank" rel="noopener noreferrer">
                  Ivolv Cybersecurity
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
