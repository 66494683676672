import axios from 'axios';
import React, {
  FormEvent, createRef, useMemo, useState,
} from 'react';
import {
  Alert,
  Button, Col, Form, Modal, Row,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useInvalidateQueries } from '../../query/GenericQuery';
import { IErrorDetails } from '../../types/Types';

interface IProps {
  show:boolean,
  close:() => void
}

interface IFormValues {
  name: string
  file: File|null
}

const formRef = createRef<HTMLFormElement>();

const AssessmentUploadModal = (props:IProps) => {
  const { show, close } = props;
  const [formValues, setFormValues] = useState<IFormValues>({
    name: '',
    file: null,
  } as IFormValues);

  const [error, setError] = useState<string>();

  const invalidateFiles = useInvalidateQueries('files');
  const canFormSubmit = useMemo(() => formValues.file && formValues.name, [formValues]);

  const handleSubmit = async (event:FormEvent) => {
    event.preventDefault();

    if (!formValues.file) { return; }

    const formData = new FormData();
    formData.append('file', formValues.file);
    formData.append('fileName', formValues.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    try {
      setError(undefined);
      await axios.post('/api/v1/module/admin/assessments', formData, config);
      toast.success('Assessment was successfully uploaded');
      invalidateFiles();
      close();
    } catch (err) {
      if (!err) {
        // File has probably changed, reset form and ask user to retry
        toast.error('File has probably changed after it was added to the file selector. Please try again.');
        formRef.current?.reset();
        return;
      }
      const typedValidationError = err as {response:{data:{errorDetails?:{errorMessage?:string}}}};

      if (typedValidationError.response?.data.errorDetails?.errorMessage) {
        setError(typedValidationError.response.data.errorDetails?.errorMessage);
        return;
      }

      const typedSystemError = err as {response:{data:IErrorDetails}};

      if (typedSystemError.response.data.detail) {
        setError(typedSystemError.response.data.detail);
        return;
      }

      setError(`An unexepected and unknown error occured.\n\n${JSON.stringify(err, null, 2)}`);
    }
  };

  const closeModal = () => {
    setFormValues({} as IFormValues);
    setError(undefined);
    close();
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="lg"
    >
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Upload assessment manuscript</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                required
                name="name"
                autoComplete="none"
                defaultValue={formValues.name}
                onChange={(e) => setFormValues({
                  ...formValues,
                  name: e.target.value,
                })}
              />
            </Col>
            <Col md={12} className="mb-3">
              <Form.Label>File</Form.Label>
              <div>
                <input
                  type="file"
                  required
                  aria-label="Upload assessment file"
                  onChange={(e) => setFormValues({
                    ...formValues,
                    file: e.target.files ? e.target.files[0] : null,
                  })}
                />
              </div>
            </Col>
            { error
              ? (
                <Col md={12}>
                  <Form.Label>Validation error</Form.Label>
                  <Alert variant="warning" style={{ maxHeight: '300px', overflow: 'auto' }} className="p-3">
                    <pre className="p-0 m-0">{error}</pre>
                  </Alert>
                </Col>
              )
              : null }
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={!canFormSubmit}
            type="submit"
          >
            Upload
          </Button>
          <Button
            variant="secondary"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AssessmentUploadModal;
