import React from 'react';
import { Badge } from 'react-bootstrap';
import { useRiskStatusAsText } from '../../utils/TranslationUtils';
import { RiskStatus } from '../../types/RiskTypes';

const RiskStatusBadge = (props : { status: RiskStatus }) => {
  const { status } = props;
  const riskStatusAsText = useRiskStatusAsText();

  const className = status === RiskStatus.Open
    ? 'risk-open-full'
    : 'risk-closed';

  return <Badge bg="none" className={className}>{riskStatusAsText(status)}</Badge>;
};

export default RiskStatusBadge;
