import { ITaggedEntity } from '../types/EntityTypes';

export const getEntityUri = (entity:ITaggedEntity) => {
  switch (entity.entityType) {
  case 'vulnerability':
    return `/vulnerabilities/${entity.entityId}`;
  case 'risk_register':
    return `/risk/${entity.entityId}`;
  case 'asset_register':
    return `/asset/${entity.entityId}`;
  default:
    throw new Error(`Unable to get URI for entity ${entity.entityType}`);
  }
};
