import React from 'react';
import { Modal } from 'react-bootstrap';

interface FormModalProps {
  show: boolean,
  handleClose: () => void,
  isAdd: boolean,
  Form: JSX.Element,
  itemType: string,
}

/**
 * Custom modal intended for form usage (large size, static backdrop)
 *
 * Used by AssetForm and RiskForm
 */
export const FormModal = (props: FormModalProps) => {
  const {
    show, handleClose, isAdd, Form, itemType,
  } = props;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isAdd ? (
            <strong>Add </strong>
          ) : (
            <strong>Edit </strong>
          )}
          {itemType}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Form}
      </Modal.Body>
    </Modal>
  );
};

export default FormModal;
