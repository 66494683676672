import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export const isIsoDateString = (input:string) => (
  !!input.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(\.\d*)?(Z|[-+]\d{2}:\d{2})$/)
);

export const stringIsDate = (date: string | Date | null | undefined) => {
  if (!date) return undefined;
  if (typeof date === 'string') {
    const converted = new Date(date);
    if (Number.isNaN(converted.getTime())) return undefined;
    return converted;
  }
  return date;
};

export const checkDateOlderThan = (date: string | Date | null | undefined, days: number) => {
  const checkedDate = stringIsDate(date);
  if (checkedDate) {
    //                h    min  sec  ms
    const daysInMs = days * 24 * 60 * 60 * 1000;
    const timestampDays = new Date().getTime() - daysInMs;
    return timestampDays > checkedDate.getTime();
  }
  return false;
};

// Get the difference between to dates in milliseconds.
export const getDateDiffMillis = (fromDate: string|Date, toDate: string|Date) => (
  new Date(toDate).getTime() - new Date(fromDate).getTime()
);

// create a function to get the newest date in
export const getNewestDate = (date: string | Date | null | undefined, otherDate: string | Date | null | undefined) => {
  const checkedDate = stringIsDate(date);
  const otherCheckedDate = stringIsDate(otherDate);
  if (checkedDate && !otherCheckedDate) return checkedDate;
  if (!checkedDate && otherCheckedDate) return otherCheckedDate;
  if (checkedDate && otherCheckedDate) {
    const result = checkedDate.getTime() - otherCheckedDate.getTime();
    if (result < 0) return otherCheckedDate;
    return checkedDate;
  }
  return undefined;
};

export const getISODate = (date: string | Date | null | undefined) => {
  const checkedDate = stringIsDate(date);
  if (checkedDate) {
    return checkedDate.toISOString();
  }
  return null;
};

// TODO Need to check UTC dates and this!!!

// export const getUTCDateOnly = (date: string | Date | null | undefined) => {
//   const checkedDate = stringIsDate(date);
//   if (checkedDate) {
//     return checkedDate.getu();
//   }
//   return null;
// };

dayjs.extend(utc);

export const getUTCDate = (date: string | Date | null | undefined) => dayjs(date).toISOString();

/**
 * Convert a string or Date to a format suitable for input type datetime-local.
 */
export const toDateTimeLocal = (input:Date|string) => {
  const date = input instanceof Date ? input : new Date(input);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    // Remove second and milliseconds and time zone
    .substring(0, 16);
};
