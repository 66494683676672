import React from 'react';
import { Form } from 'react-bootstrap';
import { SecurityLevel } from '../pages/vulnerabilities/Types';
import { useSecurityLevelAsText } from '../utils/TranslationUtils';

interface Props {
  securityLevel: SecurityLevel,
  itemRenderer?: (securityLevel:SecurityLevel, stringValue:string) => string,
  showUnknown?: boolean,
  onChange?: (level:SecurityLevel) => void,
  className?: string
}

const SecurityLevelPicker = (props:Props) => {
  const {
    securityLevel,
    onChange: outerOnChange,
    itemRenderer,
    showUnknown,
    className,
  } = props;
  const securityLevelAsText = useSecurityLevelAsText();

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (outerOnChange) {
      outerOnChange(event.target.value as SecurityLevel);
    }
  };
  const defaultValue = !showUnknown && securityLevel === SecurityLevel.Unknown
    ? SecurityLevel.Advanced
    : securityLevel;

  return (
    <Form.Select
      onChange={onChange}
      value={defaultValue}
      className={className}
    >
      { Object.values(SecurityLevel)
        .filter((k) => showUnknown || k !== SecurityLevel.Unknown)
        .map((k) => (
          <option key={k} value={k}>
            {
              itemRenderer
                ? itemRenderer(k as SecurityLevel, securityLevelAsText(k as SecurityLevel))
                : securityLevelAsText(k as SecurityLevel)
            }
          </option>
        ))}
    </Form.Select>
  );
};

export default SecurityLevelPicker;
