/* eslint-disable no-debugger */
import { toast } from 'react-toastify';
import { Module } from '../types/AccessTypes';
import {
  ActiveStatus,
  AdminAccessLevel, AdminAccount,
} from '../types/AdminTypes';
import { isIsoDateString } from './DateUtils';

export const defaultUserAccessState : AdminAccessLevel[] = [
  { module: Module.risk, role: 'none' },
  { module: Module.risk, role: 'none' },
];

export const emptyUserAccess : AdminAccessLevel = {
  module: null, role: null,
};

export const emptyUser : AdminAccount = {
  externalId: '',
  provider: 'azureEntraIdUser',
  name: '',
  accessLevel: [{ ...emptyUserAccess }],
  customerId: '',
  active: false,
  status: ActiveStatus.disabled,
  id: '',
};

export const filterProperties = <T>(obj:{[key:string]: T}, filter:((arg0:T)=>boolean)) => {
  const copy = { ...obj };
  Object.keys(copy).forEach((k) => {
    if (filter(copy[k])) {
      delete copy[k];
    }
  });
  return copy;
};

export const uniqueFilter = <T>(value: T, index:number, array:Array<T>) => (array.indexOf(value) === index);

export const removeNulls = <T>(value: T | undefined): value is T => value != null;
export const setCookie = (name: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${ date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/; Secure`;
};

export interface IDataTypeDetection {
  isInt: boolean,
  isFloat: boolean,
  isDateString: boolean,
  dateHasTime: boolean,
  isBoolean: boolean,
  isNull: boolean
}

export const identifyDataType = (arr:(string|null|undefined)[]) => arr.reduce(
  (a, b) => {
    if (!b || b === '') {
      return {
        isInt: a.isInt,
        isFloat: a.isFloat,
        isDateString: a.isDateString,
        dateHasTime: a.dateHasTime,
        isBoolean: a.isBoolean,
        isNull: a.isNull,
      };
    }

    const mIsInt = !!b.match(/^\d+$/);
    const mIsFloat = !!b.match(/^\d+([.,]\d+)?$/);
    const mIsDateString = isIsoDateString(b);

    const mDateHasTime = mIsDateString
      ? ((date:Date) => (
        !!(date.getUTCHours() || date.getUTCMinutes() || date.getUTCSeconds() || date.getUTCMilliseconds())
      ))(new Date(b))
      : false;

    return {
      isInt: a.isInt && mIsInt,
      isFloat: a.isFloat && mIsFloat,
      isDateString: a.isDateString && mIsDateString,
      dateHasTime: a.dateHasTime && mDateHasTime,
      isBoolean: a.isBoolean && ['true', 'false', 'yes', 'no'].includes(b.toLowerCase()),
      isNull: a.isNull && (b === null || b === undefined),
    };
  },
  {
    isInt: true,
    isFloat: true,
    isDateString: true,
    dateHasTime: true,
    isBoolean: true,
    isNull: true,
  },
);

/**
 * Gets a readable string based on a boolean / null / undefined value
 * If null/undefined => Unknown
 * If true => Yes
 * If false => No
 * @param value The value to convert
 * @returns a string
 */
export const boolGetYesNoUnkown = (value: boolean | null | undefined) => {
  if (value === null || value === undefined) return 'Unknown';
  return value ? 'Yes' : 'No';
};

export const getSteppedValue = (
  max: number,
  steps: number,
  value: number,
  rounder?: (value: number) => number,
): number => {
  if (value < 0 || value > max) {
    throw new Error(`Value should be between 0 and max (${max}), got ${value}`);
  }

  if (value === 0) {
    return 0;
  }

  const stepSize = max / steps;
  const result = (rounder ?? Math.floor)(value / stepSize);
  return result === steps ? steps : result;
};

/**
 * Finds the maximum number in a multidimensional array.
 */
export const findMaxNumber = (arr: (number|undefined)[][]): number => {
  let maxNumber = -Infinity;

  arr.forEach((subArr) => {
    if (Array.isArray(subArr)) {
      subArr.forEach((num) => {
        if (typeof num === 'number' && !Number.isNaN(num) && num > maxNumber) {
          maxNumber = num;
        }
      });
    }
  });

  return maxNumber;
};

export const copyClipboard = (data:object|string|undefined, toastMessage:string|undefined) => {
  if (!data) return;
  const valueAsString = typeof data === 'string'
    ? data
    : JSON.stringify(data, null, 2);
  navigator.clipboard.writeText(valueAsString);
  if (toastMessage) {
    toast.success(toastMessage, {
      toastId: toastMessage,
      updateId: toastMessage,
    });
  }
};

export const toggleInArray = <T, >(arr:T[]|undefined, value:T, presence?:boolean) => {
  const newArr = arr ? [...arr] : [];
  const index = newArr.indexOf(value);

  if (index === -1) {
    if (presence !== false) {
      newArr.push(value);
    }
  } else if (presence !== true) {
    newArr.splice(index, 1);
  }

  return newArr;
};

// export const inEnum = <T extends string, >(value:string) => Object.values(value).includes(value);

export const inEnum = <T, >(enumType: T, value: string): boolean => {
  const enumValues = Object.values(enumType as Record<string, string>);
  return enumValues.includes(value);
};

export const getEnumValues = (enumObj: object): number[] => (
  Object.values(enumObj).filter((value) => typeof value === 'number').map((v) => v as number)
);

export const convertPageToNewPageSize = (
  previousPageSize: number,
  newPageSize: number,
  currentPage: number,
  totalItems: number,
): number => {
  const previousOffset = (currentPage - 1) * previousPageSize;
  const newPage = Math.floor(previousOffset / newPageSize) + 1;

  const maxNewPage = Math.ceil(totalItems / newPageSize);
  return Math.min(newPage, maxNewPage);
};

/**
 * Checks wether an index array points to a child of the targetIndex.
 *
 * @param targetIndex An array containing indexes to an element in a multi-dimensional array.
 * @param index An array containing indexes to an element in a multi-dimensional array.
 * @returns True if index is a child of targetIndex
 */
export const isChildIndex = (targetIndex:number[], index:number[]) => {
  if (targetIndex.length >= index.length) {
    return false;
  }
  for (let i = 0; i < targetIndex.length; i += 1) {
    if (index[i] !== targetIndex[i]) {
      return false;
    }
  }
  return true;
};

export const recordPropertyGetOrDefault = <T, >(
  key:string,
  records:Record<string, T>|undefined,
  accessor:(record:T) => string|undefined,
  defaultValue?:string|undefined,
) => (records && records[key] ? accessor(records[key]) : defaultValue);

export const getEnvironmentFromAzureRedirectUrl = (url:string) => {
  if (url.match(/^https?:\/\/localhost/)) {
    return 'dev';
  }

  const matches = url.match(/:\/\/([a-z]+)\.ivolv\.cloud/);
  // 'https://security.ivolv.cloud'

  // Assume prod (null) if known environment could not be resolved.
  // Prod has the strictest privacy rules, and is the safe option.
  if (!matches) {
    return null;
  }

  switch (matches[1]) {
  case 'qa':
  case 'dev':
    return matches[1];
  case 'security':
    return 'prod';
  default:
    return null;
  }
};

export const getActiveStatusCssClass = (status:ActiveStatus, defaultClass?:string|undefined) => {
  switch (status) {
  case 'disabled':
  case 'disabledAndHidden':
    return 'text-decoration-line-through';
  case 'invited':
    return 'text-muted';
  default:
    return defaultClass ?? '';
  }
};
