import React, { useMemo, useState } from 'react';
import {
  Button, Card, Col, Row, Spinner, Stack,
} from 'react-bootstrap';
import {
  Link, useLoaderData, useNavigate, useParams,
} from 'react-router-dom';
import axios from 'axios';
import { QueryClient } from '@tanstack/react-query';
import AssessmentVulnerabilityTableCard, { IAssessmentVulnerabilityTableItem } from './AssessmentVulnerabilityTableCard';
import { useAssessmentStateStore } from './AssessmentStateStore';
import { IAssessment, IAssessmentSyncAsset, IAssessmentSyncData } from './AssessmentTypes';
import { IStringDictionary } from '../../types/Types';
import { VulnerabilityStatus } from '../vulnerabilities/Types';
import ROUTES from '../../routing/Routes';
import { getOrFetchFromApi } from '../../query/GenericQuery';
import AssessmentDiscardButton from './AssessmentDiscardButton';
import { createAssessmentStateManager } from './AssessmentStateManager';
import { urlEncodeBase64 } from '../../utils/StringUtils';
import { Module } from '../../types/AccessTypes';
import { IAccountDetails, useAccount } from '../../providers/AccountProvider';
import { searchQueryKeys } from '../vulnerabilities/VulnerabilityFilter';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { ValidPageSizes } from '../../common/table/PaginationV8';

interface IData {
  assessment: IAssessment,
}

export const AssessmentSaveConfirmPage:IComponentWithLoader<{assessment:IAssessment}, string> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:ValidPageSizes,
    sha512?:string|undefined,
  ) => {
    const assessment = await getOrFetchFromApi<IAssessment>(
      queryClient,
      sha512 && `module/assessment/${encodeURIComponent(sha512)}`,
    );

    return {
      assessment,
    };
  },
  Component: () => {
    const { id: sha512 } = useParams();
    const { customer } = useAccount();
    const navigate = useNavigate();

    const [working, setWorking] = useState<string>();

    const { assessment } = useLoaderData() as Awaited<IData>;

    const managedState = useAssessmentStateStore(customer, sha512);

    const stateManager = useMemo(() => (
      createAssessmentStateManager(assessment, managedState)
    ), [assessment, managedState]);

    const pendingVulnerabilities = useMemo(() => {
      if (!stateManager) return [];

      const assetMap = {} as IStringDictionary<IAssessmentSyncAsset>;
      stateManager?.getAssets().forEach((a) => {
        assetMap[a.uniqueId] = a;
      });

      const flattened = [] as IAssessmentVulnerabilityTableItem[];

      Object.values(stateManager?.getVulnerabilities()).forEach((vulns) => {
        vulns.forEach((v) => {
          flattened.push({
            ...v,
            asset: assetMap[v.assetUniqueId],
          });
        });
      });

      return flattened;
    }, [stateManager]);

    const saveAssessment = async () => {
      if (!stateManager) return;

      setWorking('save');
      const data = {
        assessmentId: assessment?.id,
        controlFrameworkFriendlyId: assessment?.library.id,
        assets: [],
        vulnerabilities: [],
      } as IAssessmentSyncData;

      data.assets = stateManager.getAssets();

      const vulnerabilities = stateManager.getVulnerabilities();
      Object.keys(vulnerabilities).forEach((k) => {
        vulnerabilities[k].forEach((v) => {
          data.vulnerabilities.push({
            ...v,
            assetUniqueId: parseInt(k.substring(0, k.indexOf('_')), 10),
            mitigationPercent: 0,
            status: VulnerabilityStatus.Open,
            // Tag uniqueId with the assessment Id to allow us to properly manage on next assessment
            uniqueId: !assessment?.id && !v.uniqueId
              ? undefined
              : `${assessment?.id ?? ''}${v.uniqueId ? `-${v.uniqueId}` : ''}`,
          });
        });
      });

      await axios.put('/api/v1/module/assessment/sync', data);

      managedState.clear();

      navigate(`${ROUTES.vulnerabilitySummary.uri}?framework=${assessment?.library.id}&${searchQueryKeys.sourceModuleId}=${Module.assessment}&toast=assessment-complete`);
    };

    if (!working && sha512 && stateManager && stateManager.isReady() && !stateManager.canBeFinalized()) {
      return (
        <Row>
          <Col>
            <Card>
              <Card.Header>
                Assessment cannot be finalized.
              </Card.Header>

              <Card.Body>
                <Button
                  onClick={() => navigate(`${ROUTES.assessment.uri}/${urlEncodeBase64(sha512)}`)}
                >
                  Continue assessment
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      );
    }

    return !sha512 || !customer || !stateManager || !stateManager.canBeFinalized() || !stateManager.isReady()
      ? <Spinner animation="border" />
      : (
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header>
                Save assessment?
              </Card.Header>
              <Card.Body>
                You&apos;ve completed the assessment session and the below vulnerabilities have been found.

                <div className="pt-2">
                  Finalize the assessment by saving or discarding the result.
                  <Stack direction="horizontal" gap={2} className="pt-3">
                    <Button
                      variant="primary"
                      disabled={!!working}
                      onClick={saveAssessment}
                    >
                      { working ? <Spinner animation="border" className="me-2" size="sm" /> : null }
                      Save
                    </Button>
                    <Link
                      className="btn btn-secondary"
                      to={`/assessment/${encodeURIComponent(sha512)}`}
                    >
                      Assess more assets
                    </Link>
                    <AssessmentDiscardButton
                      disabled={!!working}
                      stateManager={stateManager}
                      onDiscarded={() => navigate('/')}
                    >
                      Discard
                    </AssessmentDiscardButton>
                  </Stack>
                </div>
              </Card.Body>
            </Card>

            <AssessmentVulnerabilityTableCard vulnerabilities={pendingVulnerabilities} />
          </Col>
        </Row>
      );
  },
};
