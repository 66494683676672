import React from 'react';
import { IVulnerability } from './Types';
import { EntityType } from '../../types/EntityTypes';
import ROUTES from '../../routing/Routes';
import { EntityCardFooter } from '../../components/EntityCardFooter';

export const VulnerabilityCardFooter = ({ vulnerability }:{vulnerability:IVulnerability}) => (
  <EntityCardFooter
    timestamps={vulnerability}
    entity={{ entityId: vulnerability.id, entityType: EntityType.Vulnerability }}
    linkRoute={ROUTES.vulnerabilities}
    additionalDetails={(
      <span>
        <span>
          {'ID: '}
          { vulnerability.id }
        </span>
        <span>
          { vulnerability.uniqueId ? `, unique ID: ${vulnerability.uniqueId}` : ''}
        </span>
      </span>
    )}
  />
);
