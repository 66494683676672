import React, { useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import {
  Button, Col, Modal, Row, Stack, Spinner, Form, Alert, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { useGetEffectiveRolesQuery } from '../../query/AccessQueries';
import { ActiveStatus, AdminAccount, AdminCustomer } from '../../types/AdminTypes';
import { ModulePermissionsSelect } from './ModulePermissions';
import { useApi, useInvalidateQueries } from '../../query/GenericQuery';
import { filterProperties } from '../../utils/Utils';
import {
  Role, IHaveModuleRoles, Module, ICustomerModule,
} from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { useGetAccountProviderAsText, useGetActiveStatusAsText } from '../../utils/TranslationUtils';
import { IModalBodyAndFooterProps, useNewModalContext } from '../../providers/NewModalProvider';

interface IValue {
  customer: AdminCustomer,
  account: AdminAccount,
}

/**
 * Modal for editing user details in the admin module.
 */
export const AdminAccountDetailsModalContent = (props:IModalBodyAndFooterProps) => {
  const {
    close, setValue, value,
  } = props;

  const { hasModuleRole, refreshAccess, user: currentUser } = useAccount();

  const getAccountProviderAsText = useGetAccountProviderAsText();
  const invalidateUserList = useInvalidateQueries('module/admin/accounts');
  const { pushConfirm } = useNewModalContext();
  const activeStatusAsText = useGetActiveStatusAsText();

  const { account, customer } = value as IValue;

  const [deleteEnabled, setDeleteEnabled] = useState(account ? account.active : false);

  const permissions = useGetEffectiveRolesQuery(account.id);

  const {
    data: haveModuleRoles,
    invalidate: invalidateModuleRoles,
  } = useApi<IHaveModuleRoles>(
    `module/admin/accounts/${encodeURIComponent(account.id)}/permissions`,
  );

  const { data: customerModules } = useApi<ICustomerModule[]>(
    `module/admin/customers/${encodeURIComponent(account.customerId)}/modules`,
  );

  const readOnly = !hasModuleRole(Module.admin, 'readWrite');

  const isLoggedInUser = account.externalId === currentUser?.externalId;

  const initialValues = useMemo(() => (
    {
      name: account.name,
      externalId: account.externalId,
      email: account.email,
      provider: account.provider,
      active: account.active,
      status: account.status,
      moduleRoles: haveModuleRoles?.moduleRoles,
    }
  ), [account, haveModuleRoles]);

  const saveAccountMutation = useMutation({
    mutationFn: async (data:typeof initialValues) => axios.put<AdminAccount>(
      `/api/v1/module/admin/accounts/${account?.id}`,
      // Only send writable properties
      {
        ...account,
        active: data.active,
        status: data.status,
        name: data.name,
        email: data.email,
        // Only allow setting upn if user is not the currently logged in user
        externalId: isLoggedInUser ? account.externalId : data.externalId,
      },
    ),
    onSuccess: ({ data: updatedAccount }) => {
      setDeleteEnabled(!updatedAccount.active);
      invalidateUserList();
    },
  });

  const savePermissionsMutation = useMutation({
    mutationFn: async (data: { accountId:string, moduleRoles: Record<number, Role> }) => axios.put(
      `/api/v1/module/admin/accounts/${encodeURIComponent(data.accountId)}/permissions`,
      filterProperties(data.moduleRoles, (role:Role) => role === 'none'),
    ),
  });

  // Disabling this until we've decided on delete user
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteAccountMutation = useMutation({
    mutationFn: async (data: { accountId:string}) => axios.delete(
      `/api/v1/module/admin/accounts/${encodeURIComponent(data.accountId)}`,
    ),
    onSuccess: () => {
      toast.warn('Account was deleted');
      invalidateUserList();
      close(false);
    },
  });

  const supportedAccount = customer.canBeAdmin || account.provider !== 'azureAppRegistration';

  return (
    <Modal.Body>
      { !supportedAccount
        ? (
          <Alert variant="warning" className="p-3">
            This account type is only supported for customers configured as administrators.
            This account will not be able to use the platform.
          </Alert>
        )
        : null }
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, helpers) => {
          const { data: updateAccount } = await saveAccountMutation.mutateAsync(values);

          if (values.moduleRoles
              && JSON.stringify(values.moduleRoles) !== JSON.stringify(initialValues.moduleRoles)) {
            await savePermissionsMutation.mutateAsync({ accountId: account.id, moduleRoles: values.moduleRoles });

            // Refreshing access for current user will close the modal, make sure we close it cleanly before refreshing.
            // If we didn't do this, the modal will be left in stack and pop up randomly.
            if (account.id === currentUser.id) {
              close(false);
              refreshAccess();
            }
            await invalidateModuleRoles();
          }

          setValue({
            ...(value as IValue),
            account: updateAccount,
          });

          toast.success('Account was saved', {
            toastId: 'account-saved',
            updateId: 'account-saved',
          });
          helpers.resetForm({ values });
        }}
      >
        {({
          values, setFieldValue, handleChange, handleSubmit, handleReset, dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  value={values.name}
                  name="name"
                  disabled={readOnly}
                  onChange={handleChange}
                />
                <span className="small text-muted">{account.id}</span>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  value={values.email}
                  name="email"
                  placeholder={values.externalId}
                  disabled={readOnly}
                  onChange={handleChange}
                />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Label>Provider:</Form.Label>
                <Form.Control
                  value={getAccountProviderAsText(values.provider)}
                  name="provider"
                  disabled
                  onChange={handleChange}
                />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Label>ExternalId:</Form.Label>
                <Form.Control
                  value={values.externalId}
                  name="externalId"
                  disabled={readOnly || isLoggedInUser}
                  onChange={handleChange}
                />
              </Col>
              <Col md={6} className="mb-3">
                <Form.Select
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                >
                  {[ActiveStatus.active, ActiveStatus.disabled, ActiveStatus.invited].map((status) => (
                    <option
                      key={status}
                      value={status}
                      disabled={![ActiveStatus.active, ActiveStatus.disabled].includes(status)}
                    >
                      {activeStatusAsText(status)}
                    </option>
                  ))}
                </Form.Select>
                { values.status === ActiveStatus.disabled
                  ? (
                    <Alert variant="danger" className="p-2 mt-2">
                      <div>
                        The account is disabled, and cannot access the platform.
                      </div>
                    </Alert>
                  )
                  : null }
                { values.status === ActiveStatus.invited
                  ? (
                    <Alert variant="warning" className="p-2 mt-2">
                      <div>
                        The account is invited, and an invitation email has been sent.
                        The account cannot access the platform before the invitation has been accepted.
                      </div>
                    </Alert>
                  )
                  : null }
                { account.status === ActiveStatus.invited && values.status === ActiveStatus.active
                  ? (
                    <Alert variant="danger" className="p-2 mt-2">
                      <div>
                        Manually enabling an invited account is not recommended, as it
                        bypasses the user&apos;s acceptance of the platform&apos;s terms of use.
                        This action could lead to a GDPR violation.
                      </div>
                    </Alert>
                  )
                  : null }
              </Col>
            </Row>
            { supportedAccount
              ? (
                <Row>
                  <Col md={12} className="mb-3">
                    { values.moduleRoles && customerModules ? (
                      <ModulePermissionsSelect
                        customerModules={customerModules}
                        disabled={readOnly}
                        showHeaders
                        onChange={(moduleRoles) => {
                          if (isLoggedInUser && moduleRoles[4] === 'read') {
                            toast.warning('You cannot remove the admin module from yourself!');
                          } else {
                            setFieldValue('moduleRoles', moduleRoles);
                          }
                        }}
                        moduleRoles={values.moduleRoles}
                        effectiveRoles={permissions?.effectiveRoles}
                      />
                    ) : <Spinner animation="border" /> }
                  </Col>
                </Row>
              ) : null }
            { !readOnly ? (
              <Row>
                <Col md={12}>
                  <Stack direction="horizontal" gap={2}>
                    <Button disabled={!dirty} type="submit">
                      Save
                    </Button>
                    <Button
                      disabled={!dirty}
                      type="reset"
                      onClick={handleReset}
                      variant="secondary"
                    >
                      Cancel
                    </Button>
                    { deleteEnabled
                      ? (
                        <Button
                          disabled={dirty}
                          type="button"
                          onClick={async () => {
                            // Cannot use the modal based confim, as we're already in a modal
                            // eslint-disable-next-line no-restricted-globals, no-alert
                            if (await pushConfirm({
                              content: (
                                <>
                                  <p>
                                    Are you sure you would like to remove the
                                    {' '}
                                    {getAccountProviderAsText(account.provider)}
                                    {' '}
                                    account
                                    {' '}
                                    {account.name}
                                    &lt;
                                    {account.externalId}
                                    ?
                                  </p>
                                  <p>
                                    The accounts associated entities (e.g. vulnerabilities and jobs) will
                                    not be affected by the deletion.
                                  </p>
                                  { account.provider === 'azureEntraIdUser'
                                    ? (
                                      <p>
                                        After deletion the user will be allowed to create
                                        another account.
                                        {' '}
                                        <strong>
                                          If your goal is to prevent the user
                                          from logging in, it should be left disabled.
                                        </strong>
                                      </p>
                                    )
                                    : null }
                                </>
                              ),
                            })) {
                              await deleteAccountMutation.mutateAsync({ accountId: account.id });
                            }
                          }}
                          variant="danger"
                        >
                          Delete
                        </Button>
                      )
                      : (
                        <OverlayTrigger
                          overlay={<Tooltip>Cannot delete an enabled account</Tooltip>}
                        >
                          <div>
                            <Button
                              variant="danger"
                              disabled
                            >
                              Delete
                            </Button>
                          </div>
                        </OverlayTrigger>
                      )}
                  </Stack>
                </Col>
              </Row>
            ) : null }
          </Form>
        )}
      </Formik>
    </Modal.Body>
  );
};
