import { Row } from '@tanstack/react-table';

/**
 * Custom filter for filtering dates by a string date.
 */
export const dateStringFilterFn = <T, >(
  row: Row<T>,
  columnId: string,
  filterValue: string,
  // @ts-ignore
): boolean => row.original[columnId].replace(/[/, :]/g, '').includes(filterValue.replace(/[/, :]/g, ''));
