import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

interface AddEditAdminModalProps {
  show: boolean,
  handleClose: () => void,
  isAdd: boolean,
  FormElem: JSX.Element,
  itemType: string,
  size?: ModalProps['size'],
}

/**
 * Modal used in the admin pages for the Customer/UserForms
 */
export const AddEditAdminModal = (props: AddEditAdminModalProps) => {
  const {
    show, handleClose, isAdd, FormElem, itemType, size,
  } = props;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size={size ?? 'sm'}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isAdd ? (
            <strong>Add </strong>
          ) : (
            <strong>Edit </strong>
          )}
          {itemType}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {FormElem}
      </Modal.Body>
    </Modal>
  );
};

export default AddEditAdminModal;
