import { Column, Table, VisibilityState } from '@tanstack/react-table';
import React from 'react';
import {
  Button, Form, Modal, Stack,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import { toFirstUpperCase } from '../../../utils/StringUtils';
import { hasFilterValue } from '../TableStoreV8';

/**
 * A Modal for selecting columns for the ReactTable
 *
 * ReactTable implements this directly
 */
export const SelectColumnsModalV8 = <T, >({
  show,
  handleClose,
  table,
  defaultColumnVisibility,
  filterValueColumnAccessorKeyMap,
  getAccessorKeyFromColumn,
}:{
  show: boolean,
  handleClose: () => void,
  table: Table<T>,
  defaultColumnVisibility: VisibilityState,
  filterValueColumnAccessorKeyMap: Record<string, object[]>
  getAccessorKeyFromColumn: (column:Column<T, unknown>) => string|undefined
}) => {
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleClose();
  };

  const onColumnReset = () => {
    if (defaultColumnVisibility) {
      table.setColumnVisibility(defaultColumnVisibility);
    } else {
      table.resetColumnVisibility();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="sm"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Select columns to view
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={onSubmit}
          onReset={onColumnReset}
        >
          <Stack direction="vertical" gap={1}>
            { /*
            Adding column visibility to checkbox key might not be the best practice solution, but
            it is the cleanest solution that works, that does not require us to duplicate state
            from table. I tried approaches, and could not get the state one to cooperate with
            ReactTable state without side effects.
            */ }
            {table.getAllLeafColumns().map((col) => {
              const accessorKey = getAccessorKeyFromColumn(col);
              const filterValue = accessorKey && filterValueColumnAccessorKeyMap[accessorKey];
              const isFiltered = hasFilterValue(filterValue) || hasFilterValue(col.getFilterValue());

              return (
                <Form.Check
                  key={`${col.id}-${col.getIsVisible()}`}
                  checked={col.getIsVisible()}
                  id={col.id}
                  label={(
                    <>
                      <span>
                        {typeof col.columnDef.header === 'function' ? toFirstUpperCase(col.id) : col.columnDef.header as string}
                      </span>
                      {isFiltered ? <Icon name="filter" size="12" className="ms-1" /> : null }
                    </>
                  )}
                  onChange={col.getToggleVisibilityHandler()}
                  disabled={!col.getCanHide() || isFiltered}
                />
              );
            })}
          </Stack>
          <br />
          <Stack direction="horizontal" gap={1}>
            <Button variant="danger" type="reset">Reset</Button>
            <Button type="submit">Close</Button>
          </Stack>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SelectColumnsModalV8;
