import { IEffectiveRoles, IHaveModuleRoles } from '../types/AccessTypes';
import { useApi } from './GenericQuery';

export const useGetEffectiveRolesQuery = (userId?: string) => {
  const {
    data: userPermissions,
    isFetched: userFetched,
  } = useApi<IHaveModuleRoles>(
    userId && `module/admin/accounts/${encodeURIComponent(userId)}/permissions`,
  );

  const {
    data: effectivePermissions,
    isFetched: effectiveFetched,
  } = useApi<IHaveModuleRoles, {includeInherited:boolean}>(
    userId && `module/admin/accounts/${encodeURIComponent(userId)}/permissions`,
    {
      includeInherited: true,
    },
  );

  return userFetched && effectiveFetched
    ? {
      userRoles: userPermissions?.moduleRoles,
      effectiveRoles: effectivePermissions?.moduleRoles,
    } as IEffectiveRoles
    : undefined;
};
