/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';
import { IAvailableCustomerData } from './DashboardPage';
import { startVulnerabilityScan } from '../../components/ScanVulnerabilitiesButton';
import { Module } from '../../types/AccessTypes';
import { useApi } from '../../query/GenericQuery';
import { IErrorDetails, IJob, JobStatus } from '../../types/Types';
import { useAccount } from '../../providers/AccountProvider';
import { useInvalidateAvailableCustomerData } from '../../providers/AvailableCustomerDataProvider';

const NoDataAndNoPermittedAction = () => {
  const { isAssociatedTo } = useAccount();

  return (
    <p>
      There&apos;s no information available for your tenant.
      { isAssociatedTo?.length
        ? (
          <p className="mt-3">
            You have permission to access other customer tenants. Use the
            {' '}
            <em>Switch organization</em>
            {' '}
            button on your top right to select another customer.
          </p>
        )
        : (
          <p className="mt-3">
            And you don&apos;t have permission to perform any actions.
            <br />
            <br />
            Please contact
            {' '}
            <a href="mailto:support@ivolv.no">Ivolv Support</a>
            {' '}
            for further assistance.
          </p>
        ) }
    </p>
  );
};

export const Welcome = ({ availableData }:{availableData?:IAvailableCustomerData}) => {
  const { customer } = useAccount();
  const jobStartedRef = useRef(false);
  const [jobId, setJobId] = useState<number>();
  const [scanError, setScanError] = useState<AxiosError>();
  const { hasModuleRole, customerHasModule } = useAccount();
  const invalidateAvailableData = useInvalidateAvailableCustomerData();

  const refetchInterval = 5000;

  const { data: job } = useApi<IJob>(
    jobId ? `jobs/${jobId}` : undefined,
    undefined,
    {
      refetchInterval,
      enabled: !!jobId,
    },
  );

  useEffect(() => {
    if (job?.status === JobStatus.Finalized) {
      toast.info('We\'re done scanning for vulnerabilities and your dashboard has been updated! Your connected systems will be continouly scanned and your dashboard will always reflect the current status.', {
        autoClose: 30000,
      });
      invalidateAvailableData();
    }
  }, [invalidateAvailableData, job?.status]);

  useEffect(() => {
    // Make sure we only start one job
    if (jobStartedRef.current) {
      return;
    }

    // TODO: Currently we trigger the entraID module consent from dashboard. Since all other
    // modules with consent is currently dependent on this, we can safely assume that if
    // consent is available, it is entraID. This will probably not be the case in the future
    // and we'll need to adress this at some point.
    if (!availableData || !availableData.hasConsents || availableData.hasVulnerabilities) {
      return;
    }

    startVulnerabilityScan(Module.entraId, customer.id)
      .then((id) => setJobId(id))
      .catch((err) => {
        const axiosError = err as AxiosError;
        if (axiosError.response?.status === 409) {
          const errorDetails = axiosError.response?.data as IErrorDetails;
          if (errorDetails) {
            const details = errorDetails.errorDetails as {conflictingJobId:number};
            if (details && details.conflictingJobId) {
              setJobId(details.conflictingJobId);
            }
          }
        } else {
          setScanError(axiosError);
          // eslint-disable-next-line no-console
          console.error(`Unable to start scan from welcome tile: ${err}`);
        }
      });

    // Set flag indicating that a scan job has been started
    jobStartedRef.current = true;
  }, [availableData, customer.id]);

  if (!availableData || !availableData.hasConsents) {
    return hasModuleRole(Module.customerAdmin, 'readWrite')
      ? customerHasModule(Module.entraId) ? (
        <p>
          There&apos;s no information to show, until you connect with your services.
        </p>
      )
        : (
          <>
            <p>
              There&apos;s no information to show.
            </p>
            <p>
              Please check back later, and don&apos;t hesitate to contact
              {' '}
              <a href="mailto:support@ivolv.no">Ivolv Support</a>
              {' '}
              if you need assistance connecting your services.
            </p>
          </>
        )
      : <NoDataAndNoPermittedAction />;
  }

  return scanError
    ? (
      <Alert variant="warning" className="p-3 pb-1">
        <div>
          <p>
            We&apos;ve tried to scan your service for vulnerabilities but encountered some issues (
            {scanError.response?.status}
            {' '}
            {scanError.response?.statusText}
            ).
          </p>
          <p>
            Please contact
            {' '}
            <a href="mailto:support@ivolv.no">Ivolv Support</a>
            {' '}
            for further assistance.
          </p>
        </div>
      </Alert>
    )
    : (
      <>
        <p>
          You have successfully connected your first service, and we&apos;ve started a vulnerability scan.
        </p>
        <p>
          In a few minutes, when the scan is done, the platform will be updated with the initial findings.
        </p>
      </>
    );
};
