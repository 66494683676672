import { useInvalidateQueries } from '../query/GenericQuery';

/** Hook for refreshing users and customers */
export const useAdminPageRefresh = () => {
  const invalidateCustomers = useInvalidateQueries('module/admin/customers');
  const invalidateAccounts = useInvalidateQueries('module/admin/accounts');

  return {
    refreshAccounts: invalidateAccounts,
    refreshCustomers: invalidateCustomers,
  };
};

export default useAdminPageRefresh;
