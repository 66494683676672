import React from 'react';
import {
  Button, Card, Col, Row, Stack,
} from 'react-bootstrap';
import { useLogout } from '../../providers/BearerTokenProvider';

export const SignupConfirmCard = () => {
  const logout = useLogout();

  return (
    <Card className="fill card-md">
      <Card.Header>
        Signup complete!
      </Card.Header>
      <Card.Body>
        <p>
          You will get an email from us soon.
        </p>
        <p>
          We are processing your request and will get back to you very soon.
        </p>
      </Card.Body>
      <div className="card-button-footer">
        <Row>
          <Col md={12}>
            <Stack direction="horizontal" gap={2}>
              <Button
                variant="primary"
                autoFocus
                onClick={logout}
              >
                I understand
              </Button>
            </Stack>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
