import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { createColumnHelper } from '@tanstack/react-table';
import { useStore } from 'zustand';
import { IAssessmentAsset, IAssessmentSyncVulnerability } from './AssessmentTypes';
import { TableColumnDefV8 } from '../../common/table';
import { useTableStoreV8, columnsToVisibilityState } from '../../common/table/TableStoreV8';
import { useVulnerabilityStatusAsText } from '../../utils/TranslationUtils';
import { VulnerabilityStatus } from '../vulnerabilities/Types';
import { TableFromArray } from '../../common/table/TableFromArray';
import { arrIncludesSomeWithEmptyFixFn } from '../../common/table/filters/TableFilter';

export interface IAssessmentVulnerabilityTableItem extends IAssessmentSyncVulnerability {
  asset:IAssessmentAsset
}

interface IProps {
  vulnerabilities: IAssessmentVulnerabilityTableItem[]
}

const AssessmentVulnerabilityTableCard = (props:IProps) => {
  const { vulnerabilities } = props;

  const vulnerabilityStatusAsText = useVulnerabilityStatusAsText();

  const columnHelper = createColumnHelper<IAssessmentVulnerabilityTableItem>();

  const columns = useMemo(
    () => [
      {
        ...columnHelper.accessor('asset', {
          header: 'Asset',
          cell: ({ getValue }) => (getValue()?.name),
          filterFn: arrIncludesSomeWithEmptyFixFn,
        }),
        formatter: (value:IAssessmentAsset) => value.name,
      },
      {
        ...columnHelper.accessor('status', {
          header: 'Status',
          cell: ({ row }) => vulnerabilityStatusAsText(row.original.status),
          filterFn: arrIncludesSomeWithEmptyFixFn,
        }),
        formatter: (value:VulnerabilityStatus) => vulnerabilityStatusAsText(value),
      },
      columnHelper.accessor('controlFriendlyId', {
        header: 'Control',
        filterFn: 'weakEquals',
        sortingFn: (a, b) => {
          const { original: vulnA } = a;
          const { original: vulnB } = b;

          return vulnA.controlFriendlyId.localeCompare(
            vulnB.controlFriendlyId,
            undefined,
            { numeric: true, sensitivity: 'base' },
          );
        },
      }),
      columnHelper.accessor('summary', {
        header: 'Summary',
        filterFn: 'weakEquals',
      }),
      columnHelper.accessor('details', {
        header: 'Details',
        filterFn: 'weakEquals',
      }),
      columnHelper.accessor('remediation', {
        header: 'Remediation',
        filterFn: 'weakEquals',
      }),
      /*
    columnHelper.accessor('mitigationPercent', {
      header: 'Percent mitigated',
      filterFn: 'weakEquals',
    }),
    columnHelper.accessor('impact', {
      header: 'Impact',
      filterFn: 'weakEquals',
    }),
    columnHelper.accessor('probability', {
      header: 'Probability',
      filterFn: 'weakEquals',
    }), */
    ] as TableColumnDefV8<IAssessmentVulnerabilityTableItem, unknown>[],
    [columnHelper, vulnerabilityStatusAsText],
  );

  const { store: tableStore } = useTableStoreV8(
    'assessmentVulnerabilities',
    {
      visibilityState: columnsToVisibilityState(columns),
      pageSize: 100,
    },
  );

  const tableState = useStore(tableStore);

  return (
    <Card>
      <Card.Header>
        Identified vulnerabilities
      </Card.Header>
      <Card.Body>
        <TableFromArray
          columnDefs={columns}
          data={vulnerabilities}
          state={tableState}
          disableColumnSelect
        />
      </Card.Body>
    </Card>
  );
};

export default AssessmentVulnerabilityTableCard;
