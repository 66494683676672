import React, { useState } from 'react';
import {
  Alert, Button, Card, Col, Form, OverlayTrigger, Row, Table, Tooltip,
} from 'react-bootstrap';
import { Icon } from '@ailibs/feather-react-ts';
import axios from 'axios';
import { toast } from 'react-toastify';
import { QueryClient } from '@tanstack/react-query';
import useModalContext from '../../contexts/ModalContext';
import { getOrFetchFromApi, useApiLoaderData } from '../../query/GenericQuery';
import RenderHtml from '../../components/RenderHtml';
import { IModule } from '../../types/AccessTypes';
import { IConsentVersion } from '../../types/AdminTypes';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { useModules } from '../../providers/ModuleProvider';

interface IIncreaseDialogProps {
  module:IModule,
  setValue: (value:unknown) => void,
  setOkDisabled: (disabled:boolean) => void
}

const IncreaseDialogContent = (props:IIncreaseDialogProps) => {
  const { module, setValue, setOkDisabled } = props;

  const [previewValue, setPreviewValue] = useState<string>('');

  return (
    <>
      <p>
        Are you sure you would like to increase the consent version for
        {' '}
        <em>
          {module.name}
        </em>
        .
      </p>
      <p>
        After the version has increased, an update consent button will be
        enabled for customers that have already consented.
      </p>
      <Alert variant="warning" className="p-2">
        Please note that this action does nothing except prompt users for a new
        consent to the module.

        The modules consent implementation has to be updated with new
        requirements before increasing the version here.
      </Alert>

      <Form.Label>
        What has changed?
      </Form.Label>
      <Row>
        <Col md={6} className="mb-3">
          <Form.Control
            as="textarea"
            rows={5}
            required
            onChange={(e) => {
              setValue(e.target.value);
              setPreviewValue(e.target.value);
              setOkDisabled(e.target.value.length < 1);
            }}
          />
        </Col>
        <Col md={6} className="mb-3">
          <div className="form-control">
            <RenderHtml>
              {previewValue as string}
            </RenderHtml>
          </div>
          <div className="muted small">Markdown preview</div>
        </Col>
      </Row>
    </>
  );
};

export const AdminConsentsPage:IComponentWithLoader<IConsentVersion[], undefined> = {
  loader: async (queryClient:QueryClient) => (
    getOrFetchFromApi<IConsentVersion[]>(
      queryClient,
      'module/admin/consent',
    )
  ),
  Component: () => {
    const { openPromiseConfirm } = useModalContext();
    const { getModuleByIdOrUndefined } = useModules();

    const {
      data: consentVersions,
      invalidate: invalidateConsentVersions,
    } = useApiLoaderData<IConsentVersion[], IConsentVersion[]>(
      'module/admin/consent',
      (loaderData) => loaderData,
    );

    const increase = async (module:IModule) => {
      const { confirmed, value } = await openPromiseConfirm({
        title: `Confirm ${module.name} consent version increase`,
        content: (setValue, setOkDisabled) => {
          setOkDisabled(true);
          return <IncreaseDialogContent module={module} setValue={setValue} setOkDisabled={setOkDisabled} />;
        },
        okText: 'Yes, increase',
        cancelText: 'No, do not increase',
        size: 'xl',
      });

      if (confirmed) {
        try {
          await axios.post(
            '/api/v1/module/admin/consent/bump',
        {
          moduleId: module.id,
          changeNotes: value,
        } as {
          moduleId: number,
          changeNotes: string
        },
          );

          invalidateConsentVersions();
          toast.success('Consent version was increased');
        } catch (err) {
        // Ignore, error should be displayed by WithAxios
        }
      }
    };

    return (
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>Latest consent versions</Card.Header>
            <Card.Body>
              { consentVersions?.length
                ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>Module</th>
                        <th>Latest version</th>
                        <th>Change notes</th>
                        <th className="text-end">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      { consentVersions
                        ? consentVersions.map((v) => (
                          <tr key={v.moduleId}>
                            <td>
                              {getModuleByIdOrUndefined(v.moduleId)?.name ?? v.moduleId}
                            </td>
                            <td>
                              {v.version}
                            </td>
                            <td>
                              <RenderHtml>
                                {v.changeNotes}
                              </RenderHtml>
                            </td>
                            <td className="text-end">
                              <OverlayTrigger
                                overlay={<Tooltip>Increase latest consent version</Tooltip>}
                              >
                                <Button
                                  variant="light"
                                  disabled={!getModuleByIdOrUndefined(v.moduleId)}
                                  onClick={async () => {
                                    const module = getModuleByIdOrUndefined(v.moduleId);
                                    return (module ? increase(module) : null);
                                  }}
                                >
                                  <Icon name="plus-circle" />
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </Table>
                )
                : 'No configured consent versions'}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  },
};

export default AdminConsentsPage;
