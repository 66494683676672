import { IUser, Role } from './AccessTypes';

export enum ActiveStatus {
  active = 'active',
  disabled = 'disabled',
  invited = 'invited',
  disabledAndHidden = 'disabledAndHidden'
}

export interface Tenant {
  id: string,
}

export interface ICustomerConsent {
  version:number,
  targetId:string
}

export interface IConsent {
  moduleId: number,
  customer?: ICustomerConsent,
  availableVersion:number,
  changeNotes:string,
  consentUrl:string
}

export interface AdminAccessLevel {
  module: number | null,
  role: Role | null,
}

export interface ICustomerEssentials {
  id: string,
  name: string,
  active: boolean
}

export interface Customer extends ICustomerEssentials {
  description: string,
}

export interface AdminCustomer extends Customer {
  active: boolean,
  status: ActiveStatus,
  tenantId: string|undefined,
  notificationEmails: string[],
  /**
   * A string representation of the notification emails for use in editor.
   * Each email is separated by a newline and is useful for editing in a
   * textarea. Field is not populated from the backend, this has to be done
   * in the client in components that use the property.
   */
  notificationEmailsAsString: string,
  /**
   * A string representation of the customer tenant Id's for use in editor.
   * Each tenant Id is separated by a newline and is useful for editing in a
   * textarea. Field is not populated from the backend, this has to be done
   * in the client in components that use the property.
   */
  tenantIdsAsString: string,
  canBeAdmin?: boolean,
  canAssociate: boolean
}

export interface AdminAccount extends IUser {
  status: ActiveStatus,
  customerId: string,
  accessLevel: AdminAccessLevel[],
}

export interface GroupRole {
  id: string,
  name: string,
  members: string[],
  moduleRoles: Record<number, Role>,
}

export interface IConsentVersion {
  version:string,
  moduleId:number,
  changeNotes:string
}
