import React, { ReactElement } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { NoSidebarTemplate } from '../AuthenticatedAppTemplate';

export const LoadingCard = ({ children }:{children:string|ReactElement|undefined}) => (
  <Card className="full transparent loading-card">
    <Card.Body>
      <div className="text-center">
        <Spinner animation="border" variant="info" />
        { children
          ? (
            <div className="text-white mt-3">
              {children}
              { typeof children === 'string' ? '...' : '' }
            </div>
          )
          : null }
      </div>
    </Card.Body>
  </Card>
);

export const LoadingTemplate = ({ children }:{children:string|ReactElement|undefined}) => (
  <NoSidebarTemplate>
    <LoadingCard>
      {children}
    </LoadingCard>
  </NoSidebarTemplate>
);
