import { useEffect, useRef, useState } from 'react';

export const useSyncedHeight = <TSource extends HTMLElement, TTarget extends HTMLElement, >() => {
  const sourceRef = useRef<TSource>(null);
  const targetRef = useRef<TTarget>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (sourceRef.current) {
        setHeight(sourceRef.current.offsetHeight);
      }
    };

    const interval = setInterval(() => {
      if (sourceRef.current?.offsetHeight !== height) {
        updateHeight();
      }
    }, 100);

    return () => clearInterval(interval);
  }, [height]);

  useEffect(() => {
    if (targetRef.current && height) {
      targetRef.current.style.height = `${height}px`;
    }
  }, [height]);

  return { sourceRef, targetRef };
};
