import { ISeverityComponents } from '../pages/vulnerabilities/Types';

export interface Property {
  property: string,
  value_from: string,
  value_to: string,
}

export interface ChangeLog {
  timestamp: string,
  comment: string,
  username: string,
  upn: string,
  properties: Property[],
}

export enum RiskResponse {
  Pending = 'Pending',
  Accept = 'Accept',
  Transfer = 'Transfer',
  Reduce = 'Reduce',
  Mitigate = 'Mitigate'
}

export interface RiskCategory {
  id: string,
  name: string | null,
  hasRisks?: boolean,
}

export enum RiskStatus {
  Open = 'open',
  Closed = 'closed'
}

// RiskDto in dotnet API
export interface IRisk extends ISeverityComponents {
  name: string;
  riskId: number;
  friendlyId?: string;
  customerId: string;
  created: Date;
  description?: string;
  status: RiskStatus;
  id: string;
  updated?: Date;
  hasVulnerabilities: boolean,

  // unmanaged properties
  owner?: string,
  category?: string,
  asset?: string,
  response?: RiskResponse,
  reason?: string,
  consequence?: string,
  solution?: string,
  moduleRef?: string,
}

export interface IRiskUpdate extends ISeverityComponents {
  id?: string,
  name?: string,
  description?: string,
  status?: RiskStatus,

  // unmanaged properties
  owner?: string,
  category?: string,
  asset?: string,
  response?: RiskResponse,
  reason?: string,
  consequence?: string,
  solution?: string
}

export interface RiskOverviewCategory {
  name: string,
  value: string,
}

export type GetRiskOverviewDTO = {
  [index: string]: { high: number, medium: number, low: number }
}
