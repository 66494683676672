import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { VulnerabilityTrendChart } from '../vulnerabilities/VulnerabilityTrendChart';
import { IVulnerabilityTrend, SecurityLevel } from '../vulnerabilities/Types';
import { useApi } from '../../query/GenericQuery';
import ROUTES from '../../routing/Routes';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';
import { ISnapshotMetricsOptions } from '../vulnerabilities/loader';

export const VulnerabilityTrendTile = () => {
  const { getCustomerSetting } = useAccount();
  const [moduleIds, setModuleIds] = useState<number[]>([]);

  const customerSecurityLevel = getCustomerSetting(Module.none, 'security-level', SecurityLevel.Advanced);
  const navigate = useNavigate();

  const {
    data,
    isLoading,
  } = useApi<IVulnerabilityTrend[], ISnapshotMetricsOptions>(
    'vulnerabilitySnapshots/trend',
    {
      granularity: 'logarithmic',
      maxItems: 20,
      sourceModules: moduleIds,
    },
  );

  return isLoading || !data
    ? <Spinner animation="border" />
    : (
      <VulnerabilityTrendChart
        height="278"
        trendItems={data}
        compact
        securityLevel={customerSecurityLevel}
        useModuleFilter
        onActiveModulesUpdated={setModuleIds}
        onItemSelected={(item) => {
          const searchParams = new URLSearchParams();
          searchParams.set('trend_id', `${item?.id}`);
          navigate({
            pathname: ROUTES.vulnerabilityHistory.uri,
            search: searchParams.toString(),
          });
        }}
      />
    );
};
