import React, { useCallback, useEffect } from 'react';
import ReactTableV8, { BaseReactTableV8Props, PageableColumnDefV8 } from './ReactTableV8';
import { PagedResult } from '../../types/PagedResult';
import PaginationV8, { IPaginationMutableState, usePageCache } from './PaginationV8';
import { ISorting } from './PagedResultFilter';
import { convertPageToNewPageSize } from '../../utils/Utils';

export type TableFromPageableProps<T, TQuery extends object, TData> =
BaseReactTableV8Props<T, PageableColumnDefV8<T, TData>> & {
  pagedResult: PagedResult<T>,
  isFiltered: boolean,
  filterValues: TQuery,
  resetFilters: () => void,
}

const emptyData:[] = [];

export const TableFromPageable = <T, TQuery extends object, TData, >({
  id,
  setPage,
  pageSize,
  pagedResult,
  isLoading,
  columnDefs,
  disableFilters,
  disableColumnSelect,
  onRowClick,
  rowProps,
  rowPropSelector,
  rowClassNameResolver,
  CustomToolbarElements,
  clickRowToToggleExpand,
  size,
  disablePagination,
  refresh,
  hover,
  state,
  emptyContent,
  className,
  alternatingRowColors,
  renderMarkdown,
  noMargin,
  sorting,
  setSorting,
  resetFilters,
  filterValues,
  isFiltered,
}: TableFromPageableProps<T, TQuery, TData> & {
  id:string,
  setPage: (newPage:IPaginationMutableState) => void,
  pageSize:number,
  isLoading?: boolean,
  disablePagination?: boolean,
  setSorting?:(sorting:ISorting[]) => void,
  sorting?:ISorting[],
}) => {
  const { pagination, setPage: setCachedPage } = usePageCache(id);
  const loadAndUpdatePage = useCallback((loadPage:IPaginationMutableState) => {
    const pageToSet = {
      ...loadPage,
      // When changing page size, we need to convert current page accordingly
      page: pagination.pageSize !== loadPage.pageSize
        ? convertPageToNewPageSize(
          pagination.pageSize,
          loadPage.pageSize,
          loadPage.page,
          pagedResult.rowCount,
        )
        : loadPage.page,
    };
    setPage(pageToSet);
    setCachedPage(pageToSet);
  }, [pagedResult.rowCount, pagination.pageSize, setCachedPage, setPage]);

  useEffect(() => {
    if (pagination.page !== pagedResult.currentPage) {
      loadAndUpdatePage(pagination);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPaginationChange = async (newPagination:IPaginationMutableState) => (
    loadAndUpdatePage(newPagination)
  );

  const hidePagination = disablePagination || !pagedResult?.pageCount;

  return (
    <>
      <div className={`${isLoading ? 'mask-area' : 'd-none'}`} />

      {!hidePagination ? (
        <PaginationV8
          isLoading={isLoading}
          disabled={isLoading}
          page={pagedResult?.currentPage}
          pageSize={pageSize}
          totalPages={pagedResult?.pageCount}
          totalItemCount={pagedResult.rowCount}
          onPaginationChange={onPaginationChange}
          className="mb-3 paginator-top"
        />
      ) : null }
      <ReactTableV8
        data={pagedResult?.items ?? emptyData as T[]}
        resetCustomFilters={resetFilters}
        customFilterValues={filterValues}
        isCustomFiltered={isFiltered}
        onTableInitialized={() => {}}
        columnDefs={columnDefs}
        disableFilters={disableFilters}
        disableColumnSelect={disableColumnSelect}
        onRowClick={onRowClick}
        rowProps={rowProps}
        rowPropSelector={rowPropSelector}
        rowClassNameResolver={rowClassNameResolver}
        CustomToolbarElements={CustomToolbarElements}
        clickRowToToggleExpand={clickRowToToggleExpand}
        size={size}
        refresh={refresh}
        hover={hover}
        state={state}
        emptyContent={emptyContent}
        className={className}
        alternatingRowColors={alternatingRowColors}
        renderMarkdown={renderMarkdown}
        noMargin={noMargin}
        setCustomSorting={setSorting}
        customSorting={sorting}
      />
      {!hidePagination ? (
        <PaginationV8
          isLoading={isLoading}
          disabled={isLoading}
          page={pagedResult?.currentPage}
          pageSize={pageSize}
          totalPages={pagedResult?.pageCount}
          totalItemCount={pagedResult.rowCount}
          onPaginationChange={onPaginationChange}
        />
      ) : null }
    </>
  );
};
