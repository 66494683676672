import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { IPageViewTelemetry } from '@microsoft/applicationinsights-web';
import { Footer, Sidebar, Topbar } from './layout';
import useGlobalStore from './stores/GlobalStore';
import { MultiTenantBar } from './components/MultiTenantBar';
import { ROUTES_OBJECT } from './routing/Routes';
import { useAppInsights } from './contexts/AppInsightsContext';
import { setCookie } from './utils/Utils';
import { ElementScrollRestoration } from './routing/ElementScrollRestoration';
import { useAccount } from './providers/AccountProvider';
import { AvailableCustomerDataProvider } from './providers/AvailableCustomerDataProvider';
import { MultiTenantSearchParamDetector } from './components/MultiTenantSearchParamDetector';

export const NoSidebarTemplate = ({
  children,
  light,
  nologo,
}: {
  children: React.ReactNode,
  logoSize?:{width:string, height:string}
  light?:boolean,
  nologo?:boolean,
}) => (
  <div className={`main no-sidebar${light ? '' : ' dark'}`}>
    { nologo !== true
      ? (
        <div className={`logo-l${light ? '' : ' dark'}`}>
          <h1>Ivolv Cybersecurity</h1>
        </div>
      )
      : null }
    {children}
    <Footer />
  </div>
);

export const AuthenticatedAppRouteTemplate = () => {
  const {
    toggleSidebar,
    sidebarVisible,
  } = useGlobalStore({ sidebarVisible: window.innerWidth > 767 });

  const location = useLocation();
  const {
    user, customer, canAssociate, primaryCustomer,
  } = useAccount();
  const appInsights = useAppInsights();

  // Set title from route
  const title = 'Ivolv';
  const titlePrefix = ROUTES_OBJECT.getTitle(location.pathname);
  document.title = titlePrefix ? `${titlePrefix} - ${title}` : title;

  const [cookieSet, setCookieSet] = useState(false);
  useEffect(() => {
    if (user && !cookieSet) {
      // Safari gives HTTP 400 Bad Request on cookie values with non ASCII
      // https://stackoverflow.com/a/1969339
      // https://catonmat.net/my-favorite-regex
      setCookie('user_upn', (user.externalId ?? '').replace(/[^ -~]/, '_'), 1);
      setCookie('user_name', (user.name ?? '').replace(/[^ -~]/, '_'), 1);
      setCookieSet(true);
    }
  }, [user, cookieSet]);

  useEffect(() => {
    appInsights.trackPageView({
      uri: location.pathname + location.search,
      properties: {
        path: location.pathname,
        search: location.search,
        user_upn: user.externalId,
        user_name: user.name,
        customer: customer?.name,
        route: ROUTES_OBJECT.getRouteFromLocation(location.pathname),
      },
    } as IPageViewTelemetry);
  });

  const handleUnload = useCallback((e:Event) => {
    // If user is currently in multi tenancy mode, show unload confirmation as navigating
    // would deselect multi tenant customer.
    if (customer.id !== primaryCustomer.id) {
      e.preventDefault();

      // For legacy browsers:
      // eslint-disable-next-line no-param-reassign
      e.returnValue = true;
    }
  }, [customer.id, primaryCustomer.id]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [handleUnload]);

  const scrollContainer = useRef(null);

  return (
    <MultiTenantSearchParamDetector>
      <AvailableCustomerDataProvider>
        <div className={`wrapper${canAssociate ? ' multitenant' : ''}${customer?.id === primaryCustomer?.id ? ' primary' : ''}`}>
          <MultiTenantBar />
          <Sidebar collapsed={sidebarVisible !== undefined && !sidebarVisible} />
          <div className="main">
            <Topbar openCloseSidebar={() => toggleSidebar(!sidebarVisible)} />
            <main ref={scrollContainer} className="content" id="main-content">
              <div className="container-fluid p-0">
                <Outlet />
              </div>
            </main>
            <ElementScrollRestoration targetId="main-content" />
          </div>
          <Footer />
        </div>
      </AvailableCustomerDataProvider>
    </MultiTenantSearchParamDetector>
  );
};
