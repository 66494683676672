import { createContext, useContext } from 'react';
import { ISettingsContext } from '../providers/SettingsProvider';

/**
 * The SettingsContext is used to control visibility of the SettingsModal.
 */
export const SettingsContext = createContext<ISettingsContext | undefined>(undefined);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('No SettingsContext found when calling useSettingsContext');
  }
  return context;
};

export default useSettingsContext;
