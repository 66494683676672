// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from 'axios';
import React, {
  useMemo, useEffect, useState, ReactElement, useCallback,
} from 'react';
import {
  Spinner, Card, Alert, Button,
} from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Module } from '../types/AccessTypes';
import { LoadingTemplate } from '../routing/LoadingTemplate';
import { NoSidebarTemplate } from '../AuthenticatedAppTemplate';
import { useInvalidateQueries } from '../query/GenericQuery';
import { startVulnerabilityScan } from '../components/ScanVulnerabilitiesButton';
import { useInvalidateAvailableCustomerData } from '../providers/AvailableCustomerDataProvider';
import { useModules } from '../providers/ModuleProvider';
import { useAccount } from '../providers/AccountProvider';

export const useUrlSearchParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

type WellKnownResponseErrors = 'unknown'|'access_denied';

interface IConsentResponse {
  status:'failed'|'success',
  targetId:string,
  errorCode?:WellKnownResponseErrors,
  errorDetails?:string,
}

interface IConsentPageState {
  completed: boolean,
  response?: IConsentResponse|undefined|null,
  canClose: boolean,
  closeBusy?: boolean,
  error?: string|ReactElement|undefined,
}

const getConsentResponseForModule = (module:Module, query:URLSearchParams) : IConsentResponse => {
  switch (module) {
  case Module.m365:
  case Module.m365Configure:
  case Module.entraId:
  {
    const tenantId = query.get('tenant');
    if (!tenantId) {
      throw new Error('Missing required tenant in return URL');
    }
    return {
      status: !query.get('error') && (query.get('admin_consent') ?? '').toLowerCase() === 'true' ? 'success' : 'failed',
      targetId: tenantId,
      errorCode: query.get('error') === 'access_denied' ? 'access_denied' : 'unknown',
      errorDetails: `${(query.get('error_description') ? query.get('error_description') : '')}${(query.get('error_subcode') ? `(subCode: ${query.get('error_subcode')})` : '')}`,
    };
  }
  default:
    throw new Error(`Module ${module} is not supported`);
  }
};

/**
 * Temporary page user is redirected to after a module consent has been processed.
 *
 * Based on the response, the component will set the correct consented version.
*/
export const ConsentReturnPage = () => {
  const navigate = useNavigate();
  const query = useUrlSearchParams();
  const { getModuleNameOrDefault } = useModules();
  const { id: moduleIdAsString } = useParams();
  const { user } = useAccount();

  const [state, setState] = useState<IConsentPageState>({
    completed: false,
    canClose: window.opener != null || window.history.length === 1,
  });

  const moduleId = moduleIdAsString ? parseInt(moduleIdAsString, 10) : NaN;
  const moduleName = getModuleNameOrDefault(moduleId);

  const invalidateConsent = useInvalidateQueries('consents');
  const invalidateAvailableData = useInvalidateAvailableCustomerData();

  const close = () => {
    if (window.opener != null || window.history.length === 1) {
      window.close();
    } else {
      navigate('/');
    }
  };

  const saveAndClose = async () => {
    if (!state.response?.targetId) {
      throw new Error('Response contains no target Id');
    }
    setState({
      ...state,
      closeBusy: true,
    });

    try {
      const { data } = await axios.post<{customerId:string}>(
        `/api/v1/consents/${moduleId}/${encodeURIComponent(state.response.targetId)}`,
      );
      const { customerId } = data;

      await invalidateConsent();
      await invalidateAvailableData();
      // Ignore start scan fails; this is most likely a missing permission.
      // Nightly scans will retry this if it fails now.
      try {
        await startVulnerabilityScan(moduleId, customerId, 60);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(`Unable to start scan: ${err}`);
      }
      return true;
    } finally {
      close();
    }
  };

  const processConsentResponse = useCallback(async () => {
    try {
      const response = getConsentResponseForModule(moduleId, query);
      state.response = response;
    } catch (err) {
      setState({
        ...state,
        completed: true,
        response: null,
        error: (
          <div>
            <div>
              We encountered an error while parsing the services response, and as a result,
              we cannot connect the
              {' '}
              <em>
                {moduleName}
              </em>
              {' '}
              module.
            </div>
            <div className="mt-3">
              Please attempt to connect again. If the issue persists, don&apos;t hesitate to get
              in touch with our support team at
              {' '}
              <a href="mailto:support@ivolv.no">support@ivolv.no</a>
              {' '}
              for assistance.
            </div>
          </div>
        ),
      });
      return;
    }

    if (state.response.status === 'failed') {
      if (state.response.errorCode === 'access_denied') {
        setState({
          ...state,
          completed: true,
          error: (
            <div>
              <div>
                The external service denied our request to connect, as the account used does not
                have necessary permissions.
              </div>
              <div className="mt-3">
                Please try again with another account, or ask an
                administrator to enable
                {' '}
                {moduleName}
                .
              </div>
            </div>
          ),
        });
      } else {
        setState({
          ...state,
          completed: true,
          error: (
            <div>
              We were unable to connect to the service as it responded with an unknown error response.
              <div className="mt-3">
                Please contact
                {' '}
                <a href="mailto:support@ivolv.no">support@ivolv.no</a>
                {' '}
                for assistance.
                { state.response.errorDetails
                  ? (
                    <div className="mt-2 mb-3">
                      Details:
                      {state.response.errorDetails}
                    </div>
                  )
                  : null }
              </div>
            </div>
          ),
        });
      }

      return;
    }

    if (state.response.targetId) {
      // Get customer for tenantId and make sure it is one of the customers
      // accessible by the logged in user.
      if (!user.associatedTenantIds.includes(state.response.targetId)) {
        setState({
          ...state,
          response: null,
          completed: true,
          error: (
            <div>
              It appears that you&apos;ve granted consent to tenant ID
              {' '}
              {state.response.targetId}
              , which is not accessible with your user account
              {' '}
              <em>{user.externalId}</em>
              .
              <div className="mt-3">
                Please try again with an Entra ID account that is in one of your accessible tenants,
                or contact
                {' '}
                <a href="mailto:support@ivolv.no">support@ivolv.no</a>
                {' '}
                for assistance.
                { state.response.errorDetails
                  ? (
                    <div className="mt-2 mb-3">
                      Details:
                      {state.response.errorDetails}
                    </div>
                  )
                  : null }
              </div>
            </div>
          ),
        });
      } else {
        setState({
          ...state,
          completed: true,
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId, moduleName, query]);

  useEffect(() => {
    processConsentResponse();
  }, [processConsentResponse]);

  return state.response === undefined
    ? (
      <LoadingTemplate>
        Processing service connection response
      </LoadingTemplate>
    )
    : (
      <NoSidebarTemplate>
        <Card className="card-sm">
          {
            state.response?.status === 'success'
              ? (
                <>
                  <Card.Header>
                    {moduleName}
                    {' '}
                    was connected
                  </Card.Header>
                  <Card.Body>
                    <p>
                      Thank you for connecting
                      {' '}
                      {moduleName}
                      .
                    </p>
                    <p>
                      Please save the connection by clicking the button below.
                    </p>
                    { moduleId === Module.m365 || moduleId === Module.m365
                      ? (
                        <Alert variant="info" className="p-3">
                          <span>
                            After saving there is one final step required for this module, which is to add the
                            Service Principal
                            {' '}
                            <code>IvolvCRSMReader</code>
                            {' '}
                            to the Azure AD Role
                            {' '}
                            <code>Global Reader</code>
                            . When this is done we&apos;ll start nighly security scans of the connected service.
                          </span>
                        </Alert>
                      )
                      : null }
                    <Button
                      onClick={async () => (state.response?.targetId ? saveAndClose() : close())}
                      disabled={state.closeBusy}
                      autoFocus
                    >
                      Save and
                      {' '}
                      { state.canClose ? 'close' : 'go to dashboard' }
                      { state.closeBusy
                        ? <Spinner size="sm" className="ms-2" />
                        : null }
                    </Button>
                  </Card.Body>
                </>
              )
              : (
                <>
                  <Card.Header>Error processing connection response</Card.Header>
                  <Card.Body>
                    <Alert variant="warning" className="p-3">
                      { state.error }
                    </Alert>

                    <Button onClick={close} disabled={state.closeBusy}>
                      { state.canClose ? 'Close' : 'Go to dashboard' }
                      { state.closeBusy
                        ? <Spinner size="sm" className="ms-2" />
                        : null }
                    </Button>
                  </Card.Body>
                </>
              )
          }
        </Card>
      </NoSidebarTemplate>
    );
};
