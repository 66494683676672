import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import axios, { AxiosError } from 'axios';
import { NoSidebarTemplate } from '../AuthenticatedAppTemplate';
import { useBearerTokenContext, useLogout } from './BearerTokenProvider';
import { useAutoLogin } from '../pages/signin/AutoLoginHook';
import { LoadingTemplate } from '../routing/LoadingTemplate';
import {
  AccessDTO,
  IAccess,
  IAccessLevel,
  IAccount, ICompany, Module, Role,
} from '../types/AccessTypes';
import { ICustomerEssentials } from '../types/AdminTypes';
import { SettingValue } from '../types/Types';
import { ErrorTemplate } from '../routing/ErrorTemplate';
import { RefreshTokenTemplate } from '../routing/Router';
import useSettingsContext from '../contexts/SettingsContext';

export interface IAccountDetails {
  user: IAccount,
  customer: ICompany,
  primaryCustomer: ICustomerEssentials,
  canAssociate: boolean,
  isAssociatedTo: ICustomerEssentials[]|undefined,
  customerSettings:Record<number, Record<string, SettingValue>>,
  getCustomerSetting:<T, >(module:Module, key:string, defaultValue:T) => T,
  hasAnyRole: boolean,
  hasModuleRole: (moduleId:number, requiredRole:Role) => boolean,
  hasAnyModuleRole: (moduleId:number) => boolean,
  customerHasModule: (moduleId:number) => boolean,
  refreshAccess:() => void,
  logout:() => void,
}

interface IAccountDetailsAndStatus {
  account:IAccountDetails|undefined,
  httpStatus:number,
  accountExists:boolean|null
}

interface IAccessDTOAndStatus {
  access?:AccessDTO|undefined,
  status:number,
  accountExists:boolean|null
}

const noAccount:IAccountDetailsAndStatus = {
  account: undefined,
  httpStatus: -1,
  accountExists: null,
};

const fetchAccount = async (token:string, activeCustomerId:string|undefined):Promise<IAccessDTOAndStatus> => {
  try {
    const { data: exists, status: existsHttpStatus } = await axios.get<boolean>('/api/v1/account/exists', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!exists) {
      return {
        access: undefined,
        status: existsHttpStatus,
        accountExists: false,
      };
    }

    try {
      const { data, status: accountHttpStatus } = await axios.get<AccessDTO>('/api/v1/account', {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(activeCustomerId ? { 'algiz-customer-id': activeCustomerId } : {}),
        },
      });
      return {
        access: data,
        status: accountHttpStatus,
        accountExists: true,
      };
    } catch (err) {
      const axiosErr = err as AxiosError;
      return {
        status: axiosErr.response?.status ?? -1,
        accountExists: exists,
      };
    }
  } catch (err) {
    const axiosErr = err as AxiosError;
    return {
      status: axiosErr.response?.status ?? -1,
      accountExists: axiosErr.response?.status === 403 ? true : null,
    };
  }
};

const createAccountAndStatusFromAccessDTO = (
  data:AccessDTO,
  status:number,
  refreshAccess:() => void,
  logout:() => void,
) => {
  const accessLevel = {} as IAccessLevel;
  const access = {} as IAccess;

  (Object.keys(data.modules)).forEach((moduleIdAsString) => {
    const moduleId = parseInt(moduleIdAsString, 10);
    accessLevel[moduleId] = data.modules[moduleId].role;
    access[moduleId] = data.modules[moduleId].role !== 'none';
  });

  const hasAnyModuleRole = (moduleId:number) => {
    const role = accessLevel[moduleId];
    return !!role && role !== 'none';
  };

  const hasModuleRole = (moduleId:number, requiredRole:Role) => {
    if (!data) {
      return false;
    }
    const role = accessLevel[moduleId];

    switch (requiredRole) {
    case 'readOwn':
      return role === 'readOwn' || role === 'read' || role === 'readWrite' || role === 'admin';
    case 'read':
      return role === 'read' || role === 'readWrite' || role === 'admin';
    case 'readWrite':
      return role === 'readWrite' || role === 'admin';
    case 'admin':
      return role === 'admin';
    default:
      return false;
    }
  };

  const customerHasModule = (moduleId:number) => (
    !!data?.customer.customerModules.find((m) => m.module.id === moduleId)
  );

  return {
    account: {
      user: data,
      customer: data?.customer,
      primaryCustomer: data?.primaryCustomer,
      canAssociate: data?.canAssociate,
      isAssociatedTo: data?.isAssociatedTo,
      customerSettings: data?.customerSettings,
      getCustomerSetting: <T, >(module:Module, key:string, defaultValue:T) => (
        (data.customerSettings && data.customerSettings[module] && data.customerSettings[module][key])
          ? data.customerSettings[module][key].value as T
          : defaultValue
      ),
      hasAnyRole: Object.values(access).includes(true) && Object.keys(accessLevel).length > 0,
      hasModuleRole,
      hasAnyModuleRole,
      customerHasModule,
      refreshAccess,
      logout,
    },
    httpStatus: status,
    accountExists: true,
  };
};

export const AccountContext = createContext<IAccountDetails|null>(null);

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('No AccountContext found when calling useAccount');
  }
  return context;
};

export const AccountProvider = ({ children, fallback }: { fallback: React.ReactNode, children: React.ReactNode }) => {
  const { tokenData } = useBearerTokenContext();
  const { isLoggingIn } = useAutoLogin();
  const [accountRefreshRequested, setAccountRefreshRequested] = useState(0);
  const [accountAndStatus, setAccountAndStatus] = useState<IAccountDetailsAndStatus>(noAccount);
  const { activeCustomerId } = useSettingsContext();

  const logout = useLogout();
  const { refresh: requestTokenRefresh } = useBearerTokenContext();

  // Method, and account fetch, is triggered twice in dev mode:
  // https://react.dev/reference/react/StrictMode#fixing-bugs-found-by-double-rendering-in-development
  useEffect(() => {
    setAccountAndStatus(noAccount);

    fetchAccount(tokenData.token, activeCustomerId)
      .then((value) => {
        const { access, status, accountExists } = value;
        setAccountAndStatus(
          access && accountExists
            ? createAccountAndStatusFromAccessDTO(
              access,
              status,
              () => {
                // Use negative values to indicate manually requested refresh
                setAccountRefreshRequested((accountRefreshRequested > 0 ? 0 : accountRefreshRequested) - 1);
              },
              logout,
            )
            : {
              account: undefined,
              httpStatus: status,
              accountExists,
            },
        );

        // If backend was down, retry fetching account at regular intervals
        if (status === 504) {
          const retryNum = accountRefreshRequested < 0 ? 0 : accountRefreshRequested;
          const timeout = Math.min(100 << retryNum, 30000);
          setTimeout(() => {
            // Use positive values to indicate retries in case of API 504.
            // We use the retry counter to generate an semi exponential backoff.
            setAccountRefreshRequested(retryNum + 1);
          }, timeout);
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountRefreshRequested, tokenData.token, activeCustomerId]);

  // We're logged in, clear any autologin remnants
  /*
  useEffect(() => {
    if (clearAutoLogin()) {
      // Remove the loading after a second, when the redirect have hopefully completed.
      // We do this to hide the unauthorized card when we get here after an auto login
      // before the autologin redirect has had time to take effect.
      setTimeout(() => {
        setHandlingAutoLogin(false);
      }, 1000);
    }
  }, [clearAutoLogin]);
*/
  if (isLoggingIn) {
    return (
      <LoadingTemplate>
        Handling response from identity provider
      </LoadingTemplate>
    );
  }

  const { account, httpStatus, accountExists } = accountAndStatus;

  if (accountExists === null) {
    return (
      <LoadingTemplate>
        Fetching account
      </LoadingTemplate>
    );
  }

  // If account does not exist, show the no account fallback
  if (!accountExists) {
    return fallback;
  }

  // Account exist, but we're unable to access it, most likely a not yet authorized account
  if (accountExists && !account) {
    return (
      <NoSidebarTemplate>
        <Card className="card-sm">
          <Card.Header>Unauthorized</Card.Header>
          <Card.Body>
            <Row>
              <Col md={12} className="mb-3">
                You are logged in as
                {' '}
                <code>
                  {tokenData.userName}
                </code>
                {' '}
                (tenantId:
                {' '}
                <code>
                  {tokenData.tenantId}
                </code>
                ), but your user is not yet activated for use.
                Please log out and retry with a different account.
              </Col>
              <Col md={12} className="mb-3">
                Due to our review process, if you just signed up it might take a day or two before
                your account is activated. In that case, you&apos;ll hear from us as soon as your
                account is ready for use.
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button onClick={logout}>
                  Log out
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </NoSidebarTemplate>
    );
  }

  if (httpStatus >= 500) {
    return <ErrorTemplate status={httpStatus} />;
  }

  if (httpStatus === 401) {
    // Trigger token refresh
    requestTokenRefresh();
    return <RefreshTokenTemplate />;
  }

  return (
    <AccountContext.Provider value={account ?? null}>
      { children }
    </AccountContext.Provider>
  );
};
