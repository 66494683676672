/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  Alert,
  Button, Col, Form, Row, Stack,
} from 'react-bootstrap';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import CommonModal from '../../common/modal/CommonModal';
import { ICustomerSettingsUser, IDomainUser } from './CustomerTypes';
import { IUser } from '../../types/AccessTypes';

interface IProps {
  show:boolean
  handleClose:(user:IDomainUser&{invite:boolean}|undefined) => Promise<void>,
  users:ICustomerSettingsUser[]
}

const AddAssignableUserModal = (props:IProps) => {
  const { show, handleClose } = props;

  const [upn, setUpn] = useState<string>('');
  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState<boolean>(false);
  const [invite, setInvite] = useState<boolean>(false);

  const internalHandleClose = (user:IDomainUser&{invite:boolean}|undefined) => {
    setUpn('');
    setInvite(false);
    setError(undefined);
    setSaving(false);
    handleClose(user);
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSaving(true);

    try {
      if (!upn) return;

      if (invite) {
        const { data: result } = await axios.post<{account:IUser, created:boolean}>(
          `/api/v1/accountInvite?externalId=${encodeURIComponent(upn)}`,
        );
        if (result.account) {
          setUpn('');
          internalHandleClose({
            upn: result.account.externalId,
            displayName: '',
            invite: true,
          });
        } else {
          toast.warning('Email address is not for a valid Microsoft Entra ID account. Please try a different address.', {
            toastId: 'add-error',
            updateId: 'add-error',
          });
        }
        return;
      }

      const { data: user } = await axios.get<IDomainUser>(
          `api/v1/module/customer/assignableUser?upn=${encodeURIComponent(upn)}`,
      );

      if (user) {
        try {
          await axios.post<ICustomerSettingsUser>('/api/v1/module/customer/account', user);
          setUpn('');
          internalHandleClose({
            ...user,
            invite: false,
          });
        } catch (err) {
          const axiosError = err as AxiosError;
          if (axiosError.response?.status === 409) {
            toast.warning('This user already exists.', {
              toastId: 'add-error',
              updateId: 'add-error',
            });
          } else {
            throw err;
          }
        }
      } else {
        setInvite(true);
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <CommonModal
      title="Add user"
      show={show}
      handleClose={() => internalHandleClose(undefined)}
      size="lg"
    >
      <Form onSubmit={submit}>
        <Row>
          <Col md={12} className="mb-3">
            <Form.Label>Email address (UPN):</Form.Label>
            <Form.Control
              value={upn}
              type="email"
              name="upn"
              autoFocus
              isInvalid={!!error}
              onChange={(e) => {
                if (e.target.validity) {
                  setError(e.target.validationMessage);
                } else {
                  setError(undefined);
                }
                setInvite(false);
                setUpn(e.target.value);
              }}
            />
            {error
              ? (
                <Alert variant="danger" className="mt-2 p-3">
                  {error}
                </Alert>
              )
              : invite
                ? (
                  <Alert variant="warning" className="mt-2 p-3">
                    No matching users were found for your search.
                    <br />
                    <br />
                    You may invite the user, but please ensure you have entered the
                    full email address. Additionally, the email must correspond to a
                    valid Microsoft Entra ID account.
                  </Alert>
                )
                : null }
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Stack direction="horizontal" gap={2}>
              { invite
                ? (
                  <Button variant="danger" type="submit" disabled={saving || !!error || !upn.length}>
                    Invite external user
                  </Button>
                )
                : (
                  <Button type="submit" disabled={saving || !!error || !upn.length}>
                    Add
                  </Button>
                ) }
              <Button
                type="button"
                variant="secondary"
                onClick={() => internalHandleClose(undefined)}
              >
                Cancel
              </Button>
            </Stack>
          </Col>
        </Row>
      </Form>
    </CommonModal>
  );
};

export default AddAssignableUserModal;
