import React, { useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import {
  Button, Col, Form, Row, Spinner, Stack,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { AdminCustomer } from '../../types/AdminTypes';
import {
  stringToArray, filterEmails,
} from '../../utils/StringUtils';
import { useInvalidateQueries } from '../../query/GenericQuery';
import CustomerModuleSelector from './CustomerModuleSelector';
import { ICustomerUpdateModule, IModule } from '../../types/AccessTypes';
import { useModules } from '../../providers/ModuleProvider';

/** INTERFACES */

interface CustomerFormProps {
  handleClose: (addedCustomer?: AdminCustomer) => void
  defaultName?: string
}

interface ICustomerAdd {
    name:string,
    description:string,
    shortname:string,
    notificationEmails: string[],
    modules: ICustomerUpdateModule[],
    tenantId:string
}

const isInvalidTenant = (tenantId:string|undefined) => (
  !!tenantId && !/^[0-9a-f]{8}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{4}\b-[0-9a-f]{12}$/i.test(tenantId)
);

/**
 * Form for adding customers.
 */
const AdminCustomerForm = ({ handleClose, defaultName }: CustomerFormProps) => {
  const invalidateCustomers = useInvalidateQueries('module/admin/customers');

  const { modules } = useModules();

  const addCustomerMutation = useMutation({
    mutationFn: async (customer:ICustomerAdd) => axios.post<AdminCustomer>(
      '/api/v1/module/admin/customers',
      { ...customer, active: true },
    ),
    onSuccess: (response) => {
      invalidateCustomers();
      toast.success('Customer was created');
      handleClose(response.data);
    },
  });

  const moduleMap = useMemo(() => {
    if (!modules) {
      return undefined;
    }
    const mModuleMap:Record<number, IModule> = {};
    modules.forEach((m) => { mModuleMap[m.id] = m; });
    return mModuleMap;
  }, [modules]);

  if (!moduleMap) {
    return <Spinner animation="border" />;
  }

  return (
    <Formik
      initialValues={{
        name: defaultName ?? '',
        description: '',
        shortname: '',
        modules: [],
        tenantId: '',
        notificationEmails: [],
      } as ICustomerAdd}
      enableReinitialize
      onSubmit={async (values) => addCustomerMutation.mutateAsync(values)}
    >
      {({
        values, setFieldValue, handleChange, handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={8} className="mb-3">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                autoComplete="off"
                required
                autoFocus
                name="name"
                value={values.name}
                onChange={handleChange}
              />
            </Col>
            <Col md={12} className="mb-3">
              <Form.Label>Description:</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Description"
                name="description"
                value={values.description}
                onChange={handleChange}
              />
            </Col>
            <Col md={6} className="mb-3">
              <Form.Label>Tenant ID:</Form.Label>
              <Form.Control
                type="text"
                name="tenantId"
                placeholder="Tenant ID"
                value={values.tenantId}
                isInvalid={isInvalidTenant(values.tenantId)}
                onChange={handleChange}
              />
            </Col>
            <Col md={6} className="mb-3">
              <Form.Label>Notification emails:</Form.Label>
              <Form.Control
                style={{ whiteSpace: 'pre-wrap', minHeight: 150 }}
                as="textarea"
                placeholder="Notification emails (one line per each)"
                name="notificationEmails"
                onChange={(e) => setFieldValue(
                  'notificationEmails',
                  stringToArray(e.target.value, filterEmails),
                )}
              />
            </Col>
            <Col md={12}>
              <CustomerModuleSelector
                customer={undefined}
                customerModules={values.modules}
                onChange={(updatedModules) => {
                  setFieldValue('modules', updatedModules);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Stack direction="horizontal" gap={2}>
                <Button type="submit">Add customer</Button>
                <Button variant="secondary" type="button" onClick={() => handleClose()}>Cancel</Button>
              </Stack>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default AdminCustomerForm;
