import { TilePosition } from './DashboardPage';

export const sortByTilePosition = (positionA:TilePosition|undefined, positionB:TilePosition|undefined) => {
  switch (positionA) {
  case 'top':
    switch (positionB) {
    case 'top':
      return 0;
    default:
      return -1;
    }
  case 'bottom':
    switch (positionB) {
    case 'bottom':
      return 0;
    default:
      return 1;
    }
  default:
    switch (positionB) {
    case 'top':
      return 1;
    case 'bottom':
      return -1;
    default:
      return 0;
    }
  }
};
