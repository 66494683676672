import React from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useModalContext from '../../contexts/ModalContext';
import { AssessmentStateManager } from './AssessmentStateManager';

interface IProps {
  stateManager: AssessmentStateManager
}

const AssessmentDiscardAssetButton = (props:IProps) => {
  const { stateManager } = props;

  const { openConfirmLegacy } = useModalContext();

  const { asset } = stateManager.getActive();

  return (
    <Button
      variant="danger"
      className="text-nowrap"
      onClick={() => {
        if (stateManager.assetHasVulnerabilities(asset)) {
          openConfirmLegacy(
            (confirmed) => {
              if (confirmed) {
                stateManager.discardAsset(asset);
                toast.success(`Asset '${asset.name ?? asset.friendlyId ?? asset.uniqueId}' and its assessment vulnerabilities was discarded`);
              }
            },
            'Discard asset?',
            'Discarding the asset will delete all answers and vulnerabilities identified for this asset.',
            'Discard asset',
            'Continue assessing asset',
          );
        } else {
          stateManager.discardAsset(asset);
          toast.success(`Asset '${asset.name ?? asset.friendlyId ?? asset.uniqueId}' had no vulnerabilities and the assessment was discarded`);
        }
      }}
    >
      Discard asset
    </Button>
  );
};

export default AssessmentDiscardAssetButton;
