/* eslint-disable max-len */
import { useCallback, useState } from 'react';
import {
  AccessorFn, createColumnHelper, DeepKeys, DeepValue, DisplayColumnDef, IdentifiedColumnDef,
} from '@tanstack/react-table';
import { IPaginationMutableState, usePageCache } from './PaginationV8';
import { ICustomFilter, PageableColumnDefV8, TableColumnDefV8 } from './ReactTableV8';
import { useModules } from '../../providers/ModuleProvider';
import { Module } from '../../types/AccessTypes';

export enum Direction {
  none = 0,
  asc = 1,
  desc = 2
}

export interface ISorting {
  property:string,
  direction:Direction,
}

const directionToSearchString = (direction:Direction) => {
  switch (direction) {
  case Direction.asc:
    return 'asc';
  case Direction.desc:
    return 'desc';
  case Direction.none:
  default:
    return '';
  }
};

const convertSortingToSortBy = (sorting:ISorting[]) => {
  if (!sorting.length) return null;
  let sortBy = '';
  sorting.forEach((s) => {
    if (s.direction === Direction.none) return;
    const direction = directionToSearchString(s.direction);
    sortBy += `${sortBy.length ? ',' : ''}${s.property}${direction ? ` ${direction}` : ''}`;
  });
  return sortBy;
};

export interface IPagedTableFilter<T> {
  pageableQuery:IPaginationMutableState&{sortBy?:string}&T,
  setPage: (newPaging:IPaginationMutableState) => void,
  setQuery: (newQuery:T) => void,
  setSorting: (newSorting:ISorting[]) => void,
  sorting:ISorting[],
  appendQuery: (newQuery:T) => void,
  isFiltered:boolean,
  reset: () => void,
}

export const useAllModuleOptions = () => {
  const { getModuleNameOrDefault } = useModules();
  const modules = Object.keys(Module)
    .map((stringId) => parseInt(stringId, 10))
    .filter((moduleId) => !Number.isNaN(moduleId) && moduleId > 0);

  modules.sort((a, b) => (
    (getModuleNameOrDefault(a) ?? 'none')
      .localeCompare(getModuleNameOrDefault(b) ?? 'none')
  ));

  return modules;
};

export const usePagedTableFilter = <T, > (
  id:string,
  emptyQuery:T,
  initialQuery?:T,
  initialSorting?:ISorting[],
  appendSortingFilters?:(sorting:ISorting[], query:T) => T | undefined | null,
):IPagedTableFilter<T> => {
  const [query, setQuery] = useState<T>({
    ...emptyQuery,
    ...initialQuery,
  });
  const { pagination, setPage } = usePageCache('vulnerabilities-page');

  const [sorting, setSorting] = useState<ISorting[]>(initialSorting ?? []);

  const appendQuery = useCallback((newQuery:T) => {
    setQuery({
      ...query,
      ...newQuery,
    });
    const newPage = {
      page: 1,
      pageSize: pagination.pageSize,
    };
    setPage(newPage);
  }, [pagination.pageSize, query, setPage]);

  const pageableQuery:IPaginationMutableState&{sortBy?:string}&T = {
    ...query,
    ...pagination,
    ...appendSortingFilters ? appendSortingFilters(sorting, query) : {},
  };

  const sortBy = convertSortingToSortBy(sorting);
  if (sortBy) pageableQuery.sortBy = sortBy;

  return {
    pageableQuery,
    setPage: (newPaging:IPaginationMutableState) => {
      if (newPaging.page === 0) {
        throw new Error(`Invalid page: ${newPaging.page}`);
      }
      setPage(newPaging);
    },
    setQuery,
    setSorting,
    sorting,
    appendQuery,
    isFiltered: JSON.stringify(emptyQuery) !== JSON.stringify(query),
    reset: () => {
      const newQuery = emptyQuery as T;
      setQuery(newQuery);
    },
  };
};

export const createPageableColumnHelper = <TData, >() => {
  const columnHelper = createColumnHelper<TData>();

  return {
    accessor: <
      TAccessor extends AccessorFn<TData> | DeepKeys<TData>,
      TValue extends TAccessor extends AccessorFn<TData, infer TReturn> ? TReturn : TAccessor extends DeepKeys<TData> ? DeepValue<TData, TAccessor> : never,
      TFilterValue,
    >(
      accessor: TAccessor,
      column: TAccessor extends AccessorFn<TData> ? DisplayColumnDef<TData, TValue> : IdentifiedColumnDef<TData, TValue> | TableColumnDefV8<TData, TValue>,
      filters?: ICustomFilter<TFilterValue>,
    ): PageableColumnDefV8<TData, TFilterValue> => {
      const columnDef = columnHelper.accessor(
        accessor,
        column,
      );

      const col:PageableColumnDefV8<TData, TFilterValue> = {
        ...columnDef as TAccessor & {id:string, accessorKey:string},
        customFilter: filters,
      };

      return col;
    },
    display: columnHelper.display,
    group: columnHelper.group,
  };
};
