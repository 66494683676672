import React, { useEffect } from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { toast, ToastContainer } from 'react-toastify';
import {
  Button, Col, Row, Table,
} from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';
import { CommonModal } from '../common/modal';
import { getStringDate } from '../utils/StringUtils';
import RenderHtml from './RenderHtml';

interface IToastMessage {
  content:string|undefined,
  id?:string,
  type:string,
  timestamp:Date
}

interface IToastState {
  messages:IToastMessage[]
  addMessage: (message:IToastMessage) => void,
  updateMessage: (message:IToastMessage) => void,
  clearMessages: () => void
}

const addAndTrimToSize = (
  messages:IToastMessage[],
  message:IToastMessage,
  maxLength:number,
) => {
  messages.unshift(message);
  if (messages.length > maxLength) {
    messages.pop();
  }
};

/**
 * The store used for persisting the last 12 toast messages to storage.
 */
export const useToastStore = create<IToastState>()(
  persist(
    (set) => ({
      messages: [] as IToastMessage[],
      addMessage: (message:IToastMessage) => {
        set((state) => {
          addAndTrimToSize(state.messages, message, 12);
          return { ...state };
        });
      },
      updateMessage: (message:IToastMessage) => {
        set((state) => {
          let i = 0;
          for (; i < state.messages.length; i += 1) {
            if (state.messages[i].id === message.id) {
              break;
            }
          }

          if (i < state.messages.length) {
            const existingMessage = state.messages[i];
            if (existingMessage.content === message.content) {
              return state;
            }
          }

          addAndTrimToSize(state.messages, message, 12);
          return { ...state };
        });
      },
      clearMessages: () => {
        set(() => ({ messages: [] } as Partial<IToastState>));
      },
    }),
    {
      name: 'toast-messages',
    },
  ),
);

/**
 * Custom toast container that saves toast messages to the toast store for
 * display in `ToastLogModal`.
 */
export const AlgizToastContainer = () => {
  const store = useToastStore();

  useEffect(() => {
    const unsubscribe = toast.onChange((e) => {
      if (e.type && e.content) {
        switch (e.status) {
        case 'added':
          store.addMessage({
            content: ReactDOMServer.renderToString(e.content as React.ReactElement),
            type: e.type,
            timestamp: new Date(),
          });
          break;
        case 'updated':
          store.updateMessage({
            content: ReactDOMServer.renderToString(e.content as React.ReactElement),
            type: e.type,
            timestamp: new Date(),
          });
          break;
        default:
          // N/A
          break;
        }
      }
    });

    return unsubscribe;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ToastContainer
      hideProgressBar
      position="bottom-right"
      autoClose={15000}
    />
  );
};

interface IProps {
  visible:boolean,
  setVisibility: (visible:boolean) => void
}

/**
 * Modal for displaying the content of the users toast messages.
 */
export const ToastLogModal = (props:IProps) => {
  const { visible, setVisibility } = props;
  const store = useToastStore();

  const getMessageClassName = (message:IToastMessage) => {
    switch (message.type) {
    case 'error':
      return 'alert-danger';
    case 'info':
      return 'alert-info';
    case 'warning':
      return 'alert-warning';
    case 'success':
      return 'alert-success';
    default:
      return 'alert-info';
    }
  };

  return (
    <CommonModal
      show={visible}
      handleClose={() => setVisibility(false)}
      title="Message log"
    >
      <Row>
        <Col md={12}>
          { store.messages?.length > 0
            ? (
              <Table>
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  { store.messages.map((m) => (
                    <tr className={getMessageClassName(m)} key={new Date(m.timestamp).getTime()}>
                      <td>{getStringDate(m.timestamp)}</td>
                      <td><RenderHtml>{m.content}</RenderHtml></td>
                    </tr>
                  )) }
                </tbody>
              </Table>
            )
            : <div className="mb-3">No messages are logged since last reset</div>}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Button variant="primary" onClick={() => store.clearMessages()}>
            Clear
          </Button>
        </Col>
      </Row>
    </CommonModal>
  );
};
