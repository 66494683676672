import React from 'react';
import {
  Alert,
  Button, Col, Form, Row, Spinner, Stack,
} from 'react-bootstrap';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import SecurityLevelPicker from '../../components/SecurityLevelPicker';
import { SecurityLevel } from '../vulnerabilities/Types';
import { ICustomerSettingsCustomer } from './CustomerTypes';
import { useInvalidateQueries } from '../../query/GenericQuery';
import { useTranslation } from '../../providers/TranslationProvider';
import { useAccount } from '../../providers/AccountProvider';
import { Module } from '../../types/AccessTypes';

interface IProps {
  customer:ICustomerSettingsCustomer,
}

interface ICustomerUpdate {
  name:string,
  notificationEmails:string[],
  securityLevel:SecurityLevel,
}

const CustomerDetailsTab = (props:IProps) => {
  const { customer } = props;

  const { refreshAccess, getCustomerSetting } = useAccount();
  const i18n = useTranslation();

  const invalidateCustomer = useInvalidateQueries('module/customer');
  const customerSecurityLevel = getCustomerSetting(Module.none, 'security-level', SecurityLevel.Unknown);
  const saveMutation = useMutation({
    mutationFn: async (data:ICustomerUpdate) => axios.put('/api/v1/module/customer', data),
    onSuccess: async () => {
      toast.success('Customer was saved', {
        toastId: 'customer-save',
        updateId: 'customer-save',
      });
      await invalidateCustomer();
      await refreshAccess();
    },
  });

  if (!customer) {
    return <Spinner animation="border" />;
  }

  const initialValues = {
    ...customer,
    securityLevel: customerSecurityLevel,
  } as ICustomerUpdate;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, helpers) => {
        await saveMutation.mutateAsync(values);
        helpers.resetForm({ values });
      }}
    >
      {({
        values, handleSubmit, handleChange, setFieldValue, dirty, handleReset,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm="12">
              <Row>
                <Col md={8} className="mb-3">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    value={values.name}
                    name="name"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                  />
                </Col>
                <Col md={4} className="mb-3">
                  <div className="form-label">Targeted security level:</div>
                  <SecurityLevelPicker
                    securityLevel={values.securityLevel}
                    showUnknown
                    onChange={(securityLevel) => setFieldValue('securityLevel', securityLevel)}
                  />
                  { values.securityLevel !== SecurityLevel.Unknown
                    ? (
                      <Alert className="p-3 mt-3">
                        { i18n.getString(`security-level.${values.securityLevel}.description`) }
                      </Alert>
                    )
                    : null }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Stack direction="horizontal" gap={2}>
                <Button type="submit" disabled={!dirty}>Save</Button>
                <Button type="reset" variant="secondary" disabled={!dirty} onClick={handleReset}>Undo</Button>
              </Stack>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerDetailsTab;
