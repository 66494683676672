import React, { useState } from 'react';
import {
  Spinner, Col, Row, Table, Badge, Stack, Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import CustomerUserDetailsModal from './CustomerUserDetailsModal';
import { useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { ICustomerSettingsUser, IDomainUser } from './CustomerTypes';
import AddAssignableUserModal from './AddAssignableUserModal';
import { Module } from '../../types/AccessTypes';
import { useAccount } from '../../providers/AccountProvider';

/**
 * User management tab
 *
 * Rendered by CustomerSettingsPage
 */
const CustomerUserManagementTab = () => {
  const { hasModuleRole } = useAccount();

  const [selected, setSelected] = useState<ICustomerSettingsUser|undefined>();
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleOpen = (user: ICustomerSettingsUser) => {
    setSelected(user);
  };
  const handleClose = () => setSelected(undefined);

  const {
    data: pagedUsers,
    invalidate: invalidateUsers,
  } = useApi<PagedResult<ICustomerSettingsUser>>('module/customer/accounts');

  const getRowClassNames = (user:ICustomerSettingsUser) => {
    const classes = [];
    if (!user.active) {
      classes.push('text-muted');
    }
    return classes.join(' ');
  };

  const addUser = async (user:IDomainUser&{invite:boolean}|undefined) => {
    setShowAddUserModal(false);
    if (!user) return;
    if (user.invite) {
      toast.success(`${user.upn} was invited`, {
        toastId: 'user-add-toast',
        updateId: 'user-add-toast',
      });
    } else {
      toast.success(`${user.displayName} was added`, {
        toastId: 'user-add-toast',
        updateId: 'user-add-toast',
      });
    }
    await invalidateUsers();
  };

  return !pagedUsers
    ? <Spinner animation="border" />
    : (
      <>
        <Row>
          <Col md={12} className="overflow-auto">
            <Table size="sm" hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>UPN</th>
                  <th>Enabled</th>
                </tr>
              </thead>
              <tbody>
                {pagedUsers.items.map((user) => (
                  <tr
                    key={user.id}
                    role="button"
                    className={getRowClassNames(user)}
                    onClick={() => handleOpen(user)}
                  >
                    <td>{user.name}</td>
                    <td>{user.externalId}</td>
                    <td>
                      { user.active
                        ? <Badge bg="success">Yes</Badge>
                        : <Badge bg="danger">No</Badge> }
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Stack direction="horizontal" gap={2}>
              <Button
                disabled={!hasModuleRole(Module.customerAdmin, 'readWrite')}
                onClick={() => setShowAddUserModal(true)}
              >
                Add account
              </Button>
            </Stack>
            { selected
              ? <CustomerUserDetailsModal disabled={false} user={selected} show handleClose={handleClose} />
              : undefined }
          </Col>
        </Row>
        <AddAssignableUserModal
          show={showAddUserModal}
          handleClose={async (u) => addUser(u)}
          users={pagedUsers.items}
        />
      </>
    );
};

export default CustomerUserManagementTab;
