import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { Spinner } from 'react-bootstrap';
import { IRisk, RiskStatus } from '../../types/RiskTypes';
import DefaultApexOptions from '../../utils/DefaultApexOptions';
import {
  severityAsHexColor, severityAsIndex, asSeverity,
} from '../vulnerabilities/Utils';
import { useApi } from '../../query/GenericQuery';
import { PagedResult } from '../../types/PagedResult';
import { useTranslation } from '../../providers/TranslationProvider';
import { Severity } from '../vulnerabilities/Types';

interface IProps {
  risks?: IRisk[],
  onClick?: (selectedSeverities:Severity[]) => void,
  activeSeverity?: Severity
}

const getSeverityFromSeriesIndex = (index:number) => {
  switch (index) {
  case 0:
    return Severity.Low;
  case 1:
    return Severity.Medium;
  case 2:
    return Severity.High;
  default:
    throw new Error(`Invalid series index: ${index}`);
  }
};

const getSeriesIndexFromRiskSeverity = (severity:Severity) => {
  switch (severity) {
  case Severity.Low:
    return 0;
  case Severity.Medium:
    return 1;
  case Severity.High:
    return 2;
  default:
    return undefined;
  }
};

const RiskOverviewChart = (props:IProps) => {
  const {
    risks: inputRisks, onClick, activeSeverity,
  } = props;

  const i18n = useTranslation();
  const { data: fetchRiskPages } = useApi<PagedResult<IRisk>>(
    !inputRisks && 'risks',
  );

  const risks = inputRisks ?? fetchRiskPages?.items;

  const series = useMemo(() => {
    const s = [0, 0, 0];
    if (!risks) return s;
    risks.forEach((risk) => {
      if (risk.status === RiskStatus.Closed) return;
      const severity = severityAsIndex(asSeverity(risk));
      if (s[severity]) s[severity] += 1;
      else {
        s[severity] = 1;
      }
    });
    return s;
  }, [risks]);

  const options = useMemo(() => {
    const opts = {
      chart: {
        ...DefaultApexOptions.chart,
        // Since we need to re-render the chart to deselect the active severity
        // and we have to rely on the animationEnd event to select the active
        // severity, we need to have animation enabled, but set it to a short
        // duration for the selection to work. There is no good way to
        // pre-select an item in axios, and this is becoming quite a hack -
        // but it seems to work well enough with these constraints.
        animations: {
          enabled: true,
          speed: 0,
        },
        toolbar: {
          tools: {
            download: true,
          },
        },
        events: {
          // https://github.com/apexcharts/apexcharts.js/issues/16
          animationEnd(chart) {
            if (activeSeverity) {
              chart.ctx.toggleDataPointSelection(getSeriesIndexFromRiskSeverity(activeSeverity));
            }
          },
        },
      },
      states: {
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
        },
      },
      fill: {
        opacity: 1,
      },
      colors: [
        severityAsHexColor(Severity.Low),
        severityAsHexColor(Severity.Medium),
        severityAsHexColor(Severity.High),
      ],
      legend: {
        position: 'top',
        horizontalAlign: 'left',
      },
      labels: [
        i18n.getString('significance.low'),
        i18n.getString('significance.medium'),
        i18n.getString('significance.high'),
      ],
    } as ApexCharts.ApexOptions;

    if (onClick) {
      if (opts.chart) {
        if (!opts.chart.events) {
          opts.chart.events = {};
        }
        opts.chart.events.dataPointSelection = (
          a,
          b,
          selection:{dataPointIndex:number, selectedDataPoints:number[][]},
        ) => {
          const selectedDataPoint = selection.selectedDataPoints[0] as number[];
          // Toggling between having and not having an active severity, triggers
          // a re-render (see key in Chart). If a re-render is happening while
          // we're handling the dataPointSelection an error is thrown from
          // axios. Doing this in a timeout causes the onClick to be triggered
          // after this handler is done, and fixes the issue.
          setTimeout(() => {
            if (selectedDataPoint?.length) {
              onClick([getSeverityFromSeriesIndex(selectedDataPoint[0])]);
            } else {
              onClick([]);
            }
          }, 100);
        };
      }

      if (opts.states?.active?.filter) {
        opts.states.active.filter.type = 'darken';
      }

      if (opts.plotOptions?.pie) {
        opts.plotOptions.pie.expandOnClick = true;
      }
    }

    return opts;
  }, [activeSeverity, i18n, onClick]);

  return risks
    ? (
      <Chart
        options={options}
        series={series}
        type="pie"
        height="300"
        key={activeSeverity ? 'true' : 'false'}
      />
    )
    : <Spinner animation="border" />;
};

export default RiskOverviewChart;
