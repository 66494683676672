import { useState } from 'react';

/** Get generic value from the session storage */
export function getGenericValueFromSessionStorage<T>(id: string) : T | undefined {
  const item = sessionStorage.getItem(id);
  if (item) return JSON.parse(item);
  return undefined;
}

/** Set generic value in the session storage */
export function saveGenericValueToSessionStorage<T>(id: string, value: T) {
  sessionStorage.setItem(id, JSON.stringify(value));
}

/** Get generic value from the local storage */
export function getGenericValueFromLocalStorage<T>(id: string) : T | undefined {
  const item = localStorage.getItem(id);
  if (item) return JSON.parse(item);
  return undefined;
}

/** Set generic value in the local storage */
export function saveGenericValueToLocalStorage<T>(id: string, value: T) {
  localStorage.setItem(id, JSON.stringify(value));
}

export const useLocalStorage = <T>(key:string, defaultValue?:T) => {
  const [value, setValue] = useState(getGenericValueFromLocalStorage<T>(key) ?? defaultValue);

  return {
    value,
    setter: (v:T) => {
      saveGenericValueToLocalStorage(key, v);
      setValue(v);
    },
  };
};
