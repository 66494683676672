const DefaultApexOptions: ApexCharts.ApexOptions = {
  chart: {
    animations: {
      enabled: true,
      speed: 100,
      animateGradually: {
        enabled: false,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 100,
      },
    },
  },
};

export default DefaultApexOptions;
