import React, { useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNewModalContext } from '../providers/NewModalProvider';
import { TermsAndConditions } from '../pages/signin/TermsAndConditions';

export const useShowTerms = () => {
  const { pushModal } = useNewModalContext();

  return useCallback(async () => {
    await pushModal({
      title: 'Bruksvilkår for Ivolv Cybersecurity Platform',
      size: 'lg',
      ModalBodyAndFooter: ({ close }) => (
        <>
          <Modal.Body>
            <TermsAndConditions />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={async () => { close(true, false); }}>
              Close
            </Button>
          </Modal.Footer>
        </>
      ),
    });
  }, [pushModal]);
};
