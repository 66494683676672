import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { createColumnHelper } from '@tanstack/react-table';
import { useStore } from 'zustand';
import { ICustomerEssentials } from '../../types/AdminTypes';
import { EntityType } from '../../types/EntityTypes';
import { useApi } from '../../query/GenericQuery';
import { TableColumnDefV8 } from '../../common/table/ReactTableV8';
import { Setting } from '../../types/Types';
import { columnsToVisibilityState, useTableStoreV8 } from '../../common/table/TableStoreV8';
import { useModules } from '../../providers/ModuleProvider';
import { TableFromArray } from '../../common/table/TableFromArray';

export const AdminCustomerSettingsTab = ({ customer }:{customer:ICustomerEssentials}) => {
  const { data: settings } = useApi<Setting[]>(
    `settings/${EntityType.Customer}/${encodeURIComponent(customer.id)}`,
  );

  const { getModuleNameOrDefault } = useModules();

  const columnHelper = useMemo(() => createColumnHelper<Setting>(), []);

  const columns = useMemo<TableColumnDefV8<Setting, unknown>[]>(
    () => ([
      columnHelper.accessor('module', {
        header: 'Module',
        filterFn: 'weakEquals',
        cell: ({ getValue }) => {
          const moduleId = getValue() ?? 0;

          return moduleId === 0
            ? ''
            : getModuleNameOrDefault(moduleId);
        },
        sortingFn: (rowA, rowB, columnId) => {
          const moduleA = rowA.getValue(columnId) as number;
          const moduleB = rowB.getValue(columnId) as number;
          if (moduleA === 0 && moduleB === 0) {
            return 0;
          }
          if (moduleA === 0) {
            return -1;
          }
          if (moduleB === 0) {
            return 1;
          }
          const nameA = getModuleNameOrDefault(moduleA);
          const nameB = getModuleNameOrDefault(moduleB);
          if (nameA === nameB) return 0;
          return (nameA ?? '') < (nameB ?? '') ? -1 : 1;
        },
      }),
      columnHelper.accessor('key', {
        header: 'Key',
        filterFn: 'weakEquals',
        cell: ({ getValue }) => (
          <span className="font-monospace small">
            { getValue() }
          </span>
        ),
      }),
      columnHelper.accessor('value', {
        header: 'Value',
        filterFn: 'weakEquals',
        cell: ({ getValue }) => (
          <span className="font-monospace small">
            { getValue() }
          </span>
        ),
      }),
    ] as TableColumnDefV8<Setting, unknown>[]),
    [columnHelper, getModuleNameOrDefault],
  );

  const { store: tableStore } = useTableStoreV8(
    `customerSettings${customer ? `-${customer.id}` : ''}`,
    {
      visibilityState: columnsToVisibilityState(columns),
      pageSize: 50,
    },
  );

  const tableState = useStore(tableStore);

  if (!settings) return <Spinner animation="border" />;

  return (
    <TableFromArray
      state={tableState}
      columnDefs={columns}
      data={Object.values(settings)}
      defaultSorting={[{
        id: 'module',
        desc: false,
      },
      {
        id: 'key',
        desc: false,
      }]}
    />
  );
};
