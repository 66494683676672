import React, { useState } from 'react';
import {
  Button, Col, FormLabel, Row, Stack,
} from 'react-bootstrap';

import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import {
  IRisk, RiskStatus, IRiskUpdate, RiskResponse,
} from '../../types/RiskTypes';
import { useInvalidateQueries } from '../../query/GenericQuery';
import { AllSignificances, Significance } from '../vulnerabilities/Types';
import { useGetSignificanceAsText } from '../../utils/TranslationUtils';

interface RiskFormProps {
  risk: IRisk | undefined,
  handleClose: () => void,
}

/**
 * React component (using hooks) for showing details about a given risk.
 *
 * This form is also used for updating an existing risk or creating a new risk.
 */
export const RiskForm = (props: RiskFormProps) => {
  const { handleClose, risk } = props;
  const [saving, setSaving] = useState(false);

  const significanceAsText = useGetSignificanceAsText();

  const invalidateRisks = useInvalidateQueries('risks');

  const addUpdateRisk = useMutation({
    mutationFn: (values: IRiskUpdate) => axios({
      method: (values.id ? 'PUT' : 'POST'),
      url: `/api/v1/risks${values.id ? `/${values.id}` : ''}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: values,
    }),
    onSuccess: (_, values) => {
      toast.success((values.id ? 'Risk updated' : 'Risk created'));

      if (values.id) {
        handleClose();
      } else {
        handleClose();
      }
    },
    onSettled: () => {
      setSaving(false);
    },
  });

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="flex-fill w-100">
          <div className="card-body px-2 py-2">
            <Formik
              initialValues={risk ?? {
                name: '',
                id: undefined,
                probability: Significance.None,
                impact: Significance.None,
                description: '',
                status: RiskStatus.Open,
                response: RiskResponse.Pending,
                owner: '',
                reason: '',
                category: '',
                asset: '',
                consequence: '',
                solution: '',
              } as IRiskUpdate}
              validationSchema={Yup.object({
                name: Yup.string().required('Please provide a name'),
                probability: Yup.string().oneOf(AllSignificances),
                impact: Yup.string().oneOf(AllSignificances),
                description: Yup.string(),
                status: Yup.string().required('Please select a status'),
                response: Yup.string().required('Please select a response'),
                owner: Yup.string(),
                reason: Yup.string(),
                category: Yup.string().max(255),
                asset: Yup.string().max(255),
                consequence: Yup.string(),
                solution: Yup.string(),
              })}
              validateOnChange
              validateOnBlur
              onSubmit={async (values) => {
                setSaving(true);
                await addUpdateRisk.mutateAsync(values);
                setSaving(false);
                invalidateRisks();
              }}
            >
              {(formik) => (
                <Form>
                  <Row>
                    <Col md={8} className="mb-3">
                      <FormLabel>
                        Name:
                        <span className="error">*</span>
                      </FormLabel>
                      <Field name="name" type="text" className="form-control" />
                      <div className="error">
                        <ErrorMessage name="name" />
                      </div>
                    </Col>
                    <Col md={4} className="mb-3">
                      {formik.values.id && (
                        <>
                          <FormLabel>
                            Status:
                            <span className="error">*</span>
                          </FormLabel>
                          <Field name="status" as="select" className="form-select">
                            <option value="">Select status</option>
                            <option value={RiskStatus.Open}>Open</option>
                            <option value={RiskStatus.Closed}>Closed</option>
                          </Field>
                          <div className="error">
                            <ErrorMessage name="status" />
                          </div>
                        </>
                      )}
                    </Col>
                    <Col md={12} className="mb-3">
                      <FormLabel>
                        Description:
                      </FormLabel>
                      <Field name="description" as="textarea" className="form-control" />
                      <div className="error">
                        <ErrorMessage name="description" className="error" />
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <FormLabel>
                        Owner:
                      </FormLabel>
                      <Field name="owner" className="form-control" />
                      <div className="error">
                        <ErrorMessage name="owner" className="error" />
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <FormLabel>
                        Response:
                        <span className="error">*</span>
                      </FormLabel>
                      <Field name="response" as="select" className="form-select">
                        <option value="">Select response</option>
                        <option value={RiskResponse.Pending}>Pending</option>
                        <option value={RiskResponse.Accept}>Accept</option>
                        <option value={RiskResponse.Reduce}>Reduce</option>
                        <option value={RiskResponse.Transfer}>Transfer</option>
                        <option value={RiskResponse.Mitigate}>Mitigate</option>
                      </Field>
                      <div className="error">
                        <ErrorMessage name="response" className="error" />
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <FormLabel>
                        Reason:
                      </FormLabel>
                      <Field name="reason" as="textarea" className="form-control" />
                      <div className="error">
                        <ErrorMessage name="reason" className="error" />
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <FormLabel>
                        Consequence:
                      </FormLabel>
                      <Field name="consequence" as="textarea" className="form-control" />
                      <div className="error">
                        <ErrorMessage name="consequence" className="error" />
                      </div>
                    </Col>
                    <Col md={12} className="mb-3">
                      <FormLabel>
                        Solution:
                      </FormLabel>
                      <Field name="solution" as="textarea" className="form-control" />
                      <div className="error">
                        <ErrorMessage name="solution" className="error" />
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <FormLabel>
                        Category:
                      </FormLabel>
                      <Field name="category" className="form-control" />
                    </Col>
                    <Col md={6} className="mb-3">
                      <FormLabel>
                        Asset:
                      </FormLabel>
                      <Field name="asset" className="form-control" />
                    </Col>
                    <Col md={6} className="mb-3">
                      <FormLabel>
                        Probability:
                        <span className="error">*</span>
                      </FormLabel>
                      <Field name="probability" as="select" className="form-select">
                        <option>None</option>
                        { AllSignificances.map((s) => (
                          <option key={s} value={s}>{significanceAsText(s)}</option>
                        ))}
                      </Field>
                      <div className="error">
                        <ErrorMessage name="probability" />
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <FormLabel>
                        Impact:
                        <span className="error">*</span>
                      </FormLabel>
                      <Field name="impact" as="select" className="form-select">
                        <option>None</option>
                        { AllSignificances.map((s) => (
                          <option key={s} value={s}>{significanceAsText(s)}</option>
                        ))}
                      </Field>
                      <div className="error">
                        <ErrorMessage name="impact" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-3">
                      <div className="float-end error">* mandatory fields</div>
                      <Stack direction="horizontal" gap={2}>
                        <Button variant="primary" type="submit" disabled={saving}>Save</Button>
                        <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                      </Stack>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskForm;
