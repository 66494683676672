import React from 'react';
import { Badge } from 'react-bootstrap';
import { ActiveStatus } from '../types/AdminTypes';
import { useGetActiveStatusAsText } from '../utils/TranslationUtils';

const getBadgeBg = (status:ActiveStatus) => {
  switch (status) {
  case ActiveStatus.active:
    return 'success';
  case ActiveStatus.disabled:
  case ActiveStatus.disabledAndHidden:
    return 'danger';
  case ActiveStatus.invited:
    return 'warning';
  default:
    return 'secondary';
  }
};

export const ActiveStatusBadge = ({
  status,
  className,
}:{
  status:ActiveStatus,
  className?:string
}) => {
  const activeStatusAsText = useGetActiveStatusAsText();

  return (
    <Badge
      bg={getBadgeBg(status)}
      className={className}
    >
      {activeStatusAsText(status)}
    </Badge>
  );
};
