import React, { useEffect } from 'react';
import {
  ErrorResponse,
  Outlet, createBrowserRouter, isRouteErrorResponse, useRouteError,
} from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import ROUTES from './Routes';
import {
  AdminAssessmentsPage, AdminJobsPage, AssessmentPage, AssessmentSaveConfirmPage, AssetsPage,
  ControlPage, VulnerabilitySummaryPage, VulnerabilityPage, ConsentReturnPage, CustomerSettingsPage,
  AdminModulesPage, AdminConsentsPage, AdminToolsPage, AdminCustomersPage,
  AssetsDetailPage, RiskDetailPage, RiskPage, DashboardPage,
} from '../pages';
import {
  AuthenticatedAppRouteTemplate, NoSidebarTemplate,
} from '../AuthenticatedAppTemplate';
import MultiTenantSelectCustomerPage from '../pages/MultiTenantSelectCustomerPage';
import VulnerabilityTrendPage, { topicSearchParamName } from '../pages/vulnerabilities/VulnerabilityTrendPage';
import { QueryUtil } from '../pages/vulnerabilities/VulnerabilityFilter';
import {
  SecurityLevel, Severity, Significance, VulnerabilityStatus,
} from '../pages/vulnerabilities/Types';
import VulnerabilitiesByControlPage from '../pages/vulnerabilities/VulnerabilitiesByControlPage';
import VulnerabilitiesPage from '../pages/vulnerabilities/VulnerabilitiesPage';
import { Module } from '../types/AccessTypes';
import { IAccountDetails } from '../providers/AccountProvider';
import { SignupFormCard } from '../pages/signin/SignupFormCard';
import { SignupTermsCard } from '../pages/signin/SignupTermsCard';
import { SignupConfirmCard } from '../pages/signin/SignupConfirmCard';
import { ErrorTemplate } from './ErrorTemplate';
import { NewModalProvider } from '../providers/NewModalProvider';
import { LoadingTemplate } from './LoadingTemplate';
import { asClientError } from './ClientError';
import { SignupCustomerExists } from '../pages/signin/SignupCustomerExists';
import { SignInCard } from '../pages/signin/SignIn';
import { DashboardContextProvider } from '../pages/dashboard/DashboardStore';
import { TermsAndConditionsCard } from '../pages/signin/TermsAndConditionsCard';
import { ReportVulnerabilities } from '../reporting/ReportVulnerabilities';
import { UserSettingsPage } from '../pages/usersettings/UserSettingsPage';
import { AcceptInviteCard } from '../pages/signin/AcceptInviteCard';
import { AdminCvesPage } from '../pages/admin/AdminCvesPage';
import { ValidPageSizes } from '../common/table/PaginationV8';
import { IVulnerabilityListOptions } from '../types/Types';

export class LoadError implements ErrorResponse {
  constructor(data:string, status?:number|undefined) {
    this.data = data;
    this.status = status ?? 500;
    this.statusText = 'Load error';
  }

  status: number;

  statusText: string;

  data: string;
}

export const RefreshTokenTemplate = () => {
  // Reload the page if token is not refreshed within resonable time.
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <LoadingTemplate>
      Refreshing token
    </LoadingTemplate>
  );
};

const getTitleFromStatus = (status:number) => {
  switch (status) {
  case 404:
    return 'Resource not found';
  case 403:
    return 'Forbidden';
  case 423:
    return 'Resource was locked';
  case 408:
    return 'A timeout occured';
  case 409:
    return 'Conflict error';
  default:
    return 'An unxpected server error occured';
  }
};

const getErrorDetailsFromRouteError = (routeError:unknown) => {
  if (isRouteErrorResponse(routeError)) {
    return {
      status: routeError.status,
      title: getTitleFromStatus(routeError.status),
      details: routeError.data,
    };
  }
  const clientError = asClientError(routeError);
  if (clientError) {
    return clientError;
  }
  if (routeError instanceof Error) {
    return {
      status: -1,
      title: 'An unexpected client error occured',
      details: routeError.message,
    };
  }
  if (routeError instanceof LoadError) {
    return {
      status: routeError.status,
      title: getTitleFromStatus(routeError.status),
      details: routeError.data,
    };
  }

  return {
    status: -1,
    title: 'An unknown error occured',
    details: undefined,
  };
};

export const RouteErrorTemplate = () => {
  const error = getErrorDetailsFromRouteError(useRouteError());

  // Show loading template while refreshing token
  if (error.status === 401) {
    return <RefreshTokenTemplate />;
  }

  return <ErrorTemplate status={error.status} title={error.title} details={error.details} />;
};

export const createAlgizNoAccountRouter = () => createBrowserRouter([
  {
    errorElement: <RouteErrorTemplate />,
    element: (
      <NoSidebarTemplate>
        <Outlet />
      </NoSidebarTemplate>
    ),
    children: [
      {
        path: ROUTES.signupTerms.uri,
        element: <SignupTermsCard />,
      },
      {
        path: ROUTES.signupConfirm.uri,
        element: <SignupConfirmCard />,
      },
      {
        path: ROUTES.signup.uri,
        element: <SignupFormCard />,
      },
      {
        path: '*',
        element: <SignInCard />,
      },
    ],
  },
]);

// eslint-disable-next-line react-hooks/rules-of-hooks
export const createAlgizRouter = (
  queryClient:QueryClient,
  account:IAccountDetails,
  pageSize:ValidPageSizes,
) => createBrowserRouter([
  {
    element: (
      <NewModalProvider>
        <Outlet />
      </NewModalProvider>
    ),
    errorElement: <RouteErrorTemplate />,
    children: [
      {
        path: ROUTES.signupConsent.uri,
        element: <ConsentReturnPage />,
      },
      {
        path: ROUTES.termsAndConditions.uri,
        element: (
          <NoSidebarTemplate>
            <TermsAndConditionsCard />
          </NoSidebarTemplate>
        ),
      },
    ],
  },
  {
    errorElement: <RouteErrorTemplate />,
    element: (
      <NoSidebarTemplate>
        <Outlet />
      </NoSidebarTemplate>
    ),
    children: [
      {
        path: ROUTES.multiTenantCustomerSelect.uri,
        element: <MultiTenantSelectCustomerPage />,
      },
      {
        path: ROUTES.signup.uri,
        element: <SignupCustomerExists />,
      },
      {
        path: ROUTES.acceptInvite.uri,
        element: (
          <NewModalProvider>
            <AcceptInviteCard />
          </NewModalProvider>
        ),
      },
    ],
  },
  {
    errorElement: <RouteErrorTemplate />,
    element: (
      <NewModalProvider>
        <DashboardContextProvider>
          <AuthenticatedAppRouteTemplate />
        </DashboardContextProvider>
      </NewModalProvider>
    ),
    children: [
      {
        children: [
          {
            path: ROUTES.vulnerability.getUriId(),
            element: <VulnerabilityPage.Component />,
            loader: async ({ params }) => VulnerabilityPage.loader(queryClient, params.id),
          },
          {
            path: ROUTES.vulnerabilitySummary.uri,
            element: <VulnerabilitySummaryPage.Component />,
            loader: async ({ request }) => {
              if (!account) {
                throw new LoadError('Route requires an active account');
              }

              const securityLevel = account.getCustomerSetting(Module.none, 'security-level', SecurityLevel.Unknown);

              const url = new URL(request.url);
              const filters = new QueryUtil(securityLevel, () => { }, [url.searchParams, undefined]).getFilters();

              const options = {
                assets: filters.assetId ? [filters.assetId] : undefined,
                securityLevel: filters.securityLevel,
                sourceModuleIds: filters.sourceModuleId ? [filters.sourceModuleId] : undefined,
                projectToFramework: filters.framework,
                snapshotId: filters.snapshotId,
              };

              return VulnerabilitySummaryPage.loader(
                queryClient,
                account,
                pageSize,
                options,
              );
            },
          },
          {
            path: ROUTES.vulnerabilitiesByControl.uri,
            element: <VulnerabilitiesByControlPage.Component />,
            loader: async ({ request }) => {
              if (!account) {
                throw new LoadError('Route requires an active account');
              }

              const securityLevel = account.getCustomerSetting(Module.none, 'security-level', SecurityLevel.Unknown);

              const url = new URL(request.url);
              const filters = new QueryUtil(securityLevel, () => { }, [url.searchParams, undefined]).getFilters();

              const options = {
                assets: filters.assetId ? [filters.assetId] : undefined,
                securityLevel: filters.securityLevel,
                sourceModuleIds: filters.sourceModuleId ? [filters.sourceModuleId] : undefined,
                projectToFramework: filters.framework,
                snapshotId: filters.snapshotId,
              };

              return VulnerabilitiesByControlPage.loader(
                queryClient,
                account,
                pageSize,
                options,
              );
            },
          },
          {
            path: ROUTES.vulnerabilities.uri,
            element: <VulnerabilitiesPage.Component />,
            loader: async ({ request }) => {
              if (!account) {
                throw new LoadError('Route requires an active account');
              }

              const securityLevel = account.getCustomerSetting(Module.none, 'security-level', SecurityLevel.Unknown);

              const url = new URL(request.url);
              const filters = new QueryUtil(securityLevel, () => { }, [url.searchParams, undefined]).getFilters();

              const pageFilters = {
                assets: filters.assetId ? [filters.assetId] : undefined,
                securityLevel: filters.securityLevel,
                sourceModuleIds: filters.sourceModuleId ? [filters.sourceModuleId] : undefined,
                projectToFramework: filters.framework,
                snapshotId: filters.snapshotId,
              };

              const options:IVulnerabilityListOptions = {};
              if (url.searchParams) {
                if (url.searchParams.has('severity')) {
                  options.severity = url.searchParams.get('severity') as Severity;
                  options.impact = undefined;
                  options.probability = undefined;
                } else if (url.searchParams.has('probability') && url.searchParams.has('impact')) {
                  options.severity = undefined;
                  options.impact = [url.searchParams.get('impact') as Significance];
                  options.probability = [url.searchParams.get('probability') as Significance];
                } else if (url.searchParams.has('probability')) {
                  options.severity = undefined;
                  options.impact = undefined;
                  options.probability = [url.searchParams.get('probability') as Significance];
                } else if (url.searchParams.has('impact')) {
                  options.severity = undefined;
                  options.impact = [url.searchParams.get('impact') as Significance];
                  options.probability = undefined;
                }

                if (url.searchParams.has('status')) {
                  options.status = [url.searchParams.get('status') as VulnerabilityStatus];
                }
              }

              return VulnerabilitiesPage.loader(
                queryClient,
                account,
                pageSize,
                { filters: pageFilters, options },
              );
            },
          },
          {
            path: ROUTES.vulnerabilitySummary.getUriId(),
            element: <VulnerabilitySummaryPage.Component />,
            loader: async () => VulnerabilitySummaryPage.loader(queryClient, account, pageSize),
          },
          {
            path: ROUTES.vulnerabilityHistory.uri,
            element: <VulnerabilityTrendPage.Component />,
            loader: async ({ request }) => {
              const url = new URL(request.url);
              const topic = url.searchParams.get(topicSearchParamName);
              return VulnerabilityTrendPage.loader(queryClient, account, pageSize, { topic });
            },
          },
          {
            path: ROUTES.assets.uri,
            element: <AssetsPage.Component />,
            loader: async () => AssetsPage.loader(queryClient, account, pageSize),
          },
          {
            path: ROUTES.control.getUriId(),
            element: <ControlPage.Component />,
            loader: async ({ params, request }) => {
              const url = new URL(request.url);
              const snapshotId = url.searchParams.get('snapshot_id') ?? undefined;
              const result = await ControlPage.loader(
                queryClient,
                account,
                pageSize,
                { id: params.id, snapshotId },
              );
              if (!result.control) {
                throw new LoadError(`Control ${(params.id ?? '<unknown>')} does not exist.`, 404);
              }
              return result;
            },
          },
          {
            path: ROUTES.adminJobs.uri,
            element: <AdminJobsPage.Component />,
            loader: async ({ request }) => {
              const url = new URL(request.url);
              const id = url.searchParams.get('id');
              return AdminJobsPage.loader(queryClient, account, pageSize, id);
            },
          },
          {
            path: ROUTES.adminCves.uri,
            element: <AdminCvesPage.Component />,
            loader: async ({ request }) => {
              const url = new URL(request.url);
              const cveId = url.searchParams.get('id');
              return AdminCvesPage.loader(queryClient, account, pageSize, { id: cveId ?? undefined });
            },
          },
          {
            path: ROUTES.assessment.getUriId(),
            element: <AssessmentPage.Component />,
            loader: async ({ request, params }) => {
              const sha512 = params.id;
              const url = new URL(request.url);
              const requestedLibraryFriendlyId = url.searchParams.get('library');

              if (!sha512) {
                throw new Error('Parameters missing id');
              }

              return AssessmentPage.loader(
                queryClient,
                account,
                pageSize,
                { sha512, requestedLibraryFriendlyId },
              );
            },
          },
          {
            path: ROUTES.assessmentConfirm.getUriId(),
            element: <AssessmentSaveConfirmPage.Component />,
            loader: async ({ params }) => {
              const sha512 = params.id;

              if (!sha512) {
                throw new Error('Parameters missing id');
              }

              return AssessmentSaveConfirmPage.loader(queryClient, account, pageSize, sha512);
            },
          },
          {
            path: ROUTES.adminAssessments.uri,
            element: <AdminAssessmentsPage.Component />,
            loader: async () => AdminAssessmentsPage.loader(queryClient, account, pageSize),
          },
        ],
      },
      {
        path: ROUTES.dashboard.uri,
        element: <DashboardPage />,
      },
      {
        path: ROUTES.risk.uri,
        element: <RiskPage.Component />,
        loader: async () => RiskPage.loader(queryClient, account, pageSize),
      },
      {
        path: ROUTES.risk.getUriId(),
        element: <RiskDetailPage.Component />,
        loader: async ({ params }) => (
          RiskDetailPage.loader(queryClient, account, pageSize, { id: params.id })
        ),
      },
      {
        path: ROUTES.assets.getUriId(),
        element: <AssetsDetailPage.Component />,
        loader: async ({ params }) => (
          AssetsDetailPage.loader(queryClient, account, pageSize, { id: params.id })
        ),
      },
      {
        path: ROUTES.adminCustomers.uri,
        element: <AdminCustomersPage.Component />,
        loader: async ({ request }) => {
          const url = new URL(request.url);
          return AdminCustomersPage.loader(
            queryClient,
            account,
            pageSize,
            { id: url.searchParams.get('customer') ?? undefined },
          );
        },
      },
      {
        path: ROUTES.adminTools.uri,
        element: <AdminToolsPage />,
      },
      {
        path: ROUTES.adminConsents.uri,
        element: <AdminConsentsPage.Component />,
        loader: async () => AdminConsentsPage.loader(queryClient, account, pageSize),
      },
      {
        path: ROUTES.adminModules.uri,
        element: <AdminModulesPage.Component />,
        loader: async () => AdminModulesPage.loader(queryClient, account, pageSize),
      },
      {
        path: ROUTES.customersettings.uri,
        element: <CustomerSettingsPage.Component />,
        loader: async () => CustomerSettingsPage.loader(queryClient, account, pageSize),
      },
      {
        path: ROUTES.usersettings.uri,
        element: <UserSettingsPage.Component />,
        loader: async () => UserSettingsPage.loader(queryClient, account, pageSize),
      },
      {
        path: ROUTES.reportVulnerabilities.uri,
        element: <ReportVulnerabilities />,
      },
    ],
  },
]);
