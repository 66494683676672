import React from 'react';
import { Spinner, Stack } from 'react-bootstrap';
import { ConsentUpdateButton, useModuleConsent } from '../pages/customersettings/ConsentUpdateButton';
import { useAccount } from '../providers/AccountProvider';
import { useModules } from '../providers/ModuleProvider';
import { Module } from '../types/AccessTypes';

interface IProps {
  pendingOnly?:boolean,
  helpText?:React.ReactElement|undefined|null
}

export const ConnectedServices = ({
  pendingOnly,
  helpText,
}:IProps) => {
  const { customer } = useAccount();
  const { isLoading, getModuleConsent } = useModuleConsent();
  const { getModuleNameOrDefault } = useModules();

  return isLoading
    ? <Spinner animation="border" />
    : (
      <Stack>
        { helpText }
        { customer.customerModules.map((module) => {
          const moduleConsent = getModuleConsent(module.module.id);
          return moduleConsent && (!pendingOnly || moduleConsent.availableVersion !== moduleConsent.customer?.version)
            ? (
              <fieldset className="mb-3 pb-3" key={`${module.module.id}`}>
                <legend>{getModuleNameOrDefault(module.module.id)}</legend>
                <ConsentUpdateButton
                  consent={moduleConsent}
                  getAddConsentInfo={(moduleId) => {
                    switch (moduleId) {
                    case Module.secOpsAccess:
                      return (
                        <>
                          <p>
                            Permissions for Security Orchestration, Automation and Response (SOAR).
                          </p>
                          <p>
                            Essential permissions to enable tasks such as automated response and data enrichment,
                            allowing analysts to streamline operations and respond effectively to threats.
                          </p>
                        </>
                      );
                    default:
                      return null;
                    }
                  }}
                />
              </fieldset>
            )
            : null;
        })}
      </Stack>
    );
};
