/* eslint-disable no-nested-ternary */
import {
  IRisk, RiskStatus,
} from '../../types/RiskTypes';
import { IVulnerabilityListOptions } from '../../types/Types';
import { getSteppedValue } from '../../utils/Utils';
import { ISeverityComponents } from '../vulnerabilities/Types';
import { asSeverity, severityAsCssClassName } from '../vulnerabilities/Utils';

export const riskStatusAsCssClassName = (risk:IRisk) => {
  switch (risk.status) {
  case RiskStatus.Open:
    return 'risk-open';
  case RiskStatus.Closed:
    return 'risk-closed';
  default:
    return 'risk-unknown';
  }
};

export const riskAsColorCssClassNames = (risk:IRisk) => [
  severityAsCssClassName(asSeverity(risk)),
  riskStatusAsCssClassName(risk),
].join(' ');

export const isRiskManaged = (risk:IRisk) => risk.hasVulnerabilities;

/**
 * Highlights the single selected cell, or a stepped opacity for non-single cells to highlight
 * the most populated cells (headmap).
 */
export const getOpacityStyle = (single:boolean|undefined, count:number, maxCount:number|undefined) => {
  const minOpacity = 0.2;
  if (!single && count > 0) {
    if (maxCount) {
      const fraction = (getSteppedValue(maxCount, 5, count) / 10) + 0.65;
      return { opacity: fraction };
    }
    return undefined;
  }
  return count > 0 ? undefined : { opacity: minOpacity };
};

export const getFilterUpdateForSelectedSeverityComponent = ({
  probability,
  impact,
  getFilterValues,
}:(ISeverityComponents&{ getFilterValues: <TValue, >(filterId:string) => TValue[]|undefined })) => {
  const probabilityFilter = getFilterValues<string>('probability') ?? [];
  const impactFilter = getFilterValues<string>('impact') ?? [];

  const probabilityIndex = probabilityFilter.findIndex(
    (v) => v === probability,
  );
  const impactIndex = impactFilter.findIndex(
    (v) => v === impact,
  );

  if (probabilityIndex >= 0 && impactIndex >= 0) {
    probabilityFilter.splice(probabilityIndex, 1);
    impactFilter.splice(impactIndex, 1);
    return [
      { id: 'status', value: probabilityFilter.length === 0 && impactFilter.length === 0 ? null : ['open'] },
      { id: 'severity', value: null },
      { id: 'probability', value: probabilityFilter },
      { id: 'impact', value: impactFilter },
    ];
  }
  return [
    { id: 'status', value: ['open'] },
    { id: 'severity', value: null },
    { id: 'impact', value: [impact] },
    { id: 'probability', value: [probability] },
  ];
};

export const isSeverityComponentSelectedInOptions = (
  severityComponents:ISeverityComponents,
  options:IVulnerabilityListOptions,
) => {
  if (!options.severity && !options.impact?.length && !options.probability?.length) {
    return false;
  }

  const isSelected = (options.severity
    ? options.severity === asSeverity(severityComponents)
    : true)
  && (options.impact?.length
    ? options.impact.includes(severityComponents.impact)
    : true)
  && (options.probability?.length
    ? options.probability.includes(severityComponents.probability)
    : true);

  return isSelected;
};

export const isSeverityComponentFiltered = ({
  impact,
  probability,
  getFilterValues,
}:(ISeverityComponents&{ getFilterValues?: <TValue, >(filterId:string) => TValue[]|undefined })) => {
  if (!getFilterValues) return false;

  const severityFilter = getFilterValues('severity');
  const impactFilter = getFilterValues('impact');
  const probabilityFilter = getFilterValues('probability');

  if (!severityFilter?.length && !impactFilter?.length && !probabilityFilter?.length) {
    return false;
  }
  return (severityFilter?.length
    ? severityFilter.includes(
      asSeverity({ impact, probability }),
    )
    : true)
      && (impactFilter?.length
        ? impactFilter.includes(impact)
        : true)
      && (probabilityFilter?.length
        ? probabilityFilter.includes(probability)
        : true);
};
