import React, {
  useCallback, useEffect, useState,
} from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import {
  AdminCustomer, GroupRole,
} from '../../types/AdminTypes';
import { useApi } from '../../query/GenericQuery';
import { Module } from '../../types/AccessTypes';
import ItemsSelector from '../../common/ItemsSelector';
import { GroupDetails } from './GroupDetails';
import { useAccount } from '../../providers/AccountProvider';

export const AdminCustomerGroupsTab = (props:{customer:AdminCustomer}) => {
  const { customer } = props;

  const { hasModuleRole } = useAccount();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedGroup, setSelectedGroup] = useState<GroupRole>();

  const readOnly = !hasModuleRole(Module.admin, 'readWrite');

  const { data: customerDetails } = useApi<AdminCustomer>(
    customer && `module/admin/customers/${encodeURIComponent(customer?.id ?? '')}/details`,
  );

  const setGroupToSearchParams = useCallback((groupId:string|undefined) => {
    if (groupId) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        group: groupId,
      });
    } else {
      const entries = Object.fromEntries(searchParams.entries());
      delete entries.group;
      setSearchParams(entries);
    }
  }, [searchParams, setSearchParams]);

  const {
    data: groups,
    isLoading: groupsLoading,
    invalidate: invalidateGroupsAndRoles,
  } = useApi<GroupRole[]>(
    `module/admin/groups/${ encodeURIComponent(customer.id) }`,
  );

  const selectedGroupId = searchParams.get('group');

  const selectGroup = useCallback((group:GroupRole|undefined) => {
    setGroupToSearchParams(group?.id);
    setSelectedGroup(group);
  }, [setGroupToSearchParams]);

  useEffect(() => {
    if (!selectedGroup && selectedGroupId && groups) {
      selectGroup(groups.find((g) => g.id === selectedGroupId));
    }
  }, [selectedGroupId, groups, selectedGroup, selectGroup]);

  const addGroupMutation = useMutation({
    mutationFn: async (data: {name:string}) => axios.post(
      '/api/v1/module/admin/groups',
      { name: data.name, customerId: customer.id },
    ),
    onSuccess: (response) => {
      toast.success('Group was added');
      invalidateGroupsAndRoles();
      const addedGroup = response.data as GroupRole;
      if (addedGroup) {
        setGroupToSearchParams(addedGroup.id);
        setSelectedGroup(addedGroup);
      }
    },
  });

  return groups
    ? (
      <Row>
        <Col md={4} className="pb-3">
          <ItemsSelector
            id="admin-customer-groups"
            items={groups}
            isSearching={!groups || groupsLoading}
            addLabel="Add group"
            addEnabled={!readOnly}
            onChange={async (gs) => {
              await addGroupMutation.mutateAsync(gs[gs.length - 1]);
            }}
            toItem={(name:string) => ({ name } as GroupRole)}
            addPlaceholder="Group name..."
            onSelectItem={setSelectedGroup}
            renderer={(g) => g.name}
            selectedItem={selectedGroup}
            itemToString={(g) => g.id}
          />
        </Col>
        { selectedGroup && customerDetails
          ? (
            <Col md={8} className="pb-3">
              <GroupDetails
                group={selectedGroup}
                customer={customerDetails}
                disabled={readOnly}
                setGroup={setSelectedGroup}
                isUniqueName={(name:string) => (!(groups && groups.map(
                  ({ name: otherGroupName }) => otherGroupName,
                ).includes(name)
                ))}
              />
            </Col>
          )
          : null }
      </Row>
    )
    : <Spinner animation="border" />;
};

export default AdminCustomerGroupsTab;
