import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import ItemsSelector from '../../common/ItemsSelector';
import { useInvalidateQueries } from '../../query/GenericQuery';
import { ICustomerSettingsCustomer } from './CustomerTypes';

interface IProps {
  customer:ICustomerSettingsCustomer
}

const CustomerNotificationTab = (props:IProps) => {
  const { customer } = props;

  const invalidate = useInvalidateQueries('module/customer');

  const saveMutation = useMutation({
    mutationFn: async (data:string[]) => axios.put(
      '/api/v1/module/customer',
      { ...customer, notificationEmails: data } as ICustomerSettingsCustomer,
    ),
    onSuccess: () => {
      invalidate();
      toast.success('Notification receivers have been updated');
    },
  });

  return (
    <Row>
      <Col sm={6} className="mb-3">
        <Form.Label>Notification emails:</Form.Label>
        <ItemsSelector
          id="customer-settings-notification-emails"
          items={customer.notificationEmails}
          inputType="email"
          removeEnabled
          addEnabled
          addLabel="Add notification email"
          addPlaceholder="Enter notification email..."
          onChange={async (items) => { saveMutation.mutateAsync(items); }}
        />
      </Col>
    </Row>
  );
};

export default CustomerNotificationTab;
