import React, { useEffect, useState } from 'react';
import { QueryClient } from '@tanstack/react-query';
import {
  Button,
  Card, Col, Form, Row,
  Spinner,
  Stack,
} from 'react-bootstrap';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getOrFetchFromApi, useInvalidateQueries } from '../../query/GenericQuery';
import { cveStringRegex, cvePattern } from '../../utils/StringUtils';
import RenderHtml from '../../components/RenderHtml';
import { ICve } from '../vulnerabilities/Types';
import { IComponentWithLoader } from '../../routing/ComponentWithLoader';
import { IAccountDetails } from '../../providers/AccountProvider';
import { useSyncedHeight } from '../../components/useSyncedHeight';

const isValidCveId = (value:string|undefined|null) => (
  value && value.match(cveStringRegex)
);

interface IModel {
  id:string,
  cve?:ICve,
  busy:boolean
}

export const AdminCvesPage:IComponentWithLoader<ICve|null, {id?:string}> = {
  loader: async (
    queryClient:QueryClient,
    account:IAccountDetails,
    pageSize:number,
    args?:{id?:string|undefined},
  ) => {
    if (!args?.id) return null;

    const cve = args?.id && isValidCveId(args?.id)
      ? await getOrFetchFromApi<ICve>(
        queryClient,
        `module/admin/cve/${args.id}`,
      )
      : null;

    return cve?.id
      ? {
        ...cve,
        notes: cve.notes ?? '',
      } : {
        id: args.id,
        notes: '',
      };
  },
  Component: () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [idInput, setIdInput] = useState(searchParams.get('id') ?? '');

    const cve = useLoaderData() as Awaited<ICve>;
    const invalidateCves = useInvalidateQueries('module/admin/cve');

    const [model, setModel] = useState<IModel>({
      id: '',
      cve,
      busy: false,
    });

    useEffect(() => {
      setModel({
        ...model,
        cve,
        busy: false,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cve]);

    useEffect(() => {
      if (searchParams.get('id') !== idInput) {
        setIdInput(searchParams.get('id') ?? '');
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const { sourceRef, targetRef } = useSyncedHeight<HTMLDivElement, HTMLTextAreaElement>();

    return (
      <Card>
        <Card.Header>
          <Card.Title>
            Lookup CVE
          </Card.Title>
        </Card.Header>
        <Card.Body className="overflow-auto">
          <Row>
            <Col md={6}>
              <Form
                onSubmit={async (e) => {
                  e.preventDefault();
                  if (isValidCveId(idInput)) {
                    if (idInput) {
                      searchParams.set('id', idInput);
                    } else {
                      searchParams.delete('id');
                    }
                    setSearchParams(searchParams);
                  }
                }}
              >
                <Stack direction="horizontal">
                  <Form.Control
                    placeholder="Lookup CVE ID"
                    value={idInput}
                    disabled={model.busy || Boolean(model.cve)}
                    pattern={`^${cvePattern}$`}
                    onChange={(e) => setIdInput(e.target.value)}
                  />
                  <Button
                    disabled={model.busy || Boolean(model.cve)}
                    type="submit"
                  >
                    Search
                  </Button>
                </Stack>
              </Form>
            </Col>
          </Row>
          { model.cve
            ? (
              <Row className="mt-4">
                <Col md={12}>
                  <Form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      if (model.cve) {
                        setModel({
                          ...model,
                          busy: true,
                        });
                        try {
                          const { data: updatedCve } = await axios.post<ICve>(
                            `/api/v1/module/admin/cve/${model.cve.id}`,
                            model.cve,
                          );
                          setModel({
                            ...model,
                            cve: updatedCve,
                          });
                          invalidateCves();
                          toast.info('CVE was saved', {
                            toastId: 'cve-save',
                            updateId: 'cve-save',
                          });
                        } catch (err) {
                          toast.error(`Unable to save CVE: ${err}`, {
                            toastId: 'cve-save',
                            updateId: 'cve-save',
                          });
                        } finally {
                          setModel({
                            ...model,
                            busy: false,
                          });
                        }
                      }
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <Form.Label>
                          Ivolv CVE notes:
                        </Form.Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Control
                          as="textarea"
                          rows={8}
                          disabled={model.busy}
                          value={model.cve.notes}
                          ref={targetRef}
                          style={{ minHeight: '200px' }}
                          onChange={(e) => setModel({
                            ...model,
                            cve: model.cve
                              ? {
                                ...model.cve,
                                notes: e.target.value,
                              }
                              : undefined,
                          })}
                        />
                        <Stack direction="horizontal" gap={3} className="mt-3">
                          <Button
                            type="submit"
                            disabled={model.busy}
                          >
                            Save
                            { model.busy
                              ? <Spinner animation="border" size="sm" className="ms-2" />
                              : null }
                          </Button>
                          <Button
                            type="reset"
                            variant="secondary"
                            disabled={model.busy}
                            onClick={() => {
                              setModel({
                                ...model,
                                cve: undefined,
                              });
                              searchParams.delete('id');
                              setSearchParams(searchParams);
                              setIdInput('');
                            }}
                          >
                            Close
                          </Button>
                        </Stack>
                      </Col>
                      <Col md={6} className="mb-3">
                        <div className="form-control pb-3" ref={sourceRef} style={{ minHeight: '200px' }}>
                          <RenderHtml>
                            {model.cve.notes as string}
                          </RenderHtml>
                        </div>
                        <div className="muted small">
                          Markdown preview. Notes will be displayed on every vulnerability associated with the CVE.
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            )
            : null }
        </Card.Body>
      </Card>
    );
  },
};
